import React from 'react'
import { inject, observer } from 'mobx-react'
import { Switch, Route, Redirect } from 'react-router-dom'

import NotFound from 'pages/NotFound/NotFound'
import Maintenance from 'pages/Maintenance/Maintenance'
import SelfCare from 'pages/SelfCare/SelfCare'
import LoginSelfCare from 'pages/Login/LoginSelfCare'
import LinkExpired from 'pages/LinkExpired/LinkExpired'

const PrivateRoute = inject('UiCtrl', 'UserStore')(
  observer(props => {
    const {
      UiCtrl: { apiStatus },
      UserStore: { token, selfCareWan },
    } = props
    if (apiStatus !== 200) return <Maintenance />
    if (token && selfCareWan) return <Route {...props} />
    return <Redirect to="/sc" />
  }),
)

const Main = ({ UiCtrl: { apiStatus }, UserStore: { token, selfCareWan } }) => {
  return (
    <main>
      <div className="container pt-4 pr-3 pl-3">
        <Switch>
          <Route exact path="/sc/login/:wan/:uuid" component={LoginSelfCare} />
          <PrivateRoute exact path="/sc/:wan" component={SelfCare} />
          <Route
            exact
            path="/sc"
            render={() => {
              if (apiStatus !== 200) return <Maintenance />
              return token && selfCareWan ? <Redirect to={`/sc/${selfCareWan}`} /> : <LinkExpired />
            }}
          />
          <Route
            render={() => {
              if (apiStatus !== 200) return <Maintenance />
              return <NotFound />
            }}
          />
        </Switch>
      </div>
    </main>
  )
}

export default inject('UiCtrl', 'UserStore')(observer(Main))
