import React from 'react'
import { inject, observer } from 'mobx-react'
import { compose } from 'recompose'
import SelectInvolvedPartyModal from 'pages/Mission/SelectInvolvedPartyModal'

const Teleexpertise = ({
  TeleexpertiseCtrl: {
    showTeleexpertiseForm,
    phoneNumber,
    email,
    startSession,
    setProperty,
    involvedParty,
    sending,
    resetForm,
  },
  PartyInvolvedStore: { asSelectOptions, findEmail, findMobilePhone },
  UserStore: { isClaimManager },
  ManagerClaimStore,
  MissionStore,
}) => {
  const id = isClaimManager ? ManagerClaimStore.id : MissionStore.id
  const onClose = () => {
    setProperty('showTeleexpertiseForm', false)
    resetForm()
  }
  const onChange = e => setProperty(e.target.name, e.target.value)
  const onChangeIP = e => {
    const email = findEmail(e.target.value)
    const phoneNumber = findMobilePhone(e.target.value)
    setProperty('email', email ? email : '')
    setProperty('phoneNumber', phoneNumber ? phoneNumber : '')
    setProperty('involvedParty', e.target.value)
  }

  return (
    <SelectInvolvedPartyModal
      showModal={showTeleexpertiseForm}
      onClose={onClose}
      onChange={onChange}
      onValid={() => {
        startSession(id, isClaimManager ? 'cfm' : 'cfa')
      }}
      onChangeIP={onChangeIP}
      optionsIP={asSelectOptions}
      selectedIP={involvedParty}
      email={email}
      phoneNumber={phoneNumber}
      loading={sending}
    />
  )
}

export default compose(
  inject(
    'MissionStore',
    'UserStore',
    'TeleexpertiseCtrl',
    'PartyInvolvedStore',
    'ManagerClaimStore',
  ),
  observer,
)(Teleexpertise)
