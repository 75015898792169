import React, { useState, useRef, useEffect, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { compose } from 'recompose'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { isNil, path } from 'ramda'
import { OTSession, OTPublisher, OTStreams, OTSubscriber } from 'opentok-react'
//for network test and video/audio check
import OT from '@opentok/client'
import { usePublisher } from './hooks/usePublisher'
import { useNetworkTest } from './hooks/useNetworkTest'
import { DEVICE_ACCESS_STATUS } from './constants'
import ImageLoading from 'components/ImageLoading/ImageLoading'
import Toggle from 'components/Toggle/Toggle'
import { Line } from 'rc-progress'

import './Stream.css'
import PageLayout from 'components/PageLayout/PageLayout'
import { getNetworkToken } from 'services/teleexpertise'

const Teleexpertise = ({ TeleexpertiseCtrl: { endSession } }) => {
  const [publisherVideo, setPublisherVideo] = useState(true)
  const [publisherAudio, setPublisherAudio] = useState(true)
  const [streamConnected, setStreamConnected] = useState(false)
  const [endCall, setEndCall] = useState(false)
  const params = useParams()
  const { t } = useTranslation()
  const sessionId = path(['sessionId'], params)
  const token = path(['token'], params)

  //added for network test
  const defaultLocalAudio = true
  const defaultLocalVideo = true

  const [localAudio, setLocalAudio] = useState(defaultLocalAudio)
  const [localVideo, setLocalVideo] = useState(defaultLocalVideo)
  //const [networkTestRunning, setNetworkTestRunning] = useState(false);
  const waitingRoomVideoContainer = useRef()

  // setAudioDevice and setVideoDevice are used in the Select Menu
  let [audioDevice, setAudioDevice] = useState('')
  let [videoDevice, setVideoDevice] = useState('')

  //Manage TeleConference
  const [startTeleConference, setStartTeleConference] = useState(false)

  //Manage quality results
  const [showQualityDialog, setShowQualityDialog] = useState(false)

  //manage network token and session id
  const [networkToken, setNetworkToken] = useState('')
  const [networkSessionID, setNetworkSessionID] = useState('')

  const showPreCallResults = () => {
    setStartTeleConference(true)
  }

  const {
    publisher,
    initPublisher,
    destroyPublisher,
    accessAllowed,
    logLevel,
    deviceInfo,
    pubInitialised,
  } = usePublisher()

  const {
    connectivityTest,
    qualityTest,
    runNetworkTest,
    stopNetworkTest,
    isRunning,
    initNetworkTest,
  } = useNetworkTest({
    apikey: '47345661',
    sessionId: networkSessionID,
    token: networkToken,
    videoSource: videoDevice, //added to handle for multiple camera
  })

  const getFeedbackLabel = mos => {
    if (mos) {
      if (mos > 1 && mos < 1.69) return 'Mauvaise'
      if (mos > 1.7 && mos < 2.39) return 'Faible'
      if (mos > 2.4 && mos < 3.09) return 'Moyenne'
      if (mos > 3.1 && mos < 3.79) return 'Bonne'
      if (mos > 3.8 && mos < 4.5) return 'Excellente'
    }
    return
  }

  const handleAudioChange = React.useCallback(e => {
    setLocalAudio(e.target.checked)
  }, [])

  const handleVideoChange = React.useCallback(e => {
    setLocalVideo(e.target.checked)
  }, [])

  const handleJoinClick = () => {
    stopNetworkTest() // Stop network test
    if (localVideo && localAudio) {
      setStartTeleConference(true) //start tele conference
    } else {
      alert(t('teleexpertise.permissionText'))
    }
  }

  const handleVideoSource = React.useCallback(
    e => {
      const videoDeviceId = e.target.value
      setVideoDevice(e.target.value)
      publisher.setVideoSource(videoDeviceId)
    },
    [publisher, setVideoDevice],
  )

  const handleAudioSource = React.useCallback(
    e => {
      const audioDeviceLabel = e.target.value
      if (deviceInfo && deviceInfo.audioInputDevices) {
        const audioDevices = deviceInfo.audioInputDevices

        const deviceId = audioDevices.find(e => e.deviceId === audioDeviceLabel)

        if (deviceId != null) {
          publisher.setAudioSource(deviceId)
          setAudioDevice(audioDeviceLabel)
        }
      }
    },
    [publisher, setAudioDevice, deviceInfo],
  )

  const reRunNetworkTest = React.useCallback(
    e => {
      if (!isRunning) {
        // todo If I flip this value, runNetworkTest will re-render and run again.
        stopNetworkTest()
        initNetworkTest()
      }
    },
    [isRunning, initNetworkTest, stopNetworkTest],
  )

  //get network token and sessionID
  useEffect(() => {
    ;(async function fetchData() {
      const networkData = await getNetworkToken()
      if (networkData) {
        setNetworkToken(networkData.token)
        setNetworkSessionID(networkData.sessionId)
      }
    })()
  }, [])

  useEffect(() => {
    if (networkSessionID && networkToken) {
      runNetworkTest()
    }
  }, [runNetworkTest, networkSessionID, networkToken])

  useEffect(() => {
    const publisherOptions = {
      publishAudio: defaultLocalAudio,
      publishVideo: defaultLocalVideo,
    }
    if (waitingRoomVideoContainer.current) {
      initPublisher(waitingRoomVideoContainer.current.id, publisherOptions)
    }
  }, [initPublisher, defaultLocalAudio, defaultLocalVideo])

  useEffect(() => {
    if (publisher) {
      publisher.publishAudio(localAudio)
    }
  }, [localAudio, publisher])

  useEffect(() => {
    if (publisher) {
      publisher.publishVideo(localVideo)
    }
  }, [localVideo, publisher])

  useEffect(() => {
    if (!qualityTest.loading) {
      setShowQualityDialog(true)
    }
  }, [qualityTest])

  useEffect(() => {
    return () => {
      destroyPublisher()
    }
  }, [destroyPublisher])

  useEffect(() => {
    if (publisher && pubInitialised && deviceInfo) {
      const currentAudioDevice = publisher.getAudioSource()
      const currentVideoDevice = publisher.getVideoSource()
      if (currentAudioDevice && currentAudioDevice.label) {
        setAudioDevice(currentAudioDevice.label)
      }
      if (currentVideoDevice && currentVideoDevice.deviceId) {
        setVideoDevice(currentVideoDevice.deviceId)
      }
    }
  }, [deviceInfo, pubInitialised, setAudioDevice, setVideoDevice, publisher])

  if (isNil(sessionId) || isNil(token)) {
    return (
      <div className="stream d-flex flex-column align-items-center">
        <p>{t('teleexpertise.sessionError')}</p>
      </div>
    )
  } else if (endCall) {
    endSession()
    return (
      <div className="stream d-flex flex-column align-items-center">
        <p>{t('teleexpertise.callEnded')}</p>
      </div>
    )
  }
  if (startTeleConference) {
    return (
      <div className="page-teleexpertise">
        <h5 className="text-center mt-3 mb-3">
          {streamConnected ? (
            <span className="text-success">{t('teleexpertise.session')}</span>
          ) : (
            <span className="text-warning">{t('teleexpertise.waitingForExpertConnection')}</span>
          )}
        </h5>
        <div className="stream d-flex flex-column align-items-center">
          <OTSession
            apiKey="47345661"
            sessionId={sessionId}
            token={token}
            eventHandlers={{
              streamCreated: () => {
                setStreamConnected(true)
              },
              streamDestroyed: () => {
                setStreamConnected(false)
              },
            }}
          >
            <OTPublisher
              properties={{
                insertDefaultUI: true,
                showControls: true,
                fitMode: 'cover',
                facingMode: 'environment',
                mirror: false,
                publishVideo: true,
                publishAudio: publisherAudio,
                width: 640,
                height: 480,
                videoSource: videoDevice,
              }}
            />

            <OTStreams>
              <OTSubscriber
                properties={{
                  insertDefaultUI: true,
                  showControls: true,
                  width: 0,
                  height: 0,
                  publishVideo: false,
                  publishAudio: true,
                }}
              />
            </OTStreams>
          </OTSession>

          <div className="my-3">
            <div className="btn-group">
              <button
                className={classNames('btn', {
                  'btn-success': publisherVideo,
                  'btn-danger': !publisherVideo,
                })}
                onClick={() => {
                  setPublisherVideo(!publisherVideo)
                }}
              >
                <i className="fa fa-video-camera" />
              </button>

              <button
                className={classNames('btn', {
                  'btn-success': publisherAudio,
                  'btn-danger': !publisherAudio,
                })}
                onClick={() => {
                  setPublisherAudio(!publisherAudio)
                }}
              >
                <i
                  className={classNames('fa', {
                    'fa-microphone': publisherAudio,
                    'fa-microphone-slash': !publisherAudio,
                  })}
                />
              </button>

              <button
                className="btn btn-danger"
                onClick={() => {
                  setEndCall(true)
                  endSession()
                }}
              >
                {t('teleexpertise.endSession')}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <Fragment>
      <section className="page-section bg-primary rezise-expert" id="about">
        <div className="container px-4 px-lg-5">
          <div className="container px-4 px-lg-5">
            <div className="row gx-4 gx-lg-5 justify-content-center">
              <div className="col-lg-8 text-center">
                <h2 className="text-white mt-0">{t('teleexpertise.confCall')}</h2>
                <hr className="divider_pre_call divider-light_pre_call" />
                <p className="text-white-75 mb-4">{t('teleexpertise.confCallText')}</p>
                <button
                  className="btn_pre_call btn-light_pre_call btn-xl_pre_call"
                  onClick={showPreCallResults}
                >
                  {t('teleexpertise.confCallButton')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br />
      <hr className="divider_pre_call" />
      <br />
      <div>
        <h2 className="text-center mt-0">{t('teleexpertise.networkResult')}</h2>
        <hr className="divider_pre_call" />
        <div className="precall_container">
          <div className="precall_container__content">
            <div className="mb-2">
              <i className="fa fa-plug fs-1 text-primary"></i>
            </div>
            <h3 className="h4 mb-2">{t('teleexpertise.connectivityTest')}</h3>
            <p className="text-muted mb-0">
              {connectivityTest.loading ? (
                <ImageLoading />
              ) : connectivityTest.data && connectivityTest.data.success ? (
                //'success'
                <i className="fa fa-handshake-o fs-6" />
              ) : (
                //'Error'
                <i className="fa fa-exclamation-circle  fs-6" />
              )}
            </p>
            <hr className="divider_pre_call centre" />
            {qualityTest.loading ? (
              <ImageLoading />
            ) : qualityTest.data && showQualityDialog ? (
              <div className="qualityData pull-left">
                <p className="text-muted mb-0 text-left">
                  {' '}
                  {t('teleexpertise.videoQuality')} {getFeedbackLabel(qualityTest.data.video.mos)}
                </p>
                <p className="text-muted mb-0 text-left">
                  {' '}
                  {t('teleexpertise.resolution')} {qualityTest.data.video.recommendedResolution}@
                  {Math.round(qualityTest.data.video.frameRate)}fps
                </p>
                <p className="text-muted mb-0 text-left">
                  {t('teleexpertise.videoPacket')} {qualityTest.data.video.packetLossRatio}%<br />
                </p>
                <p className="text-muted mb-0 text-left">
                  {t('teleexpertise.videoBitrate')}{' '}
                  {Math.round(qualityTest.data.video.bitrate) / 1000} kbps
                </p>
                <hr className="divider centre" />
                <p className="text-muted mb-0 text-left">
                  {t('teleexpertise.audioQuality')} {getFeedbackLabel(qualityTest.data.audio.mos)}{' '}
                </p>
                <p className="text-muted mb-0 text-left">
                  {' '}
                  {t('teleexpertise.audioPacket')} {qualityTest.data.audio.packetLossRatio}%{' '}
                </p>
                <p className="text-muted mb-0 text-left">
                  {' '}
                  {t('teleexpertise.audioBitrate')}{' '}
                  {Math.round(qualityTest.data.audio.bitrate) / 1000}kbps{' '}
                </p>
              </div>
            ) : (
              <i className="fa fa-exclamation-circle fs-6" />
            )}
          </div>
          <div className="precall_container__column">
            <div className="precall_container__content">
              <div className="mb-2">
                <i className="fa fa-video-camera fs-1 text-primary"></i>
              </div>
              <h3 className="h4 mb-2">{t('teleexpertise.hwSwSetup')}</h3>
              <p className="text-muted mb-0">{t('teleexpertise.permission')}</p>
              <p className="text-muted mb-0">
                {accessAllowed === DEVICE_ACCESS_STATUS.PENDING ? (
                  <ImageLoading />
                ) : accessAllowed === DEVICE_ACCESS_STATUS.ACCEPTED ? (
                  <i className="fa fa-check" />
                ) : (
                  <i className="fa fa-exclamation-circle" />
                )}
              </p>
              <br />
              <div
                id="waiting-room-video-container"
                className="waitingRoomVideoPreview"
                ref={waitingRoomVideoContainer}
              />
              <br />
              {deviceInfo && pubInitialised && (
                <Fragment className="pull-left">
                  <span id="video" className="text-muted mb-0 text-left">
                    {t('teleexpertise.video')}
                  </span>
                  <p className="text-muted mb-0 text-left">
                    {deviceInfo.videoInputDevices && (
                      <select value={videoDevice} onChange={handleVideoSource}>
                        {deviceInfo.videoInputDevices.map(device => (
                          <option key={device.deviceId} value={device.deviceId}>
                            {device.label}
                          </option>
                        ))}
                      </select>
                    )}
                  </p>
                  <p className="text-muted mb-0 text-left"> {t('teleexpertise.enableVideo')}</p>
                  <Toggle onChange={handleVideoChange} checked={localVideo} /> <br />
                </Fragment>
              )}
              {deviceInfo && pubInitialised && (
                <Fragment className="pull-left">
                  <p className="text-muted mb-0 text-left">{t('teleexpertise.audioSource')}</p>
                  <p className="text-muted mb-0 text-left">
                    {deviceInfo.audioInputDevices && (
                      <select value={audioDevice} onChange={handleAudioSource}>
                        {deviceInfo.audioInputDevices.map(device => (
                          <option key={device.deviceId} value={device.deviceId}>
                            {device.label}
                          </option>
                        ))}
                      </select>
                    )}
                  </p>
                </Fragment>
              )}
              <Line percent={logLevel} strokeWidth={2} strokeColor={'#6f42c1'} />
              <p className="text-muted mb-0 text-left">{t('teleexpertise.enableMicrophone')}</p>
              <p class="text-muted mb-0 text-left">
                <Toggle onChange={handleAudioChange} checked={localAudio} />
              </p>
              <hr class="divider_pre_call centre" />
              <i class="fa fa-chrome text-muted mb-0 ">{t('teleexpertise.browser')}</i>
              <p class="text-muted mb-0">
                {OT.checkSystemRequirements() ? (
                  <i className="fa fa-check-square-o  fs-6" />
                ) : (
                  <i className="fa fa-exclamation-circle fs-6" />
                )}
              </p>
              <br />
              <p class="text-muted mb-0">
                <button
                  className="btn btn-outline-warning mr-md-3 mb-2 mb-md-0"
                  onClick={reRunNetworkTest}
                >
                  {isRunning ? t('teleexpertise.testRunning') : t('teleexpertise.runTest')}
                </button>
                {showQualityDialog && (
                  <button
                    className="btn btn-outline-success mr-md-3 mb-2 mb-md-0"
                    onClick={handleJoinClick}
                  >
                    {t('teleexpertise.joinCall')}
                  </button>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default compose(
  PageLayout,
  inject('TeleexpertiseCtrl'),
  observer,
)(Teleexpertise)
