import { observable, action, decorate, runInAction } from 'mobx'

import { createManagerClaim, getManagerClaimForm } from 'services/claim'
import { getSelfCareForm, createSelfCareClaim } from 'services/selfCare'
import Form from 'utils/dynform/Form'

class CreateManagerClaimCtrl {
  showModal = false
  showSelfCareModal = false
  loading = false
  form = null
  formSelfCare = null

  setProperty(key, value) {
    if (key === 'showModal' && value === true) {
      this.showSelfCareModal = false
    }

    if (key === 'showSelfCareModal' && value === true) {
      this.showModal = false
    }

    this[key] = value
  }

  save = async formData => {
    let claim = await createManagerClaim(formData)
    return claim.wan
  }

  saveSelfCare = async formData => {
    let claim = await createSelfCareClaim(formData)
    return claim.wan
  }

  loadData = async () => {
    this.loading = true

    try {
      const form = await getManagerClaimForm()
      form.name = 'ClaimFileManager'
      runInAction(() => {
        this.form = new Form({ form, isCreationForm: true })
      })
    } catch (err) {
      console.error(err)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  loadDataSelfCare = async () => {
    this.loading = true

    try {
      const form = await getSelfCareForm()
      form.name = 'selfCare'
      runInAction(() => {
        this.formSelfCare = new Form({ form, isCreationForm: true })
      })
    } catch (err) {
      console.error(err)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }
}

const DecoratedCreateManagerClaimCtrl = decorate(CreateManagerClaimCtrl, {
  showModal: observable,
  showSelfCareModal: observable,
  loading: observable,
  form: observable,
  formSelfCare: observable,

  loadData: action,
  loadDataSelfCare: action,
  setProperty: action.bound,
  save: action,
  saveSelfCare: action,
})

export default new DecoratedCreateManagerClaimCtrl()
