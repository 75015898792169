import React, { useState } from 'react'
import { observer } from 'mobx-react'

import { SupportingDocFileTypes } from 'stores/Common/view/SupportingDocumentsCtrl'
import UploadCtrl from 'stores/Common/view/UploadCtrl'
import Dropzone from 'react-dropzone'
import { Thumbnail } from 'pages/Mission/components/LiteGallery'
import ImageLoading from 'components/ImageLoading/ImageLoading'

const UploadPhoto = ({
  photoId,
  setPhotoId,
  filename,
  documentType = 'POTH',
  wan,
  deletePhotoId,
}) => {
  const [loading, setLoading] = useState(false)
  return (
    <Dropzone
      className="mr-4 clickable d-flex flex-flow-column align-items-center"
      multiple={false}
      onDrop={async (acceptedFiles, rejectedFiles) => {
        const res = await UploadCtrl.simpleProcessDocCreation(
          acceptedFiles[0],
          filename,
          documentType,
          wan,
          setLoading,
        )
        setPhotoId(res)
      }}
      accept={SupportingDocFileTypes.PHOTO}
    >
      <div className="upload-img-drop-zone border rounded border-primary">
        {loading && (
          <div onClick={e => e.stopPropagation()}>
            <ImageLoading />
          </div>
        )}
        {!loading && photoId && (
          <div className="preview">
            <Thumbnail
              data={{ id: photoId, name: filename }}
              thumbnailWidth="92px"
              thumbnailHeight="92px"
              className="d-flex align-items-center justify-content-center"
            />

            <button
              className="btn btn-danger delete-file rounded-circle"
              onClick={e => {
                e.stopPropagation()
                deletePhotoId()
              }}
            >
              <i className="fa fa-times" />
            </button>
          </div>
        )}
        {!loading && !photoId && <i className="fa fa-camera" aria-hidden="true"></i>}
      </div>
      <div>{filename}</div>
    </Dropzone>
  )
}

export default observer(UploadPhoto)
