import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import Label from 'components/Label/Label'
import Button from 'components/Button/Button'
import Select from 'components/Forms/Select'

const SelfCareWho = ({
  SelfCareStore: { wan, ORIGIN_EVENTS },
  SelCareWhoCtrl: {
    originEvent,
    thirdPartyCivility,
    thirdPartyFirstName,
    thirdPartyLastName,
    setProperty,
    isValid,
    loading,
    save,
    back,
  },
}) => {
  const { t } = useTranslation()

  return (
    <Fragment>
      <div className="row no-gutters">
        <div className="form-group col-12 mt-4">
          <Label required={true} className="d-block">
            Qui est à l'origine de l'évènement ?
          </Label>
          <div className="btn-group pl-0 d-flex">
            {ORIGIN_EVENTS.map(origin => (
              <button
                key={`button-originEvent-${origin}`}
                className={classNames('flex-basis-1-4 btn', {
                  'btn-primary': originEvent === origin,
                  'btn-outline-primary': originEvent !== origin,
                })}
                onClick={() => setProperty('originEvent', origin)}
              >
                {t(`selfCare.originEvents.${origin}`)}
              </button>
            ))}
          </div>
        </div>
      </div>

      {originEvent === 'THIRD_PARTY' && (
        <div className="row no-gutters">
          <div className="form-group col-8">
            <Label>Civilité</Label>
            <Select
              className="form-control"
              name="thirdPartyCivility"
              options={{ 'M.': 'Monsieur', Mme: 'Madame' }}
              value={thirdPartyCivility}
              onChange={e => setProperty('thirdPartyCivility', e.target.value)}
              placeholder=" - Selection - "
            />
          </div>
        </div>
      )}

      {originEvent === 'THIRD_PARTY' && (
        <div className="row no-gutters">
          <div className="form-group col-12 mt-4">
            <Label className="d-block">Nom du tiers</Label>
            <input
              className="form-control"
              type="text"
              max="25"
              name="thirdPartyLastName"
              onChange={e => setProperty('thirdPartyLastName', e.target.value)}
              value={thirdPartyLastName}
            />
          </div>
        </div>
      )}

      {originEvent === 'THIRD_PARTY' && (
        <div className="row no-gutters">
          <div className="form-group col-12 mt-4">
            <Label className="d-block">Prénom du tiers</Label>
            <input
              className="form-control"
              type="text"
              max="25"
              name="thirdPartyFirstName"
              onChange={e => setProperty('thirdPartyFirstName', e.target.value)}
              value={thirdPartyFirstName}
            />
          </div>
        </div>
      )}

      <div className="row no-gutters mt-4 d-flex justify-content-between">
        <Button
          className="btn btn-secondary"
          onClick={() => back(wan)}
          disabled={loading}
          loading={loading}
        >
          Etape précédente
        </Button>

        <Button
          className="btn btn-primary"
          onClick={() => save(wan)}
          disabled={!isValid || loading}
          loading={loading}
        >
          Etape suivante
        </Button>
      </div>
    </Fragment>
  )
}

export default inject('SelfCareStore', 'SelCareWhoCtrl')(observer(SelfCareWho))
