import { action, computed, decorate, observable, runInAction } from 'mobx'
import { path } from 'ramda'

import { fetchSelfCare, getOrientationSelfCare } from 'services/selfCare'
import { fetchRiskTypesSelfCare } from 'services/riskTypes'
import { fetchRoomListSelfCare } from 'services/calculation'
import { fetchCoveragesSelfCare } from 'services/coverage'
import PartyInvolvedStore from 'stores/Common/domain/PartyInvolvedStore'
import SelCareWhereCtrl from 'stores/SelfCare/view/SelCareWhereCtrl'
import SelCareWhatCtrl from 'stores/SelfCare/view/SelCareWhatCtrl'
import SelCareWhoCtrl from 'stores/SelfCare/view/SelCareWhoCtrl'
import SelCareEstimationCtrl from 'stores/SelfCare/view/SelCareEstimationCtrl'
import SelCareIndemnisationCtrl from 'stores/SelfCare/view/SelCareIndemnisationCtrl'
import SelCareFinishCtrl from 'stores/SelfCare/view/SelCareFinishCtrl'
import SearchPackageCtrl from 'stores/Mission/view/SearchPackageCtrl'
import CartStore from 'stores/Mission/domain/CartStore'
import AddrelatedCostCtrl from 'stores/Mission/view/AddRelatedCostCtrl'

export const NO_DAMAGE = 'NO_DAMAGE'
export const ORIGIN_EVENT_YOUR_SELF = 'YOURSELF'
export const ORIGIN_EVENT_THIRD_PARTY = 'THIRD_PARTY'
export const ORIGIN_EVENT_NATURE = 'NATURAL_PHENOMENON'
export const ORIGIN_EVENT_INDEFINITE = 'INDEFINITE'

class SelfCareStore {
  loading = false
  id = null
  wan = null
  claim = null
  RISK_TYPES = []
  ROOM_TYPES = ['I', 'O', NO_DAMAGE]
  ROOMS_LIST = {}
  COVERAGES = {}
  COVERAGE_EVENTS = {}
  ORIGIN_EVENTS = [
    ORIGIN_EVENT_YOUR_SELF,
    ORIGIN_EVENT_THIRD_PARTY,
    ORIGIN_EVENT_NATURE,
    ORIGIN_EVENT_INDEFINITE,
  ]

  loadData = async wan => {
    this.loading = true
    this.wan = wan
    this.id = wan
    try {
      const claim = await fetchSelfCare(wan)
      const riskTypes = await fetchRiskTypesSelfCare()
      const roomList = await fetchRoomListSelfCare({ wan })
      const coverages = await fetchCoveragesSelfCare()
      console.log('claim data manager: ', claim)
      runInAction(() => {
        this.claim = claim
        this.RISK_TYPES = riskTypes
        this.ROOMS_LIST = roomList
        coverages.forEach(coverage => {
          this.COVERAGES[coverage.id] = coverage.attributes.value
          this.COVERAGE_EVENTS[coverage.id] = coverage.attributes.events.filter(event => {
            return event.selfCare === true
          })
        })
      })

      PartyInvolvedStore.loadData(claim.involvedParties)
      SelCareWhereCtrl.loadData(claim)
      SelCareWhatCtrl.loadData(claim)
      SelCareWhoCtrl.loadData(claim)
      await this.loadEstimation()
      this.loadIndemnisation()
      this.loadFinish()
    } catch (err) {
      console.log('error:', err)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  updateClaim = async claim => {
    this.claim = claim
    await this.loadEstimation()
    this.loadIndemnisation()
    this.loadFinish()
  }

  loadEstimation = async () => {
    if (this.selfCareStatus !== 'estimation') {
      return
    }

    await SearchPackageCtrl.loadMissionRooms(this.wan, { selfCare: 1 })
    await AddrelatedCostCtrl.loadRelatedCostTypeSelfCare({
      wan: this.wan,
      coverage: this.claim.claimInformation.coverageKey,
    })
    await CartStore.fetch(this.wan, this.claim)
    await SelCareEstimationCtrl.loadData(this.claim)
  }

  loadIndemnisation = async () => {
    if (this.selfCareStatus !== 'indemnisation' && this.selfCareStatus !== 'select_indemnisation') {
      return
    }

    const orientationSelfCare = await getOrientationSelfCare(this.wan)
    SelCareIndemnisationCtrl.loadData(this.claim, orientationSelfCare)
  }

  loadFinish = async () => {
    if (this.selfCareStatus !== 'finish' && this.selfCareStatus !== 'abandon') {
      return
    }
    SelCareFinishCtrl.loadData(this.claim)
  }

  setProperty = (key, value) => {
    this[key] = value
  }

  get selfCareStatus() {
    return path(['selfCareStatus'], this.claim)
  }

  get stepperPositionFromStatus() {
    switch (this.selfCareStatus) {
      case 'where':
        return 1
      case 'what':
        return 2
      case 'who':
        return 3
      case 'estimation':
        return 4
      case 'indemnisation':
      case 'select_indemnisation':
        return 5
      case 'finish':
        return 6
      case 'abandon':
        return 99
      default:
        return 0
    }
  }
}

const DecoratedSelfCareStore = decorate(SelfCareStore, {
  loading: observable,

  id: observable,
  wan: observable,
  claim: observable,
  RISK_TYPES: observable,
  ROOM_TYPES: observable,
  ROOMS_LIST: observable,
  COVERAGES: observable,
  COVERAGE_EVENTS: observable,

  selfCareStatus: computed,
  stepperPositionFromStatus: computed,

  loadData: action,
  setProperty: action,
  updateClaim: action,
})

export default new DecoratedSelfCareStore()
