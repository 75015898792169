import { path, isNil, add, type } from 'ramda'

import { roundDecimal } from 'utils/currency'

export const getMultiplier = (packageData, rcpData) => {
  const { part } = rcpData
  const isArea = part === 'W' || part === 'C' || part === 'F'

  if (packageData.unit === 'M2' && isArea) {
    return packageData.area
  } else if (packageData.unit === 'ML' && isArea) {
    return Math.sqrt(packageData.area) * 4
  }

  return packageData.quantity
}

export const computeTotalItems = (rcpPackages, rcpData) => {
  return rcpPackages.reduce((acc, data) => {
    let rcp = null
    if (type(rcpData) === 'Array') {
      rcp = rcpData.find(rcpTmp => rcpTmp.groupId === data.groupId)
    } else rcp = rcpData

    data.items.forEach(item => {
      if (item.piActive) {
        acc += item.totalPriceSupplies * getMultiplier(data, rcp)
        acc += item.totalPriceMaterials * getMultiplier(data, rcp)
        acc += item.totalPriceTools * getMultiplier(data, rcp)
      }
    })
    return acc
  }, 0)
}

export const computeTotalItemsSelfRepair = (rcpPackages, rcpData) => {
  return rcpPackages.reduce((acc, data) => {
    let rcp = null
    if (type(rcpData) === 'Array') {
      rcp = rcpData.find(rcpTmp => rcpTmp.groupId === data.groupId)
    } else rcp = rcpData

    data.items.forEach(item => {
      if (item.piActive) {
        acc += item.totalPriceSupplies * getMultiplier(data, rcp) * 1.2
        acc += item.totalPriceMaterials * getMultiplier(data, rcp) * 1.2
        acc += item.totalPriceTools * getMultiplier(data, rcp)
      }
    })
    return acc
  }, 0)
}

export const computeTotalByKey = (rcpPackages, key) => {
  return rcpPackages.reduce((acc, data) => add(acc, data[key]), 0)
}

export const computeIdemMode = rcpPackages => {
  const rcpPackage = rcpPackages[0]
  return rcpPackage.selfRepair ? 'GAG' : rcpPackage.ren === true ? 'REN' : 'IP'
}

export const computeUnitPrice = (rcpPackages, part, area, quantity) => {
  const isSquareMeter = part === 'C' || part === 'W' || part === 'F'
  const total = rcpPackages.reduce((acc, data) => add(acc, data.finalPrice), 0)

  if (isSquareMeter) return total / area

  return total / quantity
}

export const getWall = percentageDammaged => {
  switch (percentageDammaged) {
    case 100:
      return 4
    case 75:
      return 3
    case 50:
      return 2
    case 25:
      return 1
    default:
      return 4
  }
}

export const computeWallArea = (percentageDammaged, area) => {
  const height = 2.5
  return Math.sqrt(area) * getWall(percentageDammaged) * height
}

export const computeArea = (packageData, currentRoom, percentageDammaged, area, selectedPart) => {
  let finalArea = null
  const type = path(['easyEstimationData', 'areaPercentageType'], packageData)
  const currentHeight = path(['roomSizes', 0, 'height'], currentRoom)
  const height = isNil(currentHeight) ? 2.5 : parseFloat(currentHeight)
  const areaPercentagePercentage =
    path(['easyEstimationData', 'areaPercentagePercentage'], packageData) || 100

  // SPECIAL RULE FOR V PART
  if (selectedPart === 'V' || selectedPart === 'O') {
    finalArea = packageData.quantity
  }
  // PACKAGE => ML
  else if (packageData.unit === 'ML') {
    if (type === 'TOTAL') {
      finalArea = Math.sqrt(area) * 4
    } else if (type === 'DAMMA') {
      finalArea = Math.sqrt(area) * 4 * (percentageDammaged / 100)
    } else if (type === 'TO-DA') {
      finalArea = Math.sqrt(area) * 4 - Math.sqrt(area) * 4 * (percentageDammaged / 100)
    }
  }
  // PACKAGE => M2
  else if (packageData.unit === 'M2') {
    // WALL
    if (type === 'TOTAL' && selectedPart === 'W') {
      finalArea = Math.sqrt(area) * 4 * height
    } else if (type === 'DAMMA' && selectedPart === 'W') {
      finalArea = Math.sqrt(area) * getWall(percentageDammaged) * height
    } else if (type === 'TO-DA' && selectedPart === 'W') {
      finalArea =
        Math.sqrt(area) * 4 * height - Math.sqrt(area) * getWall(percentageDammaged) * height
    }
    // REST
    else if (type === 'TOTAL') {
      finalArea = area
    } else if (type === 'DAMMA') {
      finalArea = area * (percentageDammaged / 100)
    } else if (type === 'TO-DA') {
      finalArea = area - area * (percentageDammaged / 100)
    }
  }
  // PACKAGE QUANTITY UNIT
  else {
    finalArea = packageData.quantity
  }

  // COMPUTE areaPercentagePercentage
  finalArea = finalArea * (areaPercentagePercentage / 100)
  finalArea = roundDecimal(finalArea)

  // PACKAGE AREA
  if (!isNil(finalArea) && packageData.unit === 'M2') {
    packageData.setProperty('overrideAreaSquare', finalArea)
    // FORCE REACTION IS NOT TRIGGERED
    packageData.items.forEach(item => item.setProperty('areaSquare', finalArea))
  }
  // PACKAGE UNIT
  else if (!isNil(finalArea) && !packageData.areaPresent) {
    packageData.setProperty('quantity', finalArea)
  } else {
    console.warn('Warning : something went wrong when computing area. Value is ', finalArea)
  }
}
