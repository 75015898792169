import React, { Fragment, useEffect } from 'react'
import { observer, inject } from 'mobx-react'
import Dropzone from 'react-dropzone'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import { useParams } from 'react-router-dom'

import { SupportingDocFileTypes } from 'stores/Common/view/SupportingDocumentsCtrl'
import UploadCtrl from 'stores/Common/view/UploadCtrl'
import UploadInvoiceModal from 'components/UploadInvoiceModal/UploadInvoiceModal'
import LiteGallery from 'pages/Mission/components/LiteGallery'

const WorkRequestQuotations = ({
  t,
  WorkRequestCtrl: { addSupportingDocument, removeSupportingDocument, quotations },
}) => {
  useEffect(() => {
    UploadCtrl.setProperty('context', 'supportingDocumentWorkRequest')
    UploadCtrl.setProperty('addSupportingDocument', addSupportingDocument)
  })

  const { id } = useParams()

  const onDrop = (acceptedFiles, rejectedFiles) => {
    UploadCtrl.setDefaultType('IQUO')
    UploadCtrl.setInvoiceAcceptedFiles(acceptedFiles)
  }

  return (
    <Fragment>
      <UploadInvoiceModal wan={id} datepickerPlacement="top" />
      <div className="form-group row no-gutters">
        <label className="col-sm-4 required">
          Devis
          <span className="text-danger font-weight-bold">*</span>
        </label>
        <div className="col">
          <Dropzone
            className="btn btn-outline-primary mr-2 clickable"
            multiple={false}
            onDrop={(acceptedFiles, rejectedFiles) => {
              onDrop(acceptedFiles, rejectedFiles)
            }}
            accept={SupportingDocFileTypes.DOCUMENT}
          >
            {t('mission.upload.quotation')} <i className="fa fa-upload" />
          </Dropzone>
          <LiteGallery
            supportingDocuments={quotations}
            forPackages={true}
            hasDeleteBtn={true}
            onDelete={removeSupportingDocument}
          />
        </div>
      </div>
    </Fragment>
  )
}

export default compose(
  inject('WorkRequestCtrl'),
  withTranslation(),
  observer,
)(WorkRequestQuotations)
