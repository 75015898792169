import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import ButtonWithConfirmation from 'components/ButtonWithConfirmation/ButtonWithConfirmation'
import { euro } from 'utils/currency'
import Button from 'components/Button/Button'

const RelatedCostShow = ({
  onEdit,
  onDelete,
  relatedCost: { description, priceWithVAT },
  loading = false,
}) => {
  const { t } = useTranslation()

  return (
    <div className="row no-gutters d-flex justify-content-between align-items-center p-2 mb-2">
      <div className="col-6">{description}</div>
      <div className="col-3 d-flex justify-content-between">
        <div>{euro(priceWithVAT)}</div>
      </div>

      <div className="col-3 d-flex align-items-center justify-content-end">
        <Button
          className="btn btn-outline-primary rounded-circle mr-1"
          onClick={onEdit}
          disabled={loading}
        >
          <i className="fa fa-edit" />
        </Button>

        <ButtonWithConfirmation
          className="btn btn-outline-primary rounded-circle"
          icon
          iconClassName="fa fa-trash"
          onClick={onDelete}
          confirmText={t('mission.calculation.cart.confirmDelete')}
          disabled={loading}
        />
      </div>
    </div>
  )
}

export default observer(RelatedCostShow)
