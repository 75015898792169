import React from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import classNames from 'classnames'

import Tooltip from 'components/Tooltip/Tooltip'
import { MISSION_TYPE_ID, MISSION_WAIT_TYPE_ID } from 'utils/constants'
import { concatNames, arrayToDivs } from 'utils'

function Event({
  t,
  CommonStore: { coveragesData },
  CalendarExpertStore: { viewType },
  UserStore: { isInsurer },
  event: {
    type: eventType,
    obj: {
      title,
      address,
      insurerClaimId,
      coverageKey,
      missionNature,
      expertiseCharacteristic,
      expertiseType,
      expertiseTypeKey,
      contactName,
    },
  },
}) {
  const addressFormat = address ? concatNames(address.zipCode, address.city) : ''
  const showCoverageKeyColor =
    isInsurer && viewType === 'week' && (eventType === 'MISS' || eventType === 'WAIT')

  let eventName = ''
  let eventNameMonth = ''
  switch (eventType) {
    case MISSION_TYPE_ID:
    case MISSION_WAIT_TYPE_ID:
      eventName = coveragesData[coverageKey]
      break
    default:
      eventName = title
  }

  eventNameMonth = eventName

  let text = '-'
  if (insurerClaimId) {
    const textDivs = [insurerClaimId, contactName, addressFormat, eventName, expertiseType]
    if (eventType === MISSION_WAIT_TYPE_ID) textDivs.unshift(t('calendar.appointment.WAIT'))
    text = arrayToDivs(textDivs)
    eventNameMonth += ` ${insurerClaimId}`
  } else {
    text = eventName
  }

  return (
    <Tooltip text={text} innerClassName="text-white" placement="top">
      <div className={classNames('my-event', { 'pt-1': viewType !== 'month' })}>
        {viewType === 'month' && <div>{eventNameMonth}</div>}
        {viewType !== 'month' && (
          <div className="rbc-event-content mb-1">
            <div className="mb-1">
              {!!insurerClaimId && (
                <div className="text-truncate mb-1">
                  {showCoverageKeyColor && (
                    <span
                      className={classNames(
                        'd-inline-block rounded-circle mr-2',
                        `coverage--${coverageKey}`,
                      )}
                      style={{ height: '12px', width: '12px' }}
                    />
                  )}
                  {missionNature === 'Mission urgente' && (
                    <span className="text-danger mr-2">
                      <i className="fa fa-warning" />
                    </span>
                  )}
                  {expertiseTypeKey === 'GROUND_EXPERTISE_TYPE' && (
                    <i className="fa fa-home" aria-hidden="true"></i>
                  )}
                  {expertiseTypeKey === 'TELE_EXPERTISE_TYPE' && (
                    <i className="fa fa-mobile" aria-hidden="true"></i>
                  )}
                  {expertiseTypeKey === 'VISIO_EXPERTISE_TYPE' && (
                    <i className="fa fa-video-camera" aria-hidden="true"></i>
                  )}{' '}
                  {expertiseCharacteristic && expertiseCharacteristic.includes('Contradictoire') && (
                    <span className="text-dark mr-2">
                      <i className="fa fa-calendar" />
                    </span>
                  )}
                  {eventType === MISSION_WAIT_TYPE_ID && `${t('calendar.appointment.WAIT')} : `}{' '}
                  {insurerClaimId}
                </div>
              )}
              {contactName && <div className="text-truncate mb-1">{contactName}</div>}
              {!!addressFormat && <div className="address mb-1 text-truncate">{addressFormat}</div>}
              <span className="text-truncate">{eventName}</span>
            </div>
          </div>
        )}
      </div>
    </Tooltip>
  )
}

export default compose(
  inject('CommonStore', 'CalendarExpertStore', 'UserStore'),
  withTranslation(),
  observer,
)(Event)
