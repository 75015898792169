import React from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { groupBy, keys, path, add } from 'ramda'

import Unit from 'pages/EasyEstimation/components/Unit'
import TotalTableDescriptionPrejudices from 'pages/Mission/pages/Conclusion/TotalTableDescriptionPrejudices'
import { computeTotalItems, computeTotalByKey, computeUnitPrice } from 'utils/easyEstimation'
import { euro } from 'utils/currency'

const SpecialType = observer(({ type, totalWithoutVAT, totalWithVAT, data }) => {
  const { t } = useTranslation()
  return (
    <React.Fragment>
      <tr>
        <td className="font-weight-bold">{t(`mission.conclusion.data.${type}`)}</td>
        <td />
        <td />
        <td />
        <td />
        <td className="text-center align-middle">{euro(totalWithoutVAT)}</td>
        <td className="text-center align-middle">{euro(totalWithVAT)}</td>
      </tr>
      {data.map(rowData => (
        <tr key={`row-${type}-${rowData.id}`}>
          <td>{rowData.label}</td>
          <td className="text-center align-middle">
            {' '}
            {type === 'leak'
              ? rowData.ren === true
                ? 'REN'
                : 'Ent. ext.'
              : type === 'relatedCost'
              ? rowData.ren === true
                ? 'REN'
                : rowData.selfRepair
                ? 'GAG'
                : 'Ent. ext.'
              : ''}
          </td>
          <td />
          <td />
          <td />
          <td className="text-center align-middle">{euro(rowData.priceWithoutVAT)}</td>
          <td className="text-center align-middle">{euro(rowData.priceWithVAT)}</td>
        </tr>
      ))}
    </React.Fragment>
  )
})

const RCPRow = observer(({ rcpData, rcpPackages }) => {
  const packages = rcpPackages.filter(rcpPackage => rcpPackage.groupId === rcpData.groupId)
  const totalItems = computeTotalItems(packages, rcpData)
  const totalWithoutVAT = computeTotalByKey(packages, 'finalPriceWithoutVAT')
  const totalWithVAT = computeTotalByKey(packages, 'finalPrice')
  const unitPrice = computeUnitPrice(packages, rcpData.part, rcpData.area, rcpData.quantity)
  const packageData = path([0], packages)

  return (
    <tr>
      <td>{rcpData.covering.label}</td>
      <td className="text-center align-middle">
        {packageData.selfRepair ? 'GAG' : packageData.ren === true ? 'REN' : 'Ent. ext.'}
      </td>
      <td className="text-center align-middle text-nowrap">
        <Unit
          part={rcpData.part}
          unitByDefault={rcpData.unitByDefault}
          area={rcpData.area}
          quantity={rcpData.quantity}
        />
      </td>
      <td className="text-center align-middle">{euro(unitPrice)}</td>
      <td className="text-center align-middle">{euro(totalItems)}</td>
      <td className="text-center align-middle">{euro(totalWithoutVAT)}</td>
      <td className="text-center align-middle">{euro(totalWithVAT)}</td>
    </tr>
  )
})

const RoomRow = inject('CartStore')(
  observer(({ roomId, rcp, CartStore: { packages } }) => {
    const rcpPackages = rcp.reduce((acc, rcpData) => {
      return [...acc, ...packages.filter(packageData => packageData.groupId === rcpData.groupId)]
    }, [])
    const totalItems = computeTotalItems(rcpPackages, rcp)
    const totalWithoutVAT = computeTotalByKey(rcpPackages, 'finalPriceWithoutVAT')
    const totalWithVAT = computeTotalByKey(rcpPackages, 'finalPrice')

    return (
      <React.Fragment>
        <tr>
          <td className="font-weight-bold">{path([0, 'roomLabel'], rcp)}</td>
          <td />
          <td />
          <td />
          <td className="text-center align-middle">{euro(totalItems)}</td>
          <td className="text-center align-middle">{euro(totalWithoutVAT)}</td>
          <td className="text-center align-middle">{euro(totalWithVAT)}</td>
        </tr>

        {rcp.map(rcpData => (
          <RCPRow
            key={`room-${roomId}-${rcpData.groupId}`}
            groupId={rcpData.groupId}
            rcpData={rcpData}
            rcpPackages={rcpPackages}
          />
        ))}
      </React.Fragment>
    )
  }),
)

const TableProperty = ({
  CartStore: { rcp, packages, relatedCostPackages, leakPackages, easyEstimation },
  ManagerClaimStore: { isWaterDamage },
}) => {
  if (!easyEstimation) return <TotalTableDescriptionPrejudices showPrejudiceStore={false} />

  const rcpByRoomId = groupBy(data => data.roomId)(rcp)
  const roomIds = keys(rcpByRoomId)
  const RCTotalWithoutVAT = relatedCostPackages.reduce(
    (acc, data) => add(acc, data.priceWithoutVAT),
    0,
  )
  const RCTotalWithVAT = relatedCostPackages.reduce((acc, data) => add(acc, data.priceWithVAT), 0)
  const relatedCostFormatted = relatedCostPackages.map(
    ({ id, label, priceWithoutVAT, priceWithVAT, ren, selfRepair }) => ({
      id,
      label,
      priceWithoutVAT,
      priceWithVAT,
      ren,
      selfRepair,
    }),
  )

  const leakTotalWithoutVAT = leakPackages.reduce(
    (acc, data) => add(acc, data.finalPriceWithoutVAT),
    0,
  )
  const leakTotalWithVAT = leakPackages.reduce((acc, data) => add(acc, data.finalPrice), 0)
  const leakFormatted = leakPackages.map(
    ({ id, label, ren, finalPriceWithoutVAT, finalPrice }) => ({
      id,
      label,
      ren,
      priceWithoutVAT: finalPriceWithoutVAT,
      priceWithVAT: finalPrice,
    }),
  )

  const totalWithoutVAT =
    packages.reduce((acc, data) => add(acc, data.finalPriceWithoutVAT), 0) + RCTotalWithoutVAT
  const totalWithVAT = packages.reduce((acc, data) => add(acc, data.finalPrice), 0) + RCTotalWithVAT

  return (
    <table className="table table-bordered d-none d-md-table">
      <tbody>
        <tr>
          <th className="align-middle">Poste de dommages</th>
          <th className="text-center align-middle">Choix indem.</th>
          <th className="text-center align-middle">QT</th>
          <th className="text-center align-middle">
            Prix unit. <small>(€ / unité)</small>
          </th>
          <th className="text-center align-middle">Coût fournitures</th>
          <th className="text-center align-middle">VAN HT</th>
          <th className="text-center align-middle">VAN TTC</th>
        </tr>

        {roomIds.map(roomId => (
          <RoomRow roomId={roomId} rcp={rcpByRoomId[roomId]} key={`room-${roomId}`} />
        ))}

        <SpecialType
          type="relatedCost"
          totalWithoutVAT={RCTotalWithoutVAT}
          totalWithVAT={RCTotalWithVAT}
          data={relatedCostFormatted}
        />

        {isWaterDamage && (
          <SpecialType
            type="leak"
            totalWithoutVAT={leakTotalWithoutVAT}
            totalWithVAT={leakTotalWithVAT}
            data={leakFormatted}
          />
        )}

        <tr>
          <td className="font-weight-bold">Total HT</td>
          <td />
          <td />
          <td />
          <td />
          <td className="text-center align-middle">{euro(totalWithoutVAT)}</td>
          <td />
        </tr>

        <tr>
          <td className="font-weight-bold">Total TTC</td>
          <td />
          <td />
          <td />
          <td />
          <td />
          <td className="text-center align-middle">{euro(totalWithVAT)}</td>
        </tr>
      </tbody>
    </table>
  )
}

export default inject('CartStore', 'ManagerClaimStore')(observer(TableProperty))
