import React from 'react'
import { observer, inject } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames'
import { compose } from 'recompose'

import SupportingDocumentStore from 'stores/Common/domain/SupportingDocumentStore'
import SupportingDocumentsCtrl, {
  SupportingDocTypes,
} from 'stores/Common/view/SupportingDocumentsCtrl'
import Tooltip from 'components/Tooltip/Tooltip'

const Filters = ({ t, UiCtrl: { deviceType } }) => {
  const selectFilter = filter => {
    SupportingDocumentsCtrl.setDropZoneFiltered(false)
    SupportingDocumentsCtrl.setFilter(filter)
  }

  return (
    <React.Fragment>
      <div className="main-filters-wrapper justify-content-between d-flex align-items-center">
        <ul className="nav nav-bar filters">
          <li className="nav-item">
            <span
              className={classNames('nav-link', {
                active: SupportingDocumentsCtrl.sdCategory === SupportingDocTypes.PHOTO,
              })}
              onClick={selectFilter.bind(this, SupportingDocTypes.PHOTO)}
            >
              {deviceType === 'mobile' ? (
                <i className="fa fa-image" />
              ) : (
                t('mission.supportingDocuments.type.photos', {
                  counter: SupportingDocumentStore.sdByPhotos.length,
                })
              )}
            </span>
          </li>
          <span className="divider" />
          <li className="nav-item">
            <span
              className={classNames('nav-link', {
                active: SupportingDocumentsCtrl.sdCategory === SupportingDocTypes.DOCUMENT,
              })}
              onClick={selectFilter.bind(this, SupportingDocTypes.DOCUMENT)}
            >
              {deviceType === 'mobile' ? (
                <i className="fa fa-file" />
              ) : (
                t('mission.supportingDocuments.type.documents', {
                  counter: SupportingDocumentStore.sdByDocuments.length,
                })
              )}
            </span>
          </li>
          <span className="divider" />
          <li className="nav-item">
            <span
              className={classNames('nav-link', {
                active: SupportingDocumentsCtrl.sdCategory === SupportingDocTypes.SCHEMA,
              })}
              onClick={selectFilter.bind(this, SupportingDocTypes.SCHEMA)}
            >
              {deviceType === 'mobile' ? (
                <i className="fa fa-pencil" />
              ) : (
                t('mission.supportingDocuments.type.schemas', {
                  counter: SupportingDocumentStore.sdBySchemas.length,
                })
              )}
            </span>
          </li>
          <span className="divider" />
          <li className="nav-item">
            <span
              className={classNames('nav-link', {
                active: SupportingDocumentsCtrl.sdCategory === SupportingDocTypes.INVOICE,
              })}
              onClick={selectFilter.bind(this, SupportingDocTypes.INVOICE)}
            >
              {deviceType === 'mobile' ? (
                <i className="fa fa-file-archive-o" />
              ) : (
                t('mission.supportingDocuments.type.invoices', {
                  counter: SupportingDocumentStore.sdByInvoices.length,
                })
              )}
            </span>
          </li>
        </ul>
        <ul className="nav nav-bar">
          <li className="action-bar">
            <div>
              <Tooltip
                className="tooltip-action-bar"
                text={t('mission.supportingDocuments.sendPicture')}
              >
                <button
                  className={classNames('btn btn-outline-primary add-sd')}
                  onClick={() => {
                    SupportingDocumentsCtrl.dropZoneFiltered
                      ? SupportingDocumentsCtrl.setDropZoneFiltered(false)
                      : SupportingDocumentsCtrl.setDropZoneFiltered(true)
                    SupportingDocumentsCtrl.resetSdSelected()
                  }}
                >
                  {SupportingDocumentsCtrl.dropZoneFiltered ? (
                    <i className="fa fa-th" />
                  ) : (
                    <i className="fa fa-upload" />
                  )}
                </button>
              </Tooltip>
            </div>
          </li>
        </ul>
      </div>
    </React.Fragment>
  )
}

export default compose(
  inject('UiCtrl', 'MissionStore'),
  withTranslation(),
  observer,
)(Filters)
