import { observable, computed, action, decorate } from 'mobx'

import AbstractField from 'utils/dynform/AbstractField'
import PartyInvolvedStore from 'stores/Common/domain/PartyInvolvedStore.js'

class InvolvedPartyType extends AbstractField {
  errors = []

  expanded = false
  personType = null

  constructor(data) {
    super(data)

    if (data.value) {
      this.personType = PartyInvolvedStore.typePartyInvolved(data.value)
      this.value = data.value
    } else if (data.default) {
      this.personType = PartyInvolvedStore.typePartyInvolved(data.default)
      this.value = data.default
    } else {
      this.value = ''
    }

    this.show = !(data.show === false)
    this.originalValue = this.value

    this.involvedPartyFilter = data.involvedPartyFilter
    this.involvedPartyTypeFilter = data.involvedPartyTypeFilter
    this.showAddressForBusiness = data.showAddressForBusiness
    this.showAddressForPersonal = data.showAddressForPersonal
  }

  get changed() {
    return this.value !== this.originalValue
  }

  setOriginalData = () => {
    this.value = this.originalValue
  }
}

const DecoratedInvolvedPartyType = decorate(InvolvedPartyType, {
  value: observable,
  originalValue: observable,
  errors: observable,
  show: observable,
  personType: observable,

  setOriginalData: action,
  setPartyInvolved: action,

  changed: computed,
})

export default DecoratedInvolvedPartyType
