import React, { Fragment, useState } from 'react'
import { inject, observer } from 'mobx-react'
import classNames from 'classnames'
import { path } from 'ramda'

import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'
import { concatAddress, formatPhone } from 'utils'
import useClickOutside from 'hooks/useClickOutside'

import './Stepper.css'
const Stepper = ({
  SelfCareStore: { loading, claim, stepperPositionFromStatus },
  UserStore: { customer },
}) => {
  const [grabbing, setGrabbing] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const closeModal = () => {
    setShowModal(false)
  }

  let domNode = useClickOutside(closeModal)

  const canShow = position => {
    return (
      position === stepperPositionFromStatus ||
      position + 1 === stepperPositionFromStatus ||
      stepperPositionFromStatus === position - 1
    )
  }

  const isActive = position => {
    return position < stepperPositionFromStatus
  }

  const isCurrent = position => {
    return position === stepperPositionFromStatus
  }

  const onClick = position => {
    if (!isCurrent(position)) return

    setShowModal(true)
  }

  const lastStep = position => {
    return position === 6
  }

  const Step = ({ position, label }) => {
    if (!canShow(position)) return null

    return (
      <li
        className={classNames({
          active: isActive(position),
          current: isCurrent(position),
          lastLi: lastStep(position),
        })}
      >
        <div
          className={classNames(
            { active: isActive(position), current: isCurrent(position) },
            'stepBubble',
          )}
          onClick={() => onClick(position)}
        >
          {isActive(position) && <i className="fa fa-check" aria-hidden="true"></i>}
          {isCurrent(position) && <i className="fa fa-info" aria-hidden="true"></i>}
          {!isActive(position) && !isCurrent(position) && position}
        </div>
        {label}
        <span
          className={classNames(
            { active: isActive(position), lastStep: lastStep(position) },
            'stepLine',
          )}
        ></span>
      </li>
    )
  }

  if (loading) return null

  return (
    <Fragment>
      <footer className="stepper-bar">
        <div className="col-12 text-center p-0">
          <div className="px-0 pt-4 pb-0">
            <ul
              className={classNames('stepper d-flex', {
                'justify-content-center': stepperPositionFromStatus === 99,
              })}
            >
              <Step position={0} label="Déclaration" />
              <Step position={1} label="Où" />
              <Step position={2} label="Quoi ?" />
              <Step position={3} label="Qui ?" />
              <Step position={4} label="Estimation" />
              <Step position={5} label="Indemnisation" />
              <Step position={6} label="Terminé" />
              <Step position={99} label="Fin" />
            </ul>
          </div>
        </div>
      </footer>

      <ModalWithBackdrop
        show={showModal}
        size="modal-lg"
        close={closeModal}
        draggable
        setGrabbing={setGrabbing}
      >
        <div
          ref={domNode}
          className={classNames('modal-header', { grabbable: !grabbing, grabbing: grabbing })}
        >
          <h5 className="modal-title">Informations</h5>
          <button type="button" className="close" onClick={closeModal}>
            <span>&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <h5 className="modal-title">Mes informations</h5>
          <div className="row no-gutters pl-4">
            <div className="col-12">
              <span className="font-weight-bold">Nom Prénom : </span>{' '}
              {path(['insuredInformation', 'insured', 'lastName'], claim)}{' '}
              {path(['insuredInformation', 'insured', 'firstName'], claim)}
            </div>
          </div>

          <div className="row no-gutters pl-4 mt-2">
            <div className="col-12">
              <span className="font-weight-bold">Email : </span>{' '}
              {path(['insuredInformation', 'insured', 'email'], claim)} <br />
              <span className="font-weight-bold">Tél: </span>{' '}
              {formatPhone(path(['insuredInformation', 'insured', 'mobilePhone'], claim))}
            </div>
          </div>

          <div className="row no-gutters pl-4 mt-2">
            <div className="col-12">
              <span className="font-weight-bold">N° de contrat : </span>{' '}
              {path(['contract', 'policyNumber'], claim)} <br />
              <span className="font-weight-bold">Adresse du bien assuré : </span>{' '}
              {concatAddress(path(['claimInformation', 'addressOfLoss'], claim))} <br />
              <span className="font-weight-bold">Référence sinistre :</span>{' '}
              {path(['claimInformation', 'insurerClaimId'], claim)}
            </div>
          </div>

          <h5 className="modal-title mt-4">Mon Assureur</h5>
          <div className="row no-gutters pl-4">
            <div className="col-12">{path(['name'], customer)}</div>
          </div>
          <div className="row no-gutters pl-4">
            <div className="col-12">
              <span className="font-weight-bold">Adresse : </span>{' '}
              {concatAddress(path(['address'], customer))} <br />
              <span className="font-weight-bold">Tél : </span>{' '}
              {formatPhone(path(['phoneNumber'], customer))} <br />
            </div>
          </div>
          <div className="row no-gutters mt-2 pl-4">
            <div className="col-12">
              <span className="font-weight-bold">Votre service sinistre : </span>{' '}
              {formatPhone(path(['phoneNumber'], customer))} <br />
              <span className="font-weight-bold">Votre Assistance : </span>{' '}
              {formatPhone(path(['phoneNumber'], customer))} <br />
            </div>
          </div>
        </div>
      </ModalWithBackdrop>
    </Fragment>
  )
}

export default inject('SelfCareStore', 'UserStore')(observer(Stepper))
