import React from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import './SearchPackage.css'
import SearchPackageCtrl from 'stores/Mission/view/SearchPackageCtrl'
import CardLayout from 'components/CardLayout/CardLayout'
import ListSearch from './ListSearch'
import SelectRoom from './SelectRoom'
import Input from 'components/Forms/Input'
import Button from 'components/Button/Button'
import RadioTabs from './RadioTabs'
import Breadcrumb from 'pages/Mission/pages/Calculation/Breadcrumb/Breadcrumb'
import PackageType from './PackageType'
import Coverage from './Coverage'
import SelectPriceList from './SelectPriceList'
import Loader from 'components/Loader/Loader'

class SearchPackage extends React.Component {
  searchPackages = () => {
    const endpoint = this.props.match.path.split('/').pop()

    SearchPackageCtrl.fetchSearchPackage(
      this.props.match.params.id,
      false,
      endpoint === 'searchOutdoorPackage' ? 'outdoor' : 'regular',
    )
  }
  onChange = e => SearchPackageCtrl.setProperty(e.target.name, e.target.value)
  onBlur = e => SearchPackageCtrl.setProperty(e.target.name, e.target.value.trim())
  onEnterKey = e => {
    const endpoint = this.props.match.path.split('/').pop()

    if (e.keyCode === 13 && SearchPackageCtrl.search.length > 0)
      SearchPackageCtrl.fetchSearchPackage(
        this.props.match.params.id,
        false,
        endpoint === 'searchOutdoorPackage' ? 'outdoor' : 'regular',
      )
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    const {
      t,
      CartStore: { configuration },
    } = this.props
    const { search, packageType } = SearchPackageCtrl

    if (!configuration) return <Loader />
    const { priceListOptions } = configuration

    return (
      <CardLayout className="add-package">
        <div className="search-fields container pl-0 pr-0 mb-3">
          <div className="row no-gutters mb-3 d-flex justify-content-between">
            <div className="col-12">
              <Breadcrumb />
            </div>
          </div>

          {packageType !== 'DAT_LEAK' && (
            <div className="row no-gutters mb-3 align-items-center">
              <div className="col-12">
                <RadioTabs />
              </div>
            </div>
          )}

          {packageType === 'DAT_LEAK' && priceListOptions.length >= 1 && (
            <div className="row no-gutters mb-3 align-items-center">
              <div className="col-12">
                <span className="tab-filter-title">
                  {t('mission.calculation.searchPackage.partner')}
                </span>
                <div className="row no-gutters search-radio-tabs">
                  <SelectPriceList packageType="DAT_LEAK" />
                </div>
              </div>
            </div>
          )}

          <div className="row no-gutters mb-3">
            {packageType !== 'DAT_LEAK' && (
              <div className="col-12 col-md-3 col-lg-3 col-xl-3 mr-3">
                <span className="tab-filter-title">
                  {t('mission.calculation.searchPackage.coverage')}
                </span>
                <Coverage t={t} />
              </div>
            )}

            <div className="col-12 col-md-3 col-lg-3 col-xl-3 mr-3">
              <span className="tab-filter-title">
                {t('mission.calculation.searchPackage.room')}
              </span>
              <SelectRoom />
            </div>

            {packageType !== 'DAT_LEAK' && (
              <div className="col-12 col-md-3 col-lg-3 col-xl-3">
                <span className="tab-filter-title">
                  {t('mission.calculation.searchPackage.packageType')}
                </span>
                <PackageType />
              </div>
            )}
          </div>

          <div className="row no-gutters">
            <div className="col-12">
              <div className="input-group" onKeyDown={this.onEnterKey}>
                <Input
                  className="form-control"
                  name="search"
                  value={search}
                  placeholder={t('mission.calculation.searchPackage.search')}
                  onChange={this.onChange}
                  autoComplete="off"
                />
                <div className="input-group-append">
                  <Button
                    className="btn btn-primary"
                    disabled={search.length === 0}
                    onClick={this.searchPackages}
                  >
                    <i className="fa fa-search" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ListSearch />
      </CardLayout>
    )
  }
}

export default compose(
  inject('CartStore'),
  withRouter,
  withTranslation(),
  observer,
)(SearchPackage)
