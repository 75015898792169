import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import './Forms.css'

const noop = value => value

class PhoneNumber extends React.Component {
  render() {
    const {
      autoComplete,
      className,
      disabled,
      name,
      onBlur,
      onChange,
      onFocus,
      errors,
      placeholder,
      readOnly,
      required,
      value,
      country,
    } = this.props
    return [
      <PhoneInput
        key={`phoneNumber-${name}`}
        autoComplete={autoComplete}
        className={classNames(
          className,
          { error: errors && !!errors.length, required },
          { 'bg-required': required && !value },
        )}
        disabled={disabled}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        placeholder={placeholder}
        readOnly={readOnly}
        required={required}
        value={value || ''}
        defaultCountry={country}
        displayInitialValueAsLocalNumber={false}
        maxLength={15}
      />,
      errors && !!errors.length && (
        <div key={'error-block'} className="row">
          <div className="error-message col-sm-12">
            {errors.map(errorDescription => (
              <div key={`${errorDescription}`}>{errorDescription}</div>
            ))}
          </div>
        </div>
      ),
    ]
  }
}

PhoneNumber.defaultProps = {
  name: 'phoneNumber',
  onChange: noop,
  errors: [],
}

PhoneNumber.propTypes = {
  autoComplete: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  errors: PropTypes.array,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
}

export default withTranslation()(PhoneNumber)
