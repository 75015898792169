import React from 'react'
import { observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'

import DashboardCtrl from 'stores/Common/view/DashboardCtrl'
import DashboardFilterStore from 'stores/Common/domain/DashboardFilterStore'
import ToggleView from './ToggleView'
import CreateClaimButton from './CreateClaimButton'
import CreateClaimModal from 'pages/Dashboard/components/CreateClaimModal'
import CreateManagerClaimModal from 'pages/Dashboard/components/CreateManagerClaimModal'
import CreateManagerClaimSelfCareModal from 'pages/Dashboard/components/CreateManagerClaimSelfCareModal'
import { truncate } from 'utils'

class DashboardHeader extends React.Component {
  render() {
    const { t } = this.props
    const { missions } = DashboardCtrl
    const { currentFilter } = DashboardFilterStore

    return (
      <div className="row no-gutters">
        <div className="col-12 col-md-5 d-flex align-items-center">
          <h6 className="nav-divider-title contrasted-dark-label mr-2">
            {t('dashboard.list.title')}
          </h6>

          <ToggleView />
        </div>

        <div className="col-12 col-md-7 d-flex align-items-md-center justify-content-md-end flex-md-row flex-column align-items-left">
          <CreateClaimButton />
          <h6 className="nav-divider-title contrasted-dark-label fs16 text-left text-md-right ml-md-2">
            {truncate(`${currentFilter.name} : ${missions.length}`, 45)}
          </h6>
        </div>

        <CreateClaimModal />
        <CreateManagerClaimModal />
        <CreateManagerClaimSelfCareModal />
      </div>
    )
  }
}

export default withTranslation()(observer(DashboardHeader))
