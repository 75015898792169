import React from 'react'
import { inject, observer } from 'mobx-react'
import { isNil } from 'ramda'

import InputArea from 'components/InputArea/InputArea'
import InputNumber from 'components/InputNumber/InputNumber'

const CoveringUnit = ({
  PropertyEmbellishmentCtrl: {
    area,
    quantity,
    currentRoom,
    selectedPart,
    setProperty,
    changePackagesProperty,
    changeArea,
    unitByDefault,
  },
}) => {
  if (selectedPart === '') return null

  if (selectedPart === 'C' || selectedPart === 'W' || selectedPart === 'F') {
    return (
      <div className="row no-gutters">
        <div className="col-6 covering-unit-area form-group">
          <label className="d-flex align-items-center">Surface de la pièce</label>

          <div className="d-flex ">
            <InputArea
              name="area"
              className="form-control d-inline-block"
              value={isNil(area) ? currentRoom.areaSize : area}
              onChange={e => changeArea(e.target.value)}
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="row no-gutters">
      <div className="col-6 covering-unit-area form-group">
        {unitByDefault === 'M2' && <label>Surface</label>}

        {unitByDefault !== 'M2' && <label>Quantité</label>}

        {unitByDefault.length > 0 ? (
          <div className="input-group">
            <InputNumber
              name="quantity"
              className="form-control text-right"
              value={quantity}
              onChange={e => {
                setProperty(
                  e.target.name,
                  unitByDefault === 'M2' || unitByDefault === 'ML'
                    ? e.target.value
                    : parseInt(e.target.value),
                )
                changePackagesProperty(
                  'quantity',
                  unitByDefault === 'M2' || unitByDefault === 'ML'
                    ? e.target.value
                    : parseInt(e.target.value),
                )
              }}
            />
            <div className="input-group-append">
              <span className="input-group-text">{unitByDefault}</span>
            </div>
          </div>
        ) : (
          <InputNumber
            name="quantity"
            className="form-control text-right"
            value={quantity}
            onChange={e => {
              setProperty(e.target.name, parseInt(e.target.value))
              changePackagesProperty('quantity', parseInt(e.target.value))
            }}
          />
        )}
      </div>
    </div>
  )
}

export default inject('PropertyEmbellishmentCtrl')(observer(CoveringUnit))
