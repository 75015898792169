import React from 'react'
import { ReactSVG } from 'react-svg'
import classNames from 'classnames'
import { isNil } from 'ramda'

const _importImages = r => {
  const images = {}
  r.keys().forEach(item => {
    images[item.replace('./', '')] = r(item)
  })
  return images
}

export default ({ imgId, selected }) => {
  const images = _importImages(require.context(`assets/events/`, false, /.svg$/))

  if (isNil(images[`${imgId}.svg`])) {
    console.warn(`Missing svg for ${imgId}`)
    return (
      <ReactSVG
        className={classNames('img-img mb-2', {
          selected,
        })}
        src={images['autres.svg']}
      />
    )
  }

  return (
    <ReactSVG
      className={classNames('img-img mb-2', {
        selected,
      })}
      src={images[`${imgId}.svg`]}
    />
  )
}
