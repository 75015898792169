import React, { Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import { inject, observer } from 'mobx-react'
import { toLower } from 'ramda'
import classNames from 'classnames'

import './UploadDocumentModal.css'
import UploadCtrl from 'stores/Common/view/UploadCtrl'
import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'
import SupportingDocumentsCtrl from 'stores/Common/view/SupportingDocumentsCtrl'
import { Line } from 'rc-progress'
import Label from 'components/Forms/Label'
import Input from 'components/Forms/Input'
import Select from 'components/Forms/Select'
import Button from 'components/Button/Button'
import ItemIcon from 'components/FallBackThumbnail/ItemIcon'
import ImageFallback from 'components/ImageFallback'

class UploadDocumentModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      grabbing: false,
    }
  }

  setModalDisplay = show => UploadCtrl.setModalDisplay(show)
  uploadSD = wan => UploadCtrl.createSupportingDocuments(wan)
  setProperty = (index, e) => UploadCtrl.acceptedFiles[index].setProperty('name', e.target.value)
  setType = (index, e) => UploadCtrl.acceptedFiles[index].setType(e.target.value)
  setGrabbing = value => this.setState({ grabbing: value })

  renderUploadForm = () => {
    const { t, CommonStore } = this.props

    return (
      <table className="tableUpload">
        <thead>
          <tr>
            <th className="head">{t('mission.upload.preview')}</th>
            <th className="head">{t('mission.upload.fileName')}</th>
            <th className="head">
              {t('mission.upload.categoryType', {
                category: t(SupportingDocumentsCtrl.sdCategoryLabelLowerCase),
              })}
            </th>
          </tr>
        </thead>
        <tbody>
          {UploadCtrl.acceptedFiles.map((acceptedFile, index) => {
            return (
              <Fragment>
                <tr>
                  <td className="mainSeparator" colSpan="3"></td>
                </tr>
                <Fragment>
                  <tr key={index}>
                    <td className="preview">
                      {/image/.test(acceptedFile.file.type) ? (
                        <ImageFallback
                          src={acceptedFile.file.preview}
                          alt={`preview-${acceptedFile.name}`}
                        />
                      ) : (
                        <div className="no-preview">
                          <ItemIcon mimeType={acceptedFile.file.type} />
                        </div>
                      )}
                      <Line
                        percent={UploadCtrl.acceptedFiles[index]['progressBar']}
                        strokeWidth={4}
                        strokeColor={'#f27fe20'}
                        trailWidth={8}
                        className="progress-bar-inner"
                      />
                    </td>
                    <td className="normal">
                      <Input
                        name={`fileNameUpload-${index}`}
                        className="form-control"
                        type="text"
                        maxLength={128}
                        value={UploadCtrl.acceptedFiles[index]['name']}
                        onChange={this.setProperty.bind(this, index)}
                      />
                    </td>
                    <td className="normal">
                      <Select
                        name="categoryTypeUpload"
                        className="form-control"
                        options={
                          CommonStore['supportingDocumentsTypes'][
                            SupportingDocumentsCtrl.sdCategory
                          ]
                        }
                        value={UploadCtrl.acceptedFiles[index]['type']['key']}
                        onChange={this.setType.bind(this, index)}
                      />
                    </td>
                  </tr>
                </Fragment>
              </Fragment>
            )
          })}
        </tbody>
      </table>
    )
  }

  renderUploadFormMobile = () => {
    const { t, CommonStore } = this.props

    return UploadCtrl.acceptedFiles.map((acceptedFile, index) => {
      return [
        <div key={`preview-${index}`} className="row preview-mobile">
          {/image/.test(acceptedFile.file.type) ? (
            <img
              className="img-fluid"
              src={acceptedFile.file.preview}
              alt={`preview-${acceptedFile.name}`}
            />
          ) : (
            <div className="no-preview">
              <i className="fa fa-file" />
            </div>
          )}
        </div>,
        <div key={`progressBar-${index}`} className="row progress-bar-mobile">
          <Line
            percent={UploadCtrl.acceptedFiles[index]['progressBar']}
            strokeWidth={4}
            strokeColor={'#f27e20'}
            trailWidth={8}
            className="progress-bar-inner-mobile"
          />
        </div>,
        <div key={`fileName-${index}`} className="row filename-mobile">
          <Label label={t('mission.upload.fileName')} className={'upload-label-mobile'} />
          <Input
            name={`fileNameUpload-${index}`}
            className="form-control"
            value={UploadCtrl.acceptedFiles[index]['name']}
            onChange={this.setProperty.bind(this, index)}
          />
        </div>,
        <div key={`type-${index}`} className="row type-mobile">
          <Label
            label={t('mission.upload.categoryType', {
              category: t(SupportingDocumentsCtrl.sdCategoryLabelLowerCase),
            })}
            className={'upload-label-mobile'}
          />
          <Select
            name="categoryTypeUpload"
            className="form-control"
            options={CommonStore['supportingDocumentsTypes'][SupportingDocumentsCtrl.sdCategory]}
            value={UploadCtrl.acceptedFiles[index]['type']['key']}
            onChange={this.setType.bind(this, index)}
          />
        </div>,
      ]
    })
  }

  render() {
    const {
      t,
      wan,
      UiCtrl: { isMobile },
    } = this.props

    return (
      <ModalWithBackdrop
        show={UploadCtrl.modalDisplay}
        close={value => value}
        size="modal-lg"
        className="upload-document-modal"
        modalContentClassName="px-3 px-md-0"
        draggable
        scrollable
        setGrabbing={this.setGrabbing}
      >
        <div
          className={classNames('modal-header d-block', {
            grabbable: !this.state.grabbing,
            grabbing: this.state.grabbing,
          })}
        >
          <div
            className={classNames('d-flex justify-content-between', {
              grabbable: !this.state.grabbing,
              grabbing: this.state.grabbing,
            })}
          >
            <h5 className="modal-title">
              {UploadCtrl.context !== 'supportingDocument'
                ? t('mission.upload.modalPackageTitle')
                : t('mission.upload.modalTitle', {
                    document: toLower(t(SupportingDocumentsCtrl.sdCategoryLabel)),
                  })}
            </h5>
            {!UploadCtrl.loading && (
              <button
                type="button"
                className="close"
                onClick={this.setModalDisplay.bind(this, false)}
              >
                <span>&times;</span>
              </button>
            )}
          </div>
        </div>

        <div className="modal-body">
          {!!UploadCtrl.acceptedFiles.length && !isMobile && this.renderUploadForm()}
          {!!UploadCtrl.acceptedFiles.length && isMobile && this.renderUploadFormMobile()}
        </div>

        <div className="modal-footer pb-2 pt-2">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={this.setModalDisplay.bind(this, false)}
            disabled={UploadCtrl.loading}
          >
            {t('common.close')}
          </button>
          <Button
            className="btn btn-primary"
            onClick={this.uploadSD.bind(this, wan)}
            loading={UploadCtrl.loading}
          >
            {t('mission.upload.add')}
          </Button>
        </div>
      </ModalWithBackdrop>
    )
  }
}

export default inject('UiCtrl', 'CommonStore')(withTranslation()(observer(UploadDocumentModal)))
