import React, { Fragment, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { useHistory, useParams } from 'react-router-dom'

import SelfCareWhere from 'pages/SelfCare/SelfCareWhere'
import SelfCareWhat from 'pages/SelfCare/SelfCareWhat'
import SelfCareWho from 'pages/SelfCare/SelfCareWho'
import SelfCareEstimation from 'pages/SelfCare/SelfCareEstimation'
import SelfCareIndemnisation from 'pages/SelfCare/SelfCareIndemnisation'
import SelfCareFinish from 'pages/SelfCare/SelfCareFinish'
import SelfCareWaitAndAbandon from 'pages/SelfCare/SelfCareWaitAndAbandon'
import Stepper from 'pages/SelfCare/Stepper'
import Loader from 'components/Loader/Loader'

import './selfcare.css'

const SelfCare = ({
  SelfCareStore: { loadData, loading, selfCareStatus },
  UserStore: { selfCareWan },
}) => {
  const { wan } = useParams()
  let history = useHistory()

  useEffect(() => {
    loadData(wan)
  }, [loadData, wan])

  useEffect(() => {
    if (wan !== selfCareWan) {
      history.push(`/sc`)
    }
  }, [wan, selfCareWan, history])

  if (loading) return <Loader />

  return (
    <Fragment>
      <div className="self-care-body" id="self-care-body">
        {selfCareStatus === 'where' && <SelfCareWhere />}
        {selfCareStatus === 'what' && <SelfCareWhat />}
        {selfCareStatus === 'who' && <SelfCareWho />}
        {selfCareStatus === 'estimation' && <SelfCareEstimation />}
        {(selfCareStatus === 'indemnisation' || selfCareStatus === 'select_indemnisation') && (
          <SelfCareIndemnisation />
        )}
        {(selfCareStatus === 'finish' || selfCareStatus === 'abandon') && <SelfCareFinish />}
        <SelfCareWaitAndAbandon />
      </div>
      <Stepper />
    </Fragment>
  )
}

export default inject('SelfCareStore', 'UserStore')(observer(SelfCare))
