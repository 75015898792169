import { observable, action, decorate, runInAction, computed } from 'mobx'
import { getOrientation, getOrientationForm, saveOrientation } from 'services/claimManagerForm'
import AlertCtrl from 'stores/Common/view/AlertCtrl'
import Form from 'utils/dynform/Form'
import ManagerClaimStore from '../domain/ManagerClaimStore'
import MessagingStore from 'stores/Messaging/MessagingStore'
class OrientationCtrl {
  amountGag = 0
  amountRen = 0
  amountExpert = 0
  amountRecourses = 0
  gagFloor = 0
  gagCeiling = 0
  renFloor = 0
  renCeiling = 0
  teleExpertiseFloor = 0
  teleExpertiseCeiling = 0
  expertiseFloor = 0
  expertiseCeiling = 0
  amountMixGagPecuniary = 0
  mixRenGagAvailable = 0
  amountMixRenGagForRen = 0
  amountMixRenGagForGag = 0
  amountMixRenIpForRen = 0
  amountMixRenIpForIp = 0
  amountAspQuote = 0
  amountAspInvoice = 0
  amountAspEstimation = 0
  gagAvailable = false
  renAvailable = false
  teleExpertiseAvailable = false
  expertiseAvailable = false
  insuredWillBeContactedLater = false
  pecuniaryAvailable = false
  payInsuredAvailable = false
  mixGagPecuniaryAvailable = false
  mixRenGagAvailable = false
  mixRenIpAvailable = false
  aspQuoteAvailable = false
  aspInvoiceAvailable = false
  showModal = false
  choice = null
  loading = false
  loadingForm = false
  form = null

  goToReportPage = false

  loadData = async wan => {
    this.loading = true
    try {
      const res = await getOrientation(wan)
      runInAction(() => {
        this.gagFloor = res.gagFloor
        this.gagCeiling = res.gagCeiling
        this.renFloor = res.renFloor
        this.renCeiling = res.renCeiling
        this.teleExpertiseFloor = res.teleExpertiseFloor
        this.teleExpertiseCeiling = res.teleExpertiseCeiling
        this.expertiseFloor = res.expertiseFloor
        this.expertiseCeiling = res.expertiseCeiling
        this.gagAvailable = res.gagAvailable
        this.renAvailable = res.renAvailable
        this.teleExpertiseAvailable = res.teleExpertiseAvailable
        this.expertiseAvailable = res.expertiseAvailable
        this.mixGagPecuniaryAvailable = res.mixGagPecuniaryAvailable
        this.mixRenGagAvailable = res.mixRenGagAvailable
        this.mixRenIpAvailable = res.mixRenIpAvailable
        this.insuredWillBeContactedLater = res.insuredWillBeContactedLater
        this.pecuniaryAvailable = res.pecuniaryAvailable
        this.payInsuredAvailable = res.payInsuredAvailable
        this.aspQuoteAvailable = res.aspQuoteAvailable
        this.aspInvoiceAvailable = res.aspInvoiceAvailable
        this.amountGag = res.amountGag
        this.amountRen = res.amountRen
        this.amountExpert = res.amountExpert
        this.amountRecourses = res.amountRecourses
        this.amountMixGagPecuniary = res.amountMixGagPecuniary
        this.amountMixRenGagForRen = res.amountMixRenGagForRen
        this.amountMixRenGagForGag = res.amountMixRenGagForGag
        this.amountMixRenIpForRen = res.amountMixRenIpForRen
        this.amountMixRenIpForIp = res.amountMixRenIpForIp
        this.amountAspQuote = res.amountAspQuote
        this.amountAspInvoice = res.amountAspInvoice
        this.amountAspEstimation = res.amountAspEstimation

        this.loading = false
      })
    } catch (error) {
      runInAction(() => {
        this.loading = false
      })
      console.warn(error)
      AlertCtrl.alert('danger', 'managerClaim.orientation.failure')
    }
  }
  get defaultAmount() {
    let amount = null
    let amountSecondInCaseMix = null
    let amountAspQuote = null
    let amountAspInvoice = null
    let amountAspEstimation = null
    switch (this.choice) {
      case 'gag':
        amount = this.amountGag
        break
      case 'ren':
        amount = this.amountRen
        break
      case 'teleExpert':
      case 'expert':
        amount = this.amountRen
        break
      case 'mix_gag_pecuniary':
        amount = this.amountMixGagPecuniary
        break
      case 'mix_ren_gag':
        amount = this.amountMixRenGagForRen
        amountSecondInCaseMix = this.amountMixRenGagForGag
        break
      case 'mix_ren_ip':
        amount = this.amountMixRenIpForRen
        amountSecondInCaseMix = this.amountMixRenIpForIp
        break
      case 'aspQuote':
        amountAspQuote = this.amountAspQuote
        amountAspEstimation = this.amountAspEstimation
        break
      case 'aspInvoice':
        amountAspInvoice = this.amountAspInvoice
        amountAspEstimation = this.amountAspEstimation
        break
      default:
        amount = null
    }

    return {
      orientation: {
        amount,
        amountSecondInCaseMix,
        amountAspQuote,
        amountAspInvoice,
        amountAspEstimation,
      },
    }
  }

  loadOrientationForm = async (wan, data = null) => {
    this.loadingForm = true
    data = data || this.defaultAmount
    if (this.choice !== null) {
      const form = await getOrientationForm(wan, this.choice)
      form.name = this.choice
      runInAction(() => {
        this.form = new Form({ form, data })
      })
    }
    runInAction(() => {
      this.loadingForm = false
    })
  }

  setProperty = (key, value) => {
    this[key] = value
  }

  clearForm = () => {
    this.form = null
  }

  save = async (wan, formData) => {
    const claim = await saveOrientation(wan, formData, this.choice)
    ManagerClaimStore.setProperty('claim', claim)

    if (this.choice === 'expert') {
      MessagingStore.loadData(wan, null, true)
    }

    runInAction(() => {
      this.showModal = false
      this.goToReportPage = true
    })
    if (this.choice === 'gag') {
      ManagerClaimStore.reloadCartStore()
    }
  }
}

const DecoratedOrientation = decorate(OrientationCtrl, {
  amountGag: observable,
  amountRen: observable,
  amountExpert: observable,
  amountRecourses: observable,
  amountMixGagPecuniary: observable,
  amountMixRenGagForRen: observable,
  amountMixRenGagForGag: observable,
  amountMixRenIpForRen: observable,
  amountMixRenIpForIp: observable,
  amountAspQuote: observable,
  amountAspInvoice: observable,
  amountAspEstimation: observable,
  gagFloor: observable,
  gagCeiling: observable,
  renFloor: observable,
  renCeiling: observable,
  teleExpertiseFloor: observable,
  teleExpertiseCeiling: observable,
  expertiseFloor: observable,
  expertiseCeiling: observable,
  gagAvailable: observable,
  mixGagPecuniaryAvailable: observable,
  mixRenGagAvailable: observable,
  mixRenIpAvailable: observable,
  renAvailable: observable,
  teleExpertiseAvailable: observable,
  expertiseAvailable: observable,
  insuredWillBeContactedLater: observable,
  pecuniaryAvailable: observable,
  payInsuredAvailable: observable,
  aspQuoteAvailable: observable,
  aspInvoiceAvailable: observable,
  choice: observable,
  form: observable,
  loading: observable,
  loadingForm: observable,
  showModal: observable,
  goToReportPage: observable,

  defaultAmount: computed,

  loadData: action,
  loadOrientationForm: action,
  setProperty: action,
  clearForm: action,
  save: action,
})

export default new DecoratedOrientation()
