import React from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'

import PropertyEmbellishmentCtrl from 'stores/Mission/EasyEstimation/PropertyEmbellishmentCtrl'
import InputNumber from 'components/InputNumber/InputNumber'

const PercentageDammaged = () => {
  const {
    selectedPart,
    percentageDammaged,
    setProperty,
    computeAreaForPackages,
  } = PropertyEmbellishmentCtrl
  const walls = [
    { label: 1, value: 25 },
    { label: 2, value: 50 },
    { label: 3, value: 75 },
    { label: 4, value: 100 },
  ]

  if (selectedPart === 'C' || selectedPart === 'F')
    return (
      <div>
        <label className="d-block">% de la surface endommagée</label>

        <InputNumber
          name="percentageDammaged"
          className="form-control text-right"
          onChange={e => {
            setProperty('percentageDammaged', e.target.value)
            computeAreaForPackages()
          }}
          value={percentageDammaged}
          maxValue={100}
          minValue={1}
        />
      </div>
    )
  else if (selectedPart === 'W')
    return (
      <div>
        <label className="d-block">Nombre de murs endommagés</label>

        <div className="btn-group d-flex">
          {walls.map(wall => (
            <button
              key={`wall-${wall.label}`}
              className={classNames('btn', {
                'btn-primary': percentageDammaged === wall.value,
                'btn-outline-primary': percentageDammaged !== wall.value,
              })}
              onClick={() => {
                setProperty('percentageDammaged', wall.value)
                computeAreaForPackages()
              }}
            >
              {wall.label}
            </button>
          ))}
        </div>
      </div>
    )

  return null
}

export default observer(PercentageDammaged)
