import React, { useState, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { path, isNil } from 'ramda'

import Select from 'components/ResponsiveSelect/ResponsiveSelect'
import Label from 'components/Label/Label'
import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'
import Button from 'components/Button/Button'
import TextArea from 'components/Forms/TextArea'
import Loader from 'components/Loader/Loader'

const ReportModal = ({
  showModal,
  setShowModal,
  sendDarvaWithReason,
  ReportCtrl: { loading, sendReportRectifPending, currentReportObj },
}) => {
  const { t } = useTranslation()
  const [reason, setReason] = useState('')
  const [action, setAction] = useState('')

  if (isNil(currentReportObj) || !currentReportObj.modal) return null
  const onClose = () => setShowModal(false)

  const reportActionsForSelect = currentReportObj.reportActions.map(action => ({
    value: action.reportType,
    label: action.buttonText,
  }))

  const currentReportAction = currentReportObj.reportActions.find(
    reportAction => reportAction.reportType === action,
  )
  const textAreaLabel = path(['textAreaLabel'], currentReportAction) || t('mission.report.reason')

  return (
    <Fragment>
      <ModalWithBackdrop size="modal-md" show={showModal} close={onClose}>
        <div className="modal-header">
          <h5 className="modal-title">{t('mission.report.header')}</h5>
          <button type="button" className="close" onClick={onClose}>
            <span>&times;</span>
          </button>
        </div>

        <div className="modal-body">
          {sendReportRectifPending ? (
            <Loader loadingMsg={t('mission.report.loadingSent')} />
          ) : (
            <div className="d-flex flex-column justify-content-around form-group">
              {reportActionsForSelect.length > 1 && (
                <div className="form-group row">
                  <Label htmlFor="action" className="col-6 col-form-label" required>
                    {t('mission.report.action')}
                  </Label>
                  <div className="col-6">
                    <Select
                      placeholder={t('mission.report.selectAction')}
                      options={reportActionsForSelect}
                      value={action}
                      onChange={e => setAction(e.target.value)}
                    />
                  </div>
                </div>
              )}

              <div className="form-group row">
                <Label htmlFor="reason" className="col-6 col-form-label" required>
                  {textAreaLabel}
                </Label>

                <div className="col-6">
                  <TextArea
                    className="form-control"
                    autoFocus
                    rows={3}
                    id="reason"
                    value={reason}
                    onChange={e => setReason(e.target.value)}
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="modal-footer pb-2 pt-2">
          <Button type="button" className="btn btn-secondary" disabled={loading} onClick={onClose}>
            {t('common.cancel')}
          </Button>
          <Button
            className="btn btn-primary"
            onClick={() =>
              sendDarvaWithReason({
                reason,
                submittableReportType: action,
              })
            }
            loading={loading}
            disabled={!reason || (reportActionsForSelect.length > 1 && !action) || loading}
          >
            {t('common.validate')}
          </Button>
        </div>
      </ModalWithBackdrop>
    </Fragment>
  )
}

export default inject('ReportCtrl', 'MissionStore')(observer(ReportModal))
