import React from 'react'
import { roundDecimal } from 'utils/currency'
import Tooltip from 'components/Tooltip/Tooltip'
import { getWall, computeWallArea } from 'utils/easyEstimation'

const toolTipTextForWall = (percentageDammaged, area) => {
  const numberWall = getWall(percentageDammaged)
  const finalArea = computeWallArea(percentageDammaged, area)

  return numberWall > 1
    ? `${numberWall} murs - ${roundDecimal(finalArea)} m2`
    : `${numberWall} mur - ${roundDecimal(finalArea)} m2`
}

export default ({ part, unitByDefault, area, quantity, percentageDammaged, label }) => {
  if (part === 'C' || part === 'F') {
    return (
      <span>
        Pièce de {roundDecimal(area)} m<sup>2</sup> - {percentageDammaged}% endommagé - {label}
      </span>
    )
  } else if (part === 'W') {
    return (
      <Tooltip text={toolTipTextForWall(percentageDammaged, area)} placement="top">
        <span style={{ cursor: 'help' }}>
          Pièce de {roundDecimal(area)} m<sup>2</sup> - {percentageDammaged}% endommagé - {label}
        </span>
      </Tooltip>
    )
  } else if (unitByDefault.length > 0) {
    return (
      <span>
        {quantity} {unitByDefault} - {percentageDammaged}% endommagé - {label}
      </span>
    )
  }

  return <span>{quantity} unité</span>
}
