import React, { Fragment } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'

import FormFields from './FormFields'
import { uid } from 'utils'

const Accordion = ({
  formObj,
  focus,
  formObj: { groups, openCloseGroup, isGroupOpen, isGroupNotEmpty },
}) => {
  return groups.map(({ groupId, groupLabel }) => {
    if (!groupId) {
      return <FormFields key={uid(7)} groupId={null} formObj={formObj} focus={focus} />
    }

    return (
      <Fragment key={groupId || 0}>
        <div
          className="border-bottom border-primary my-3 pb-2 clickable"
          onClick={() => openCloseGroup(groupId)}
        >
          {isGroupNotEmpty(groupId) && (
            <span className="text-primary pr-2">
              <i className={`fa fa-angle-${isGroupOpen(groupId) ? 'up' : 'down'}`}></i>
            </span>
          )}

          <strong className={!isGroupNotEmpty(groupId) ? 'text-secondary' : ''}>
            {groupLabel}
          </strong>
        </div>

        <div className={classNames({ 'd-none': !isGroupOpen(groupId) })}>
          <FormFields {...{ groupId, formObj, focus }} />
        </div>
      </Fragment>
    )
  })
}

export default observer(Accordion)
