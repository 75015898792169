import request from 'services/httpRequest'

export const fetchPartyInvolvedRoles = roleType => {
  return request({
    method: 'get',
    url: '/thirdPartiesRoles/v1/',
    params: {
      'filter[type]': roleType,
    },
  })
    .then(res => {
      const roles = res.data.map(role => ({
        key: role.attributes.key,
        name: role.attributes.name,
        type: role.attributes.type,
      }))
      return roles
    })
    .catch(err => {
      if (err) return Promise.reject(err.data)
    })
}

export const fetchTypeOfVictims = roleType => {
  return request({
    method: 'get',
    url: '/businessData/v1/typeOfVictim',
  })
    .then(res => {
      const roles = res.data.map(role => ({
        key: role.attributes.key,
        name: role.attributes.value,
      }))
      return roles
    })
    .catch(err => {
      if (err) return Promise.reject(err.data)
    })
}

export const fetchInvolvedPartyResponsabilities = async () => {
  try {
    const res = await request({
      method: 'get',
      url: '/involvedPartyResponsabilities/v1/',
    })

    const responsabilities = res.data.map(responsability => ({
      key: responsability.attributes.key,
      name: responsability.attributes.name,
    }))
    return responsabilities
  } catch (err) {
    if (err) return Promise.reject(err.data)
  }
}

export const savePartyInvolved = async ({ url, method, partyInvolvedData }) => {
  try {
    const data = {
      data: {
        type: 'involved_party',
        attributes: partyInvolvedData,
      },
    }
    const res = await request({
      method,
      url,
      data,
    })
    return res
  } catch (err) {
    if (err.status === 422) {
      const errorsFields = err.data.errors.map(error => ({
        key: error.source.pointer.split('/').pop(),
        value: error.detail,
      }))
      return Promise.reject({ status: 422, errors: errorsFields })
    }
    return Promise.reject(err)
  }
}

export const deletePartyInvolved = async url => {
  try {
    const res = await request({
      method: 'delete',
      url,
    })
    return res
  } catch (err) {
    return Promise.reject(err)
  }
}

// export const saveAttendance = (wan, partyAttendance) => {
//   const url = `/claimFiles/v1/claimFileAssessor/${wan}/involvedParties/attendance`
//   const data = {
//     data: {
//       type: 'involved_parties',
//       attributes: {
//         ids: partyAttendance,
//       },
//     },
//   }

//   return request({
//     method: 'post',
//     url,
//     data,
//   })
//     .then(res => {
//       return res
//     })
//     .catch(err => {
//       if (err) return Promise.reject(err)
//     })
// }
