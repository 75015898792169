import { observable, action, decorate, runInAction, computed, toJS } from 'mobx'
import { add, path, subtract, sum, isNil, equals, mergeDeepRight } from 'ramda'

import { fetchPv, savePv } from 'services/missionForm'
import Form from 'utils/dynform/Form'
import { generateReport } from 'services/report'
import AlertCtrl from 'stores/Common/view/AlertCtrl'
import ReportCtrl from 'stores/Mission/view/ReportCtrl'
import MissionStore from 'stores/Mission/domain/MissionStore'
import SupportingDocumentsCtrl from 'stores/Common/view/SupportingDocumentsCtrl'
import SDExpertCtrl from 'stores/Common/view/SDExpertCtrl'
import { saveReportToSD } from 'services/report'
import CartStore from '../domain/CartStore'
import PrejudiceStore from '../domain/PrejudiceStore'
import { roundDecimal } from 'utils/currency'
import { verifyValue } from 'utils'

class PvCtrl {
  loading = false
  form = null
  loaded = false
  reportContent = ''
  numPages = 0
  savingReport = false
  originalEstimation = null
  estimation = {
    property: {
      van: null,
      vanOverride: null,
      vanWithVAT: null,
      obsRate: null,
      obsRateOverride: null,
      obs: null,
      obsOverride: null,
      withOBWithoutVAT: null,
      withOBWithoutVATOverride: null,
      withOBWithVAT: null,
      withOBWithVATOverride: null,
    },
    embellishment: {
      van: null,
      vanOverride: null,
      vanWithVAT: null,
      obsRate: null,
      obsRateOverride: null,
      obs: null,
      obsOverride: null,
      withOBWithoutVAT: null,
      withOBWithoutVATOverride: null,
      withOBWithVAT: null,
      withOBWithVATOverride: null,
    },
    leak: {
      van: null,
      vanOverride: null,
      vanWithVAT: null,
      obsRate: null,
      obsRateOverride: null,
      obs: null,
      obsOverride: null,
      withOBWithoutVAT: null,
      withOBWithoutVATOverride: null,
      withOBWithVAT: null,
      withOBWithVATOverride: null,
    },
    relatedCost: {
      van: null,
      vanOverride: null,
      vanWithVAT: null,
      obsRate: null,
      obsRateOverride: null,
      obs: null,
      obsOverride: null,
      withOBWithoutVAT: null,
      withOBWithoutVATOverride: null,
      withOBWithVAT: null,
      withOBWithVATOverride: null,
    },
    otherDamage: {
      van: null,
      vanOverride: null,
      vanWithVAT: null,
      obsRate: null,
      obsRateOverride: null,
      obs: null,
      obsOverride: null,
      withOBWithoutVAT: null,
      withOBWithoutVATOverride: null,
      withOBWithVAT: null,
      withOBWithVATOverride: null,
    },
    furniture: {
      van: null,
      vanOverride: null,
      vanWithVAT: null,
      obsRate: null,
      obsRateOverride: null,
      obs: null,
      obsOverride: null,
      withOBWithoutVAT: null,
      withOBWithoutVATOverride: null,
      withOBWithVAT: null,
      withOBWithVATOverride: null,
    },
    totals: {
      vanWithVATOverride: null,
    },
  }

  get estimationChanged() {
    if (isNil(this.originalEstimation)) return false

    return !equals(
      toJS(this.estimation, { recurseEverything: true }),
      toJS(this.originalEstimation, { recurseEverything: true }),
    )
  }

  get estimationAsjson() {
    const estimation = {
      ...this.estimation,
    }
    estimation.property.van = this.vans.property
    estimation.property.vanWithVAT = this.vansWithWat.property
    estimation.property.obsRate = this.obsRates.property
    estimation.property.obs = this.obs.property
    estimation.property.withOBWithoutVAT = this.withOBWithoutVAT.property
    estimation.property.withOBWithVAT = this.withOBWithVAT.property

    estimation.embellishment.van = this.vans.embellishment
    estimation.embellishment.vanWithVAT = this.vansWithWat.embellishment
    estimation.embellishment.obsRate = this.obsRates.embellishment
    estimation.embellishment.obs = this.obs.embellishment
    estimation.embellishment.withOBWithoutVAT = this.withOBWithoutVAT.embellishment
    estimation.embellishment.withOBWithVAT = this.withOBWithVAT.embellishment

    estimation.leak.van = this.vans.leak
    estimation.leak.vanWithVAT = this.vansWithWat.leak
    estimation.leak.withOBWithoutVAT = this.withOBWithoutVAT.leak
    estimation.leak.withOBWithVAT = this.withOBWithVAT.leak

    estimation.relatedCost.van = this.vans.relatedCost
    estimation.relatedCost.vanWithVAT = this.vansWithWat.relatedCost
    estimation.relatedCost.withOBWithoutVAT = this.withOBWithoutVAT.relatedCost
    estimation.relatedCost.withOBWithVAT = this.withOBWithVAT.relatedCost

    estimation.otherDamage.van = this.vans.otherDamage
    estimation.otherDamage.vanWithVAT = this.vansWithWat.otherDamage
    estimation.otherDamage.obsRate = this.obsRates.otherDamage
    estimation.otherDamage.obs = this.obs.otherDamage
    estimation.otherDamage.withOBWithoutVAT = this.withOBWithoutVAT.otherDamage
    estimation.otherDamage.withOBWithVAT = this.withOBWithVAT.otherDamage

    estimation.furniture.van = this.vans.furniture
    estimation.furniture.vanWithVAT = this.vansWithWat.furniture
    estimation.furniture.obs = this.obs.furniture
    estimation.furniture.withOBWithoutVAT = this.withOBWithoutVAT.furniture
    estimation.furniture.withOBWithVAT = this.withOBWithVAT.furniture
    estimation.totals = { ...estimation.totals, ...this.totals }

    return estimation
  }

  clearEstimationOverride = () => {
    this.estimation.property.vanOverride = null
    this.estimation.property.obsRateOverride = null
    this.estimation.property.obsOverride = null
    this.estimation.property.withOBWithoutVATOverride = null
    this.estimation.property.withOBWithVATOverride = null

    this.estimation.embellishment.vanOverride = null
    this.estimation.embellishment.obsRateOverride = null
    this.estimation.embellishment.obsOverride = null
    this.estimation.embellishment.withOBWithoutVATOverride = null
    this.estimation.embellishment.withOBWithVATOverride = null

    this.estimation.leak.vanOverride = null
    this.estimation.leak.obsRateOverride = null
    this.estimation.leak.obsOverride = null
    this.estimation.leak.withOBWithoutVATOverride = null
    this.estimation.leak.withOBWithVATOverride = null

    this.estimation.relatedCost.vanOverride = null
    this.estimation.relatedCost.obsRateOverride = null
    this.estimation.relatedCost.obsOverride = null
    this.estimation.relatedCost.withOBWithoutVATOverride = null
    this.estimation.relatedCost.withOBWithVATOverride = null

    this.estimation.otherDamage.vanOverride = null
    this.estimation.otherDamage.obsRateOverride = null
    this.estimation.otherDamage.obsOverride = null
    this.estimation.otherDamage.withOBWithoutVATOverride = null
    this.estimation.otherDamage.withOBWithVATOverride = null

    this.estimation.furniture.vanOverride = null
    this.estimation.furniture.obsRateOverride = null
    this.estimation.furniture.obsOverride = null
    this.estimation.furniture.withOBWithoutVATOverride = null
    this.estimation.furniture.withOBWithVATOverride = null

    this.estimation.totals = {
      vanWithVATOverride: null,
    }
    this.updateOriginalEstimation()
  }

  updateOriginalEstimation = () => {
    this.originalEstimation = toJS(this.estimation, { recurseEverything: true })
  }

  resetEstimation = () => {
    if (this.estimationChanged) {
      this.estimation = toJS(this.originalEstimation, { recurseEverything: true })
    }
  }

  setEstimation = (key, value) => {
    const path = key.split('.')
    this.estimation[path[0]][path[1]] = value
  }

  loadData = async (wan, pvData) => {
    this.loading = true
    const pvEstimation = path(['pv', 'pvEstimation'], pvData)
    this.estimation = pvEstimation
      ? mergeDeepRight(toJS(this.estimation, { recurseEverything: true }), pvEstimation)
      : this.estimation

    this.updateOriginalEstimation()

    try {
      const form = await fetchPv(wan)
      form.name = 'pv'
      runInAction(() => {
        this.form = new Form({ form, data: pvData })
      })
    } catch (err) {
      console.error(err)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  save = async (wan, formData) => {
    try {
      let claim = await savePv(wan, formData, this.estimationAsjson)
      const isValid = await ReportCtrl.pvValidator(wan)
      this.updateOriginalEstimation()
      if (isValid === 204) {
        let reportSupportingDocuments = []
        if (claim.reports && claim.reports[0] && claim.reports[0].supportingDocuments) {
          reportSupportingDocuments = Object.values(claim.reports[0].supportingDocuments)
        }

        SDExpertCtrl.loadSDReport(reportSupportingDocuments)
        SupportingDocumentsCtrl.fetchSupportingDocuments(wan)
      }
      this.generateReport(wan)
    } catch (error) {
      console.error(error)
    }
  }

  setReportContent = content => {
    this.reportContent = content
  }

  generateReport = async wan => {
    this.setReportContent('')

    try {
      const res = await generateReport(wan, MissionStore.isMissionClosed, 'PV_EXPERTISE_REPORT')

      runInAction(() => {
        if (res.body) {
          this.setReportContent(res.body)
        } else {
          this.setReportContent(res)
        }
      })
    } catch (error) {
      console.warn(error)
      AlertCtrl.alert('danger', 'mission.report.contentFailure')
    }
  }

  setProperty = (key, value) => {
    this[key] = value
  }

  savePvToSD = async wan => {
    this.savingReport = true

    try {
      await saveReportToSD(wan, 'PV_EXPERTISE_REPORT')
      SupportingDocumentsCtrl.fetchSupportingDocuments(wan)
      AlertCtrl.alert('success', 'mission.report.pvSave')
    } catch (err) {
      AlertCtrl.alert('error', 'mission.report.errorSave')
    } finally {
      runInAction(() => {
        this.savingReport = false
      })
    }
  }

  get vans() {
    const { catalog, selfRepair } = CartStore.totalsFromCart
    return {
      property: add(
        catalog.totalPropertyWithoutObsolescenceWithoutVAT,
        selfRepair.totalPropertyWithoutObsolescenceWithoutVAT,
      ),
      embellishment: add(
        catalog.totalEmbellishmentWithoutObsolescenceWithoutVAT,
        selfRepair.totalEmbellishmentWithoutObsolescenceWithoutVAT,
      ),
      leak: MissionStore.isWaterDamage ? catalog.totalLeakWithoutObsolescenceWithoutVAT : 0,
      relatedCost: CartStore.totalRelatedCostsAnnexPriceWithoutVAT,
      otherDamage: sum([
        catalog.totalOutdoorWithoutObsolescenceWithoutVAT,
        selfRepair.totalOutdoorWithoutObsolescenceWithoutVAT,
        PrejudiceStore.payloads.length > 0 ? PrejudiceStore.vanWithoutVAT : 0,
      ]),
      furniture: catalog.totalFurnitureWithoutObsolescenceWithoutVAT,
    }
  }

  get vansWithWat() {
    const { catalog, selfRepair } = CartStore.totalsFromCart
    return {
      property: add(catalog.totalPropertyWithVAT, selfRepair.totalPropertyWithVAT),
      embellishment: add(catalog.totalEmbellishmentWithVAT, selfRepair.totalEmbellishmentWithVAT),
      leak: MissionStore.isWaterDamage ? catalog.totalLeakWithVAT : 0,
      relatedCost: CartStore.totalRelatedCostsAnnexPriceWithVAT,
      otherDamage: sum([
        catalog.totalOutdoorWithVAT,
        selfRepair.totalOutdoorWithVAT,
        PrejudiceStore.payloads.length > 0 ? PrejudiceStore.vanWithVAT : 0,
      ]),
      furniture: catalog.totalFurnitureWithVAT,
    }
  }

  get obsRates() {
    const {
      propertyPackages,
      embellishmentPackages,
      outdoorPackages,
      outdoorFurnitures,
    } = CartStore

    let propertyObsRates = []
    propertyPackages.forEach(pk => {
      const rate = roundDecimal(pk.correction)
      if (propertyObsRates.indexOf(rate) === -1) {
        propertyObsRates.push(rate)
      }
    })

    let embellishmentObsRates = []
    embellishmentPackages.forEach(pk => {
      const rate = roundDecimal(pk.correction)
      if (embellishmentObsRates.indexOf(rate) === -1) {
        embellishmentObsRates.push(rate)
      }
    })

    let otherDamageObsRates = []
    outdoorPackages.forEach(pk => {
      const rate = roundDecimal(pk.correction)
      if (otherDamageObsRates.indexOf(rate) === -1) {
        otherDamageObsRates.push(rate)
      }
    })
    outdoorFurnitures.forEach(furniture => {
      const rate = roundDecimal(furniture.obsolescence)
      if (otherDamageObsRates.indexOf(rate) === -1) {
        otherDamageObsRates.push(rate)
      }
    })
    PrejudiceStore.payloads.forEach(payload => {
      payload.prejudices.forEach(pk => {
        const rate = roundDecimal(pk.obsolescenceRate)
        if (otherDamageObsRates.indexOf(rate) === -1) {
          otherDamageObsRates.push(rate)
        }
      })
      payload.annexes.forEach(pk => {
        const rate = roundDecimal(pk.obsolescenceRate)
        if (otherDamageObsRates.indexOf(rate) === -1) {
          otherDamageObsRates.push(rate)
        }
      })
    })

    return {
      property: propertyObsRates.length === 1 ? propertyObsRates[0] : null,
      embellishment: embellishmentObsRates.length === 1 ? embellishmentObsRates[0] : null,
      leak: null,
      relatedCost: null,
      furniture: null,
      otherDamage: otherDamageObsRates.length === 1 ? otherDamageObsRates[0] : null,
    }
  }

  get obs() {
    const { catalog, selfRepair } = CartStore.totalsFromCart
    return {
      property: add(
        catalog.totalPropertyObslescenceWithoutVAT,
        selfRepair.totalPropertyObslescenceWithoutVAT,
      ),
      embellishment: add(
        catalog.totalEmbellishmentObslescenceWithoutVAT,
        selfRepair.totalEmbellishmentObslescenceWithoutVAT,
      ),
      leak: null,
      relatedCost: null,
      furniture:
        catalog.totalFurnitureWithoutObsolescenceWithoutVAT -
        catalog.totalFurnitureFinalPriceWithoutVAT,
      otherDamage: sum([
        catalog.totalOutdoorObslescenceWithoutVAT,
        selfRepair.totalOutdoorObslescenceWithoutVAT,
        PrejudiceStore.payloads.length > 0 ? PrejudiceStore.realObsolescence : 0,
      ]),
    }
  }

  get withOBWithoutVAT() {
    const { catalog } = CartStore.totalsFromCart

    return {
      property: subtract(this.vans.property, this.obs.property),
      embellishment: subtract(this.vans.embellishment, this.obs.embellishment),
      leak: MissionStore.isWaterDamage ? catalog.totalLeakFinalPriceWithoutVAT : 0,
      relatedCost: CartStore.totalRelatedCostsAnnexPriceWithoutVAT,
      otherDamage: subtract(this.vans.otherDamage, this.obs.otherDamage),
      furniture: catalog.totalFurnitureFinalPriceWithoutVAT,
    }
  }

  get withOBWithVAT() {
    const { catalog, selfRepair } = CartStore.totalsFromCart
    return {
      property: add(
        subtract(catalog.totalPropertyWithVAT, catalog.totalPropertyObsolescence),
        subtract(selfRepair.totalPropertyWithVAT, selfRepair.totalPropertyObsolescence),
      ),
      embellishment: add(
        subtract(catalog.totalEmbellishmentWithVAT, catalog.totalEmbellishmentObsolescence),
        subtract(selfRepair.totalEmbellishmentWithVAT, selfRepair.totalEmbellishmentObsolescence),
      ),
      leak: MissionStore.isWaterDamage ? catalog.totalLeakFinalPrice : 0,
      relatedCost: CartStore.totalRelatedCostsAnnexPriceWithVAT,
      furniture: catalog.totalFurnitureFinalPrice,
      otherDamage: sum([
        subtract(catalog.totalOutdoorWithVAT, catalog.totalOutdoorObsolescence),
        subtract(selfRepair.totalOutdoorWithVAT, selfRepair.totalOutdoorObsolescence),
        PrejudiceStore.payloads.length > 0 ? PrejudiceStore.obsolescenceDeductedWithVAT : 0,
      ]),
    }
  }

  get totals() {
    return {
      van: sum([
        verifyValue(this.estimation.property.vanOverride, this.vans.property),
        verifyValue(this.estimation.embellishment.vanOverride, this.vans.embellishment),
        verifyValue(this.estimation.leak.vanOverride, this.vans.leak),
        verifyValue(this.estimation.relatedCost.vanOverride, this.vans.relatedCost),
        verifyValue(this.estimation.otherDamage.vanOverride, this.vans.otherDamage),
        verifyValue(this.estimation.furniture.vanOverride, this.vans.furniture),
      ]),
      vanWithWat: sum([
        this.vansWithWat.property,
        this.vansWithWat.embellishment,
        this.vansWithWat.leak,
        this.vansWithWat.relatedCost,
        this.vansWithWat.otherDamage,
        this.vansWithWat.furniture,
      ]),
      obs: sum([
        verifyValue(this.estimation.property.obsOverride, this.obs.property),
        verifyValue(this.estimation.embellishment.obsOverride, this.obs.embellishment),
        verifyValue(this.estimation.otherDamage.obsOverride, this.obs.otherDamage),
        verifyValue(this.estimation.furniture.obsOverride, this.obs.furniture),
      ]),
      withOBWithoutVAT: sum([
        verifyValue(
          this.estimation.property.withOBWithoutVATOverride,
          this.withOBWithoutVAT.property,
        ),
        verifyValue(
          this.estimation.embellishment.withOBWithoutVATOverride,
          this.withOBWithoutVAT.embellishment,
        ),
        verifyValue(this.estimation.leak.withOBWithoutVATOverride, this.withOBWithoutVAT.leak),
        verifyValue(
          this.estimation.relatedCost.withOBWithoutVATOverride,
          this.withOBWithoutVAT.relatedCost,
        ),
        verifyValue(
          this.estimation.otherDamage.withOBWithoutVATOverride,
          this.withOBWithoutVAT.otherDamage,
        ),
        verifyValue(
          this.estimation.furniture.withOBWithoutVATOverride,
          this.withOBWithoutVAT.furniture,
        ),
      ]),
      withOBWithVAT: sum([
        verifyValue(this.estimation.property.withOBWithVATOverride, this.withOBWithVAT.property),
        verifyValue(
          this.estimation.embellishment.withOBWithVATOverride,
          this.withOBWithVAT.embellishment,
        ),
        verifyValue(this.estimation.leak.withOBWithVATOverride, this.withOBWithVAT.leak),
        verifyValue(
          this.estimation.relatedCost.withOBWithVATOverride,
          this.withOBWithVAT.relatedCost,
        ),
        verifyValue(
          this.estimation.otherDamage.withOBWithVATOverride,
          this.withOBWithVAT.otherDamage,
        ),
        verifyValue(this.estimation.furniture.withOBWithVATOverride, this.withOBWithVAT.furniture),
      ]),
    }
  }
}

const DecoratedPv = decorate(PvCtrl, {
  loading: observable,
  form: observable,
  loaded: observable,
  numPages: observable,
  reportContent: observable,
  savingReport: observable,
  originalEstimation: observable,
  estimation: observable,

  vans: computed,
  vansWithWat: computed,
  obsRates: computed,
  obs: computed,
  withOBWithoutVAT: computed,
  withOBWithVAT: computed,
  totals: computed,
  estimationChanged: computed,

  setEstimation: action,
  clearEstimationOverride: action,
  updateOriginalEstimation: action,
  resetEstimation: action,
  loadData: action,
  save: action,
  generateReport: action,
  setProperty: action,
  savePvToSD: action,
})

export default new DecoratedPv()
