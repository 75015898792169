import React from 'react'
import { observer } from 'mobx-react'

import PhoneNumber from 'components/Forms/PhoneNumber'

const PhoneNumberWrapper = ({ inputData }) => {
  return (
    <PhoneNumber
      className="form-control"
      onChange={value => inputData.setProperty('value', value)}
      value={inputData.value}
      placeholder={inputData.placeholder}
      country="FR"
      indicateInvalid={false}
    />
  )
}

export default observer(PhoneNumberWrapper)
