import React from 'react'
import Geosuggest from 'react-geosuggest'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withTranslation } from 'react-i18next'
import { path } from 'ramda'

import './Address.css'
import Label from 'components/Label/Label'
import Input from 'components/Forms/Input'

class Address extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      address: {
        streetNumber: path(['value', 'streetNumber'], this.props) || '',
        addressLine1: path(['value', 'addressLine1'], this.props) || '',
        addressLine2: path(['value', 'addressLine2'], this.props) || '',
        addressLine3: path(['value', 'addressLine3'], this.props) || '',
        zipCode: path(['value', 'zipCode'], this.props) || '',
        city: path(['value', 'city'], this.props) || '',
        country: 'FR',
        long: path(['value', 'geometry', 'long'], this.props) || null,
        lat: path(['value', 'geometry', 'lat'], this.props) || null,
      },
      suggests: [],
      showManual: false,
      errorZipCode: '',
    }
    this.myRef = React.createRef()
  }

  componentWillReceiveProps(next) {
    if (next.errors.length > 0) {
      this.setState({ ...this.state, showManual: true })
    }
  }

  reverseGoogleAddress = suggest => {
    const extractInfoType = type => {
      const info = suggest.gmaps.address_components.find(
        address => address.types && address.types.includes(type),
      )
      return info ? info.long_name : ''
    }

    const address = {
      streetNumber: extractInfoType('street_number'),
      addressLine1: extractInfoType('route'),
      addressLine2: '',
      addressLine3: '',
      zipCode: extractInfoType('postal_code'),
      city: extractInfoType('locality'),
      country: extractInfoType(),
      long: suggest.location.lng,
      lat: suggest.location.lat,
    }
    return address
  }

  onSuggestSelect = suggest => {
    const { onSelected, t } = this.props

    if (suggest !== undefined && suggest.gmaps) {
      const address = this.reverseGoogleAddress(suggest)
      this.setState({
        ...this.state,
        address,
        showManual: false,
        errorZipCode: address.zipCode.length === 0 ? t('address.errorZipCode') : '',
      })
      onSelected(address)
    }
  }

  onInputChange = e => {
    const { onSelected } = this.props
    const newAdress = {
      ...this.state.address,
      long: null,
      lat: null,
      [e.target.name]: e.target.value,
    }

    this.setState({
      ...this.state,
      address: newAdress,
      errorZipCode: '',
    })

    onSelected(newAdress)
  }

  handleError = (errors, name) => {
    const error = errors.find(error => error.key === name)
    if (error) return [error.detail]
    return []
  }

  noResults = userInput => {
    if (userInput.length > 0) this.setState({ ...this.state, errorZipCode: '' })
  }

  showManual = () => {
    if (this.state.showManual)
      this.setState({
        ...this.state,
        showManual: !this.state.showManual,
      })
    else this.setState({ ...this.state, showManual: !this.state.showManual })
  }

  onChangeGeoSuggest = value => {
    if (value.length === 0) {
      const { onSelected } = this.props
      const address = {
        streetNumber: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        zipCode: '',
        city: '',
        country: 'FR',
        long: null,
        lat: null,
      }

      this.setState({
        ...this.state,
        address,
      })
      onSelected(address)
    }
  }

  onBlur = value => {
    if (this.state.address.zipCode.length === 0) {
      const address = {
        streetNumber: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        zipCode: '',
        city: '',
        country: 'FR',
        long: null,
        lat: null,
      }

      this.setState({ ...this.state, address })
      this.myRef.current.update('')
    }

    this.props.onBlur(value)
  }

  render() {
    const { t, placeholder, errors, required, initialValue, disabled } = this.props
    const { showManual, address, errorZipCode } = this.state

    if (showManual) {
      return (
        <React.Fragment>
          <div className="row mt-2 form-group">
            <div className="col-6">
              <div className="row no-gutters">
                <div className="col-3">
                  <Label>{t('address.streetNumber')}</Label>
                </div>
                <div className="col-9">
                  <input
                    type="text"
                    onChange={this.onInputChange}
                    className="form-control"
                    value={address.streetNumber}
                    name="streetNumber"
                    autoComplete="new-streetNumber"
                  />
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="row no-gutters">
                <div className="col-3">
                  <Label>{t('address.addressLine1')}</Label>
                </div>
                <div className="col-9">
                  <input
                    type="text"
                    onChange={this.onInputChange}
                    className="form-control"
                    value={address.addressLine1}
                    name="addressLine1"
                    autoComplete="new-addressLine1"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <div className="row no-gutters">
                <div className="col-3">
                  <Label>{t('address.city')}</Label>
                </div>
                <div className="col-9">
                  <input
                    type="text"
                    onChange={this.onInputChange}
                    className="form-control"
                    value={address.city}
                    autoComplete="new-city"
                    name="city"
                  />
                </div>
              </div>
            </div>

            <div className="col-6">
              <div className="row no-gutters">
                <div className="col-3">
                  <Label required>{t('address.zipCode')}</Label>
                </div>
                <div className="col-9">
                  <Input
                    type="text"
                    onChange={this.onInputChange}
                    className="form-control"
                    value={address.zipCode}
                    errors={errors}
                    autoComplete="new-zipCode"
                    name="zipCode"
                    maxLength={5}
                  />
                </div>
              </div>
            </div>
          </div>

          {!disabled && (
            <div>
              <span
                className="clickable border-bottom border-primary text-primary"
                onClick={this.showManual}
              >
                {t('address.search')}
              </span>
            </div>
          )}
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        <div className="input-group">
          <Geosuggest
            ref={this.myRef}
            className={this.props.className}
            initialValue={initialValue}
            disabled={this.props.disabled}
            onChange={this.onChangeGeoSuggest}
            onSelected={this.props.onSelected}
            placeholder={placeholder ? placeholder : t('address.placeholder')}
            inputClassName={classNames({ error: errors && !!errors.length }, 'form-control', {
              'bg-required': required && (!initialValue || !address.addressLine1),
            })}
            onSuggestSelect={this.onSuggestSelect}
            onSuggestNoResults={this.noResults}
            onBlur={this.onBlur}
            autoComplete="new-address"
            autoFocus={false}
            country={this.props.country}
          />
        </div>

        {!!errors.length && (
          <div className="row">
            <div className="error-message col-sm-12">
              {errors.map(errorDescription => (
                <div key={`${errorDescription}`}>{t(errorDescription)}</div>
              ))}
            </div>
          </div>
        )}

        {errorZipCode.length > 0 && (
          <div className="row">
            <div className="error-message col-sm-12">
              <div>{errorZipCode}</div>
            </div>
          </div>
        )}

        {!disabled && (
          <span
            className="clickable border-bottom border-primary text-primary"
            onClick={this.showManual}
          >
            {t('address.manual')}
          </span>
        )}
      </React.Fragment>
    )
  }
}

Address.defaultProps = {
  className: '',
  value: null,
  errors: [],
  name: 'address-bar',
  types: ['address'],
  placeholder: null,
  country: 'FR',
  initialValue: '',
  disabled: false,
  onSelected: () => {},
  onChange: () => {},
  onBlur: () => {},
}

Address.propTypes = {
  className: PropTypes.string,
  value: PropTypes.object,
  errors: PropTypes.array,
  types: PropTypes.array,
  onBlur: PropTypes.func,
  onSelected: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  initialValue: PropTypes.string,
  disabled: PropTypes.bool,
  country: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
}

export default withTranslation()(Address)
