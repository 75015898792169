import React, { Fragment, useState } from 'react'
import { inject, observer } from 'mobx-react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { setHours, setMinutes, getDay } from 'date-fns'

import Label from 'components/Label/Label'
import Button from 'components/Button/Button'
import ButtonWithConfirmation from 'components/ButtonWithConfirmation/ButtonWithConfirmation'
import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'
import DatePicker from 'components/Forms/DatePicker'

const SelfCareWaitAndAbandon = ({
  SelfCareStore: { wan, selfCareStatus },
  SelCareWaitAbandonCtrl: { loading, pause, isValid, abandon, appointmentDate, setProperty },
}) => {
  const { t } = useTranslation()
  const [grabbing, setGrabbing] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const closeModal = () => {
    setShowModal(false)
  }
  const onAbandon = async () => {
    await abandon(wan)
    setShowModal(false)
  }

  const today = new Date()
  let tomorrow = new Date()
  tomorrow.setDate(today.getDate() + 1)
  tomorrow.setHours(9)
  tomorrow.setMinutes(0)

  const minTime = setMinutes(setHours(new Date(), 9), 0)
  const maxTime = setMinutes(setHours(new Date(), 17), 0)

  const isWeekday = date => {
    const day = getDay(date)
    return day !== 0 && day !== 6
  }

  if (['finish', 'abandon'].includes(selfCareStatus)) return null

  return (
    <Fragment>
      <ModalWithBackdrop
        show={showModal}
        size="modal-lg"
        close={closeModal}
        draggable
        setGrabbing={setGrabbing}
      >
        <div className={classNames('modal-header', { grabbable: !grabbing, grabbing: grabbing })}>
          <h5 className="modal-title">{t('selfCare.abandon.title')}</h5>
          <button type="button" className="close" onClick={closeModal}>
            <span>&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <div className="row no-gutters">
            <div className="col-12">{t('selfCare.abandon.contactTitle')}</div>
          </div>

          <div className="row no-gutters mt-2">
            <div className="form-group col-12">
              <Label required={true}>{t('selfCare.abandon.contactTitle2')}</Label>
              <DatePicker
                className="form-control"
                name="appointmentDate"
                dateFormat="dd/MM/y H:mm"
                onChange={value => setProperty('appointmentDate', value)}
                selected={appointmentDate}
                minDate={tomorrow}
                placeholder="jj/mm/aaa"
                withPortal={true}
                showMonthDropdown={false}
                showYearDropdown={false}
                showTimeSelect={true}
                timeIntervals={30}
                minTime={minTime}
                maxTime={maxTime}
                filterDate={isWeekday}
                required={true}
                onFocus={e => (e.target.readOnly = true)}
                onBlur={e => (e.target.readOnly = false)}
              />
            </div>
          </div>
        </div>

        <div className="modal-footer pb-2 pt-2 mt-2 d-flex justify-content-between">
          <Button
            className="btn btn-secondary"
            onClick={closeModal}
            disabled={loading}
            loading={loading}
          >
            {t('common.dismiss')}
          </Button>

          <Button
            className="btn btn-warning"
            onClick={onAbandon}
            disabled={!isValid || loading}
            loading={loading}
          >
            {t('common.confirm')}
          </Button>
        </div>
      </ModalWithBackdrop>

      <div className="row no-gutters mt-4 pt-4 d-flex justify-content-between">
        {selfCareStatus !== 'indemnisation' && (
          <Button
            className="btn btn-danger"
            onClick={() => setShowModal(true)}
            disabled={loading}
            loading={loading}
          >
            <i className="fa fa-sign-out" /> {t('selfCare.abandon.button')}
          </Button>
        )}
        <ButtonWithConfirmation
          className="btn btn-primary"
          icon
          iconClassName="fa fa-pause"
          text={t('selfCare.pause.button')}
          onClick={() => pause(wan)}
          disabled={loading}
          loading={loading}
          confirmText={t('selfCare.pause.confirmMessage')}
          confirmButtonText={t('common.confirm')}
          dismissButtonText={t('common.dismiss')}
          switchButtonsOrder={true}
        />
      </div>
    </Fragment>
  )
}

export default inject('SelfCareStore', 'SelCareWaitAbandonCtrl')(observer(SelfCareWaitAndAbandon))
