import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useKonamiCode from 'hooks/useKonamiCode'

import './Maintenance.css'
import logo from 'assets/sps-logo.svg'

const Maintenance = () => {
  const { t } = useTranslation()
  const isKonamicode = useKonamiCode()
  const [byPassCode, setByPassCode] = useState()

  const applyMaintenanceBypassCode = () => {
    localStorage.setItem('maintenanceByPassCode', byPassCode)
    window.location.href = '/'
  }

  useEffect(() => {
    document.body.classList.add('maintenance')
    return () => document.body.classList.remove('maintenance')
  }, [])

  return (
    <div className="maintenance">
      <img height="50" width="120" src={logo} alt="Logo SPS" className="mt-3 ml-2" />
      <h1 className="ml-3">{t('maintenance.title')}</h1>
      {isKonamicode && (
        <div className="row mt-5 ml-5">
          <div className="col-4">
            <input
              className="form-control"
              type="text"
              value={byPassCode}
              onChange={e => setByPassCode(e.target.value)}
            />
          </div>
          <button className="btn btn-primary" onClick={applyMaintenanceBypassCode}>
            By Pass Maintenance
          </button>
        </div>
      )}
    </div>
  )
}

export default Maintenance
