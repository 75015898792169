import { action, computed, decorate, observable, runInAction } from 'mobx'
import { path } from 'ramda'
import SelfCareStore, { NO_DAMAGE } from 'stores/SelfCare/domain/SelfCareStore'
import { saveSelfCareForm } from 'services/selfCare'
import { isValid, format, setHours, getHours } from 'date-fns'
import AlertCtrl from 'stores/Common/view/AlertCtrl'

class SelCareWhereCtrl {
  loading = false
  dateOfLoss = null
  riskType = null
  damageRoomTypes = []
  damageRooms = []

  appointmentDate = null

  get damageRoomTypesWithoutNoDamage() {
    return this.damageRoomTypes.filter(value => value !== NO_DAMAGE).sort()
  }

  get isValid() {
    return (
      this.dateOfLoss !== null &&
      this.riskType !== null &&
      this.damageRoomTypes.length > 0 &&
      (this.damageRoomTypes.includes(NO_DAMAGE) || this.damageRooms.length > 0)
    )
  }

  get isValidWithAppointmentDate() {
    if (!this.isValid) return false

    return isValid(this.appointmentDate)
  }

  loadData = claim => {
    this.dateOfLoss = path(['claimInformation', 'dateOfLoss'], claim)
      ? new Date(path(['claimInformation', 'dateOfLoss'], claim))
      : null
    this.riskType = path(['claimInformation', 'riskTypeKey'], claim)
    this.damageRoomTypes = path(['damageDescription', 'damageRoomTypes'], claim)
    this.damageRooms = path(['damageDescription', 'damageRooms'], claim)
  }

  setProperty = (key, value) => {
    this[key] = value
    if (key === 'appointmentDate' && getHours(this.appointmentDate) === 0) {
      this.appointmentDate.setHours(9)
    }
  }

  setRiskType = value => {
    if (this.riskType !== value) {
      this.damageRooms = []
    }

    this.riskType = value
  }

  setDamageRoomTypes = value => {
    if (this.damageRoomTypes.includes(value)) {
      this.damageRoomTypes = this.damageRoomTypes.filter(location => location !== value)
    } else if (value === NO_DAMAGE) {
      this.damageRoomTypes = [NO_DAMAGE]
      this.damageRooms = []
    } else {
      this.damageRoomTypes = this.damageRoomTypes.filter(location => location !== NO_DAMAGE)
      this.damageRoomTypes.push(value)
    }
  }

  setDamageRooms = value => {
    if (this.damageRooms.includes(value)) {
      this.damageRooms = this.damageRooms.filter(room => room !== value)
    } else {
      this.damageRooms.push(value)
    }
  }

  get dateOfLossISOString() {
    const dateValue = setHours(new Date(this.dateOfLoss), 10)

    if (isValid(dateValue)) {
      return format(dateValue, "yyyy-MM-dd'T'HH:mm:ssxxx")
    }

    return null
  }

  get appointmentDateISOString() {
    if (isValid(this.appointmentDate)) {
      return format(this.appointmentDate, "yyyy-MM-dd'T'HH:mm:ssxxx")
    }

    return null
  }

  save = async wan => {
    if (!this.isValid) return
    if (this.damageRoomTypes.includes(NO_DAMAGE) && !this.isValidWithAppointmentDate) return

    this.loading = true
    let attributes = {
      dateOfLoss: this.dateOfLossISOString,
      riskType: this.riskType,
      damageRoomTypes: this.damageRoomTypes,
      damageRooms: this.damageRooms,
      appointmentDate: this.appointmentDateISOString,
    }
    try {
      const res = await saveSelfCareForm(wan, attributes, 'selfCareWhere')
      SelfCareStore.updateClaim(res)
    } catch (err) {
      console.error(err)
      AlertCtrl.alert('danger', 'selfCare.saveError')
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }
}

const DecoratedSelCareWhereCtrl = decorate(SelCareWhereCtrl, {
  loading: observable,

  dateOfLoss: observable,
  riskType: observable,
  damageRoomTypes: observable,
  damageRooms: observable,
  appointmentDate: observable,

  damageRoomTypesWithoutNoDamage: computed,
  dateOfLossISOString: computed,
  isValid: computed,
  isValidWithAppointmentDate: computed,
  appointmentDateISOString: computed,

  loadData: action,
  setProperty: action,
  setRiskType: action,
  setDamageRoomTypes: action,
  setDamageRooms: action,
  save: action,
})

export default new DecoratedSelCareWhereCtrl()
