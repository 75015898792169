import React from 'react'
import { observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'

import { euro } from 'utils/currency'
import DetailPackageCtrl from 'stores/Mission/view/DetailPackageCtrl'
import UnitSuffix from 'pages/Mission/pages/Calculation/UnitSuffix'

class UnitPrice extends React.Component {
  render() {
    const { t } = this.props
    const {
      quantityTotal,
      originalTotalPrice,
      area,
      areaPresent,
      unit,
    } = DetailPackageCtrl.packageData

    return (
      <div className="unitprice d-flex justify-content-between">
        <span className="font-weight-bold">
          {t('mission.calculation.detailPackage.unitPriceLabel')}
        </span>
        <span>
          {euro(area ? quantityTotal / area : originalTotalPrice)} /
          <UnitSuffix areaPresent={areaPresent} areaType={unit} />
        </span>
      </div>
    )
  }
}

export default withTranslation()(observer(UnitPrice))
