import React from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { noop } from 'utils'
import ResponsiveSelect from 'components/ResponsiveSelect/ResponsiveSelect'
import TextArea from 'components/Forms/TextArea'
import Button from 'components/Button/Button'
import Attachments from './Attachments'

function Form({
  MessagingStore: {
    contextsAsOptions,
    currentContext,
    setProperty,
    sendMessage,
    sendingMessage,
    validMessage,
    fetchMessages,
    fetchNotifications,
    loadingMessages,
    currentMessage: { text, setProperty: setMessage, attachments, deleteDocument, isUrgent },
  },
}) {
  const { t } = useTranslation()
  const { id } = useParams()

  return (
    <div className="form p-3 bg-light mb-3">
      <div className="row mb-2">
        <div className="col-12 col-lg-10 order-last order-lg-first">
          <ResponsiveSelect
            name="currentContext"
            placeholder={t('messaging.form.context')}
            value={currentContext}
            options={contextsAsOptions}
            onChange={e => setProperty(e.target.name, e.target.value)}
          />
        </div>
        <div className="col-12 col-lg-2 order-first order-lg-last mb-2 mb-lg-0">
          <Button
            className="btn btn-primary btn-block"
            onClick={() => {
              fetchMessages(id)
              fetchNotifications(id)
            }}
            disabled={loadingMessages}
            loading={loadingMessages}
          >
            <i className="fa fa-refresh" />
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-lg-10">
          <TextArea
            name="text"
            className="form-control text-area"
            placeholder={t('messaging.form.text')}
            value={text}
            onChange={e => setMessage(e.target.name, e.target.value)}
            maxLength="770"
          />
        </div>
        <div className="col-12 col-lg-2 d-flex flex-lg-column justify-content-lg-center flex-row justify-content-end mt-3 mt-lg-0">
          <div className="form-group form-check mb-0 mb-lg-3 mr-2 mr-lg-0">
            <input
              type="checkbox"
              className="form-check-input"
              id="isUrgent"
              checked={isUrgent}
              onChange={() => setMessage('isUrgent', !isUrgent)}
            />
            <label className="form-check-label" htmlFor="isUrgent">
              {t('messaging.form.urgent')}
            </label>
          </div>

          <Button
            className="btn btn-secondary mb-0 mb-lg-3 mr-2 mr-lg-0"
            onClick={() => setProperty('showSDModal', true)}
          >
            <i className="fa fa-paperclip" />
          </Button>

          <Button
            className="btn btn-primary"
            onClick={sendMessage}
            disabled={sendingMessage || !validMessage}
          >
            {t('messaging.form.send')}
          </Button>
        </div>
      </div>

      <Attachments
        documents={attachments}
        onSelectItem={noop}
        deleteItem={deleteDocument}
        canDelete={true}
        showInfo={true}
      />
    </div>
  )
}

export default inject('MessagingStore')(observer(Form))
