import React from 'react'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import classNames from 'classnames'

import DetailPackageCtrl from 'stores/Mission/view/DetailPackageCtrl'
import CreatePackageCtrl from 'stores/Mission/view/CreatePackageCtrl'
import ButtonWithConfirmation from 'components/ButtonWithConfirmation/ButtonWithConfirmation'
import { euro } from 'utils/currency'
import { formatArea } from 'utils'

const Area = ({ area, unit }) => {
  if (!area) return null

  return (
    <React.Fragment>
      {`(${formatArea(area)}`}&nbsp;{`${unit === 'M2' ? 'm²' : 'ml'})`}
    </React.Fragment>
  )
}

class CartPackage extends React.Component {
  showPackage = packageData => {
    const {
      history,
      match: {
        params: { id, type },
      },
    } = this.props

    if (packageData.priceType === 'custom') {
      CreatePackageCtrl.setPackage(packageData)
      if (packageData.location === 'outdoor') {
        history.push(`/${type}/${id}/calculation/createOutdoorPackage`)
      } else {
        history.push(`/${type}/${id}/calculation/createPackage`)
      }
    } else {
      DetailPackageCtrl.setPackage(packageData)
      if (packageData.location === 'outdoor') {
        history.push(`/${type}/${id}/calculation/detailOutdoorPackage`)
      } else {
        history.push(`/${type}/${id}/calculation/detailPackage`)
      }
    }
  }
  removePackage = id => {
    this.props.CartStore.removePackage(id)
  }
  selectPriceType = ({ dataSelfRepair, selfRepairButton }) => {
    const {
      match: {
        params: { type },
      },
      MissionStore: { isMissionClosed },
      ManagerClaimStore: { isClaimClosed },
    } = this.props
    const isClosed = type === 'mission' ? isMissionClosed : isClaimClosed

    if (isClosed) return
    else if (dataSelfRepair !== selfRepairButton)
      this.props.data.setProperty('selfRepair', !dataSelfRepair)
    else return
  }

  render() {
    const {
      t,
      data,
      data: {
        id,
        packageType,
        area,
        unit,
        quantity,
        label,
        selfRepair,
        isPackageOverrode,
        priceListLabel,
        finalPriceCatalog,
        finalPriceSelfRepair,
      },
      match: { params: type },
      MissionStore: { isMissionClosed },
      ManagerClaimStore: { isClaimClosed },
    } = this.props
    const isClosed = type === 'mission' ? isMissionClosed : isClaimClosed

    return (
      <div className="row no-gutters align-items-center mb-2">
        <div
          className="package-title col-12 col-lg-7 pl-4"
          onClick={this.showPackage.bind(this, data)}
        >
          {priceListLabel ? priceListLabel + ' - ' : ''} {label}
          <Area area={area} unit={unit} />
          {quantity > 1 ? ` (x${quantity})` : ''}
        </div>

        <div className="col-6 col-sm-5 col-lg-2 pl-4 pl-lg-0 text-left text-lg-right">
          <button
            className={classNames('btn btn-outline-primary', {
              'selected-price': !selfRepair,
              'disabled-price': selfRepair,
            })}
            onClick={this.selectPriceType.bind(this, {
              dataSelfRepair: selfRepair,
              selfRepairButton: false,
            })}
          >
            <i className="fa fa-book d-inline-block d-lg-none" aria-hidden="true" />{' '}
            {euro(finalPriceCatalog)}
            {isPackageOverrode ? '*' : ''}
          </button>
        </div>

        {packageType !== 'DAT_LEAK' ? (
          <div className="col-6 col-sm-5 col-lg-2 text-right">
            <button
              className={classNames('btn btn-outline-primary', {
                'selected-price': selfRepair,
                'disabled-price': !selfRepair,
              })}
              onClick={this.selectPriceType.bind(this, {
                dataSelfRepair: selfRepair,
                selfRepairButton: true,
              })}
            >
              <i className="fa fa-user d-inline-block d-lg-none" aria-hidden="true" />{' '}
              {euro(finalPriceSelfRepair)}
              {isPackageOverrode ? '*' : ''}
            </button>
          </div>
        ) : (
          <div className="col-6 col-sm-5 col-lg-2" />
        )}

        <div className="col-sm-2 col-lg-1 d-none d-sm-inline-block text-right">
          {!isClosed && (
            <ButtonWithConfirmation
              className="btn btn-outline-primary rounded-circle"
              icon
              iconClassName="fa fa-trash"
              onClick={this.removePackage.bind(this, id)}
              confirmText={t('mission.calculation.cart.confirmDelete')}
            />
          )}
        </div>
      </div>
    )
  }
}

export default compose(
  inject('CartStore', 'MissionStore', 'ManagerClaimStore'),
  withRouter,
  withTranslation(),
  observer,
)(CartPackage)
