import React, { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'
import Button from 'components/Button/Button'
import Input from 'components/Forms/Input'
import Label from 'components/Label/Label'
import Select from 'components/ResponsiveSelect/ResponsiveSelect'

const ScreenshotModal = ({
  wan,
  TeleexpertiseCtrl: {
    imgData,
    showScreenshotModal,
    closeScreenshotModal,
    uploadScreenshot,
    uploading,
  },
}) => {
  const [fileName, setFileName] = useState('')
  const [category, setCategory] = useState('POTH')

  useEffect(() => {
    setFileName('')
    setCategory('POTH')
  }, [showScreenshotModal])

  const { t } = useTranslation()

  const options = [
    { value: 'PCCL', label: 'Cause du sinistre' },
    { value: 'PDFO', label: 'Dommage constaté' },
    { value: 'PDMO', label: 'Dommage antérieur' },
    { value: 'PFUR', label: 'Dommage mobilier' },
    { value: 'POTH', label: 'Autre' },
  ]

  return (
    <ModalWithBackdrop
      show={showScreenshotModal}
      close={() => closeScreenshotModal()}
      scrollable
      backdropColor="white"
      backdropOpacity={1}
    >
      <div className="modal-header d-flex justify-content-between">
        <h5 className="modal-title">{t('mission.upload.modalPackageTitle')}</h5>

        <i
          className="fa fa-close clickable"
          onClick={() => {
            closeScreenshotModal()
            setFileName('')
            setCategory('POTH')
          }}
        />
      </div>

      <div className="modal-body container">
        <div className="row mb-3">
          <div className="col-6 form-group">
            <Label required>{t('mission.upload.fileName')}</Label>
            <Input
              name="fileName"
              className="form-control"
              onChange={e => setFileName(e.target.value)}
              value={fileName}
              placeholder={t('mission.upload.fileName')}
            />
          </div>

          <div className="col-6 form-group">
            <Label required>
              {t('mission.upload.categoryType', {
                category: 'photo',
              })}
            </Label>

            <Select
              name="category"
              options={options}
              value={category}
              onChange={e => setCategory(e.target.value)}
            />
          </div>
        </div>

        <div className="d-flex justify-content-center">
          {imgData && (
            <img
              className="img-fluid"
              src={'data:image/png;base64,' + imgData}
              alt="screenshot taken"
            />
          )}
        </div>
      </div>

      <div className="modal-footer pb-2 pt-2">
        <Button
          className="btn btn-secondary"
          onClick={() => {
            closeScreenshotModal()
            setFileName('')
            setCategory('POTH')
          }}
          disabled={uploading}
        >
          {t('common.cancel')}
        </Button>

        <Button
          className="btn btn-success"
          onClick={() => {
            uploadScreenshot({ wan, fileName, category })
          }}
          disabled={uploading || fileName.length === 0}
          loading={uploading}
        >
          {t('mission.upload.add')}
        </Button>
      </div>
    </ModalWithBackdrop>
  )
}

export default inject('TeleexpertiseCtrl')(observer(ScreenshotModal))
