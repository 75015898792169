import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import CoveringForm from 'pages/SelfCare/Estimation/CoveringForm'
import UnitDetail from 'pages/EasyEstimation/components/UnitDetail'
import ButtonWithConfirmation from 'components/ButtonWithConfirmation/ButtonWithConfirmation'
import { Thumbnail } from 'pages/Mission/components/LiteGallery'
import Button from 'components/Button/Button'

const Covering = ({
  store,
  rcpData,
  rcpPackages,
  rcpData: { id, groupId, part, area, quantity, percentageDammaged, covering, photo1, photo2 },
  packageData,
  closeNewForm,
  CartStore: { changeGroupIdPackages, save },
  PropertyEmbellishmentCtrl: { editCoveringForm, editCovering, deleteCovering, setProperty },
  loading = false,
}) => {
  const [showEditForm, setShowEditForm] = useState(false)
  const { t } = useTranslation()

  if (showEditForm && editCoveringForm === groupId) {
    return (
      <CoveringForm
        mode="edit"
        store={store}
        show={showEditForm}
        closeForm={() => {
          save()
          setShowEditForm(false)
          setProperty('editCoveringForm', null)
        }}
      />
    )
  }

  return (
    <div className="row no-gutters align-items-center rounded p-2 mb-2" key={`rcp-${id}`}>
      <div className="col-7">
        <span className="font-weight-bold">{t(`mission.easyEstimation.parts.${part}`)}</span>
        <br />
        <UnitDetail
          part={part}
          unitByDefault={rcpData.unitByDefault}
          area={area}
          quantity={quantity}
          percentageDammaged={percentageDammaged}
          label={covering.label}
        />
        <br />
        <div className="d-flex">
          {photo1 && (
            <div className="thumbnail-preview btn btn-outline-primary">
              <Thumbnail
                data={{ id: photo1, name: "Photo vue d'ensemble" }}
                thumbnailWidth="52px"
                thumbnailHeight="52px"
                className="d-flex align-items-center justify-content-center"
              />
            </div>
          )}
          {photo2 && (
            <div className="thumbnail-preview btn btn-outline-primary ml-2">
              <Thumbnail
                data={{ id: photo2, name: 'Photo du dommage' }}
                thumbnailWidth="52px"
                thumbnailHeight="52px"
                className="d-flex align-items-center justify-content-center"
              />
            </div>
          )}
        </div>
      </div>

      <div className="col-5 d-flex justify-content-end align-items-center">
        <Button
          className="btn btn-outline-primary rounded-circle mr-1"
          onClick={() => {
            closeNewForm()
            editCovering(rcpData, rcpPackages)
            setShowEditForm(true)
          }}
          disabled={loading}
        >
          <i className="fa fa-edit" />
        </Button>
        <ButtonWithConfirmation
          className="btn btn-outline-primary rounded-circle"
          icon
          iconClassName="fa fa-trash"
          onClick={() => deleteCovering(groupId)}
          confirmText={t('mission.calculation.cart.confirmDelete')}
          disabled={loading}
        />
      </div>
    </div>
  )
}

export default inject('CartStore', 'PropertyEmbellishmentCtrl')(observer(Covering))
