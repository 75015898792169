import { observable, computed, action, decorate } from 'mobx'

import AbstractField from 'utils/dynform/AbstractField'
import { roundDecimal } from 'utils/currency'

class InputPrice extends AbstractField {
  value = null
  errors = []
  show = true
  originalValue = null

  type = 'inputPrice'
  choices = null
  maxLength = null
  maxValue = null
  minValue = null
  multiple = false
  expanded = false
  children = null

  constructor(data) {
    super(data)

    this.type = data.type || 'inputPrice'
    this.choices = data.choices || null
    this.maxLength = data.maxLength || null
    this.maxValue = isFinite(data.maxValue) ? data.maxValue : null
    this.minValue = isFinite(data.minValue) ? data.minValue : null

    if (data.value) this.value = parseFloat(data.value) || 0
    else if (data.default) this.value = parseFloat(data.default) || 0
    else this.value = null || 0
    this.value = roundDecimal(this.value)
    this.show = data.show
    this.originalValue = this.value
  }

  get changed() {
    if (this.name === 'workAmount') return false

    return this.value !== this.originalValue
  }

  setOriginalData() {
    this.value = this.originalValue
  }
}

const DecoratedInputPrice = decorate(InputPrice, {
  value: observable,
  errors: observable,
  show: observable,
  originalValue: observable,

  setOriginalData: action,

  changed: computed,
})

export default DecoratedInputPrice
