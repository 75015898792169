import React from 'react'
import { observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames'

import './UserManagement.css'
import UserManagementCtrl from 'stores/Parameters/UserManagement/UserManagementCtrl'
import UserManagementStore from 'stores/Parameters/UserManagement/UserManagementStore'
import { uid } from 'utils'

const User = observer(({ data, setUser }) => {
  return (
    <tr className={classNames('clickable', { 'disabled-user': !data.enabled })} onClick={setUser}>
      <td className="d-none d-lg-table-cell">{data.firstName}</td>
      <td>{data.lastName}</td>
      <td>{data.username}</td>
      <td className="d-none d-lg-table-cell">{data.email}</td>
    </tr>
  )
})

class UserManagement extends React.Component {
  addNewUser = customerId => {
    UserManagementCtrl.resetForm()
    UserManagementCtrl.setProperty('showUserModal', true)
    UserManagementCtrl.setProperty('customerId', customerId)
  }
  loadData = customerId => {
    UserManagementStore.loadData(customerId)
  }
  setUser = (user, customerId) => {
    UserManagementCtrl.setProperty('user', user)
    UserManagementCtrl.user.getAreas()
    UserManagementCtrl.setProperty('showUserModal', true)
    UserManagementCtrl.setProperty('customerId', customerId)
  }

  render() {
    const { t, customerId, name, users } = this.props
    const { level } = UserManagementStore

    return (
      <table className="table table-bordered">
        <thead>
          <tr className="table-primary">
            <th colSpan="4" scope="col">
              <div className="mt-2 float-left">{name}</div>

              <div className="float-right">
                {level > 0 ? (
                  <div
                    className="btn btn-success mr-3 clickable"
                    onClick={this.loadData.bind(this, customerId)}
                  >
                    {t('parameters.userManagement.seeGroup')}
                  </div>
                ) : null}

                <button
                  className="btn btn-success"
                  onClick={this.addNewUser.bind(this, customerId)}
                >
                  <i className="fa fa-plus" />
                </button>
              </div>
            </th>
          </tr>

          <tr>
            <th scope="col" className="d-none d-lg-table-cell">
              {t('parameters.userManagement.firstName')}
            </th>
            <th scope="col">{t('parameters.userManagement.lastName')}</th>
            <th scope="col">{t('parameters.userManagement.login')}</th>
            <th scope="col" className="d-none d-lg-table-cell">
              {t('parameters.userManagement.email')}
            </th>
          </tr>
        </thead>
        <tbody>
          {users.length === 0 ? (
            <tr>
              <td colSpan="4" className="text-center">
                {t('parameters.userManagement.none')}
              </td>
            </tr>
          ) : (
            users.map(user => (
              <User
                key={`user-${uid(10)}`}
                data={user}
                setUser={this.setUser.bind(this, user, customerId)}
              />
            ))
          )}
        </tbody>
      </table>
    )
  }
}

export default withTranslation()(observer(UserManagement))
