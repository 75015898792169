import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import FurnitureCtrl from 'stores/Mission/EasyEstimation/FurnitureCtrl'
import Button from 'components/Button/Button'
import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'
import ResponsiveSelect from 'components/ResponsiveSelect/ResponsiveSelect'
import classNames from 'classnames'

const CategoryModal = ({ CommonStore: { furnitureCategoriesOptions }, selectedList }) => {
  const { t } = useTranslation()
  const [category, setCategory] = useState('')
  const { showCategoryModal, addNewCategory, setProperty } = FurnitureCtrl
  const categoryOptions = () => {
    return furnitureCategoriesOptions.filter(fo => {
      return undefined === selectedList.find(selected => selected.cde === fo.value)
    })
  }
  const [grabbing, setGrabbing] = useState(false)
  const closeModal = () => {
    setProperty('showCategoryModal', !showCategoryModal)
  }

  return (
    <ModalWithBackdrop
      show={showCategoryModal}
      size="modal-md"
      onClose={closeModal}
      draggable
      setGrabbing={setGrabbing}
    >
      <div
        className={classNames('modal-header d-flex justify-content-between', {
          grabbable: !grabbing,
          grabbing: grabbing,
        })}
      >
        <h5 className="modal-title">Ajouter une catégorie</h5>

        <i className="fa fa-close clickable" onClick={closeModal} />
      </div>

      <div className="modal-body container">
        <ResponsiveSelect
          name="category"
          isSearchable
          value={category}
          onChange={e => setCategory(e.target.value)}
          options={categoryOptions()}
        />
      </div>

      <div className="modal-footer pb-2 pt-2">
        <button className="btn btn-secondary" onClick={closeModal}>
          {t('common.cancel')}
        </button>

        <Button
          className="btn btn-success"
          disabled={category.length === 0}
          onClick={() => {
            const data = furnitureCategoriesOptions.find(option => option.value === category)
            addNewCategory({
              cde: data.value,
              label: data.label,
            })
            closeModal()
          }}
        >
          {t('common.add')}
        </Button>
      </div>
    </ModalWithBackdrop>
  )
}

export default inject('CommonStore')(observer(CategoryModal))
