import Compressor from 'compressorjs'

export const isImage = type => {
  return (
    type === 'image/gif' ||
    type === 'image/png' ||
    type === 'image/jpeg' ||
    type === 'image/bmp' ||
    type === 'image/webp' ||
    type === 'image/heif' ||
    type === 'image/heic' ||
    type === 'image/heif-sequence' ||
    type === 'image/heic-sequence'
  )
}

export const compressImage = async file => {
  try {
    const compressedImg = await new Promise((resolve, reject) => {
      new Compressor(file, { quality: 0.6, success: resolve, error: reject })
    })
    return compressedImg
  } catch (error) {
    throw error
  }
}
