import { observable, action, decorate, runInAction } from 'mobx'
import i18next from 'i18next'

import Appointment from './Appointment'
import AlertCtrl from 'stores/Common/view/AlertCtrl'
import CalendarExpertStore from 'stores/Calendar/CalendarExpertStore'
import { MISSION_TYPE_ID } from 'utils/constants'
import { format, setHours, setMinutes } from 'date-fns'

class AppointmentCtrl {
  showModal = false
  currentAppointment = null
  currentExpert = null

  showNewModal(appointment = null, expert) {
    if (
      appointment.start === appointment.end &&
      format(appointment.start, 'HH:mm:ss') === '00:00:00'
    ) {
      appointment.end = setHours(appointment.end, 23)
      appointment.end = setMinutes(appointment.end, 59)
    }
    this.currentAppointment = new Appointment(appointment)
    this.currentExpert = expert
    this.showModal = true
  }

  showEditModal(appointment, expert) {
    this.currentAppointment = appointment
    this.currentExpert = expert
    this.showModal = true
  }

  hideModal() {
    this.currentAppointment.resetData()
    this.showModal = false
    this.currentAppointment = null
    this.currentExpert = null
  }

  async refreshCalendar() {
    // MULTI EXPERT CASE
    if (this.currentExpert === 'multiExpert') {
      const { experts } = CalendarExpertStore
      const selectedExpert = experts.find(expert => expert.id === this.currentAppointment.assignee)

      if (!selectedExpert) throw new Error('missingExpert')

      // IF ASSIGNEE CHANGED FROM ORIGINAL DATA FETCH BOTH CALENDAR AND NOT NULL (NEW APPOINTEMENT IS NULL NO ORIGINAL ASSIGNEE)
      if (
        this.currentAppointment.originalAssignee !== null &&
        this.currentAppointment.originalAssignee !== this.currentAppointment.assignee
      ) {
        const originalExpert = experts.find(
          expert => expert.id === this.currentAppointment.originalAssignee,
        )

        if (!originalExpert) throw new Error('missingExpert')

        originalExpert.getCalendar(CalendarExpertStore.day, CalendarExpertStore.view)
      }

      selectedExpert.getCalendar(CalendarExpertStore.day, CalendarExpertStore.view)
    } else {
      await this.currentExpert.getCalendar(CalendarExpertStore.day, CalendarExpertStore.view)
    }

    runInAction(() => {
      this.hideModal()
    })
  }

  async saveAppointment() {
    if (
      this.currentAppointment.type === MISSION_TYPE_ID &&
      this.currentAppointment.start.getTime() <= new Date().getTime()
    ) {
      AlertCtrl.alert('danger', 'calendar.appointment.dateError')
      return
    }

    try {
      await this.currentAppointment.save()
      await this.refreshCalendar()
      AlertCtrl.alert('success', 'calendar.appointment.saved')
    } catch (err) {
      this.handleError(err)
    }
  }

  async deleteAppointment() {
    try {
      await this.currentAppointment.destroy()
      await this.refreshCalendar()
      AlertCtrl.alert('success', 'calendar.appointment.deleted')
    } catch (err) {
      this.handleError(err)
    }
  }

  handleError(err) {
    if (err === 'missingExpert') {
      AlertCtrl.alert('warning', 'calendar.appointment.missingExpert')
    } else if (err.status !== 500) {
      try {
        AlertCtrl.alert(
          'danger',
          i18next('calendar.appointment.error', { status: err.status, data: err.data }),
          true,
        )
      } catch (error) {
        console.warn(error)
      }
    } else {
      console.warn(err)
    }
  }
}

const AppointmentCtrlDecorated = decorate(AppointmentCtrl, {
  showModal: observable,
  currentAppointment: observable,
  currentExpert: observable,

  showNewModal: action.bound,
  showEditModal: action.bound,
  hideModal: action.bound,
  saveAppointment: action.bound,
  deleteAppointment: action.bound,
  refreshCalendar: action.bound,
})

export default new AppointmentCtrlDecorated()
