import { path, map, sortBy, prop, type, isNil } from 'ramda'

import request from 'services/httpRequest'

export const searchPackage = (wan, searchData) => {
  const data = {
    data: {
      type: 'package_search',
      attributes: searchData,
    },
  }
  const params = { 'search-es': 1 }
  if (searchData.showMore) params.all = 1

  return request({
    method: 'post',
    url: `/claimFiles/v1/claimFileAssessor/${wan}/estimation/package-search`,
    params,
    data,
  })
    .then(res => {
      const packages = []
      map(packageData => {
        packages.push(packageData)
      }, res[0].data)
      return { packages, totalSearchPackage: res[0].meta['total-count'] }
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

export const furnituresCategories = async () => {
  try {
    const res = await request({
      method: 'get',
      url: '/businessData/v1/furnitureCategory',
    })

    return res.data.map(category => ({ ...category.attributes }))
  } catch (error) {
    throw error
  }
}

export const furnitureDataTransformer = furniture => {
  return {
    productId: furniture.originId,
    priceWithVat: furniture.price,
    brand: furniture.brand,
    model: furniture.label,
    image: furniture.image,
    releaseYear: furniture.releaseYear,
    storeProducts: isNil(furniture.storeProducts) ? [] : furniture.storeProducts,
    releasePrice: furniture.introductoryPrice,
    group: furniture.group,
    groupId: furniture.groupId,
    features: furniture.features,
    productType: furniture.productType,
  }
}

export const searchFurniture = async ({ wan, search, page }) => {
  const params = {
    'filter[query]': search,
    page: page,
  }

  try {
    const res = await request({
      method: 'get',
      url: `/retail-product/v1/${wan}`,
      params,
    })

    return Promise.resolve({
      furnitures: res.data ? res.data : [],
      totalSearchFurniture: res.meta['total-count'],
    })
  } catch (err) {
    return Promise.reject(err)
  }
}

export const checkPrice = async upptechDetailRequestData => {
  const data = {
    data: {
      type: 'upptechDetailRequest',
      attributes: upptechDetailRequestData,
    },
  }

  try {
    const res = await request({
      method: 'post',
      url: `/retail-product/v1/product-detail`,
      data,
    })

    return res.data.attributes
  } catch (err) {
    return Promise.reject(err)
  }
}

export const fetchRoomList = async ({ wan, params }) => {
  try {
    const res = await request({
      method: 'get',
      url: `/claimFiles/v1/claimFileAssessor/${wan}/estimation/rooms_complete`,
      params,
    })
    return sortBy(prop('label'), res)
  } catch (error) {
    throw error
  }
}

export const saveCart = ({
  wan,
  cart,
  totals,
  settlements,
  precisions,
  instructions,
  ventilatedByInvolvedParties,
  idCart = null,
  easyEstimation,
}) => {
  const method = idCart ? 'post' : 'put'
  const url = `/claimFiles/v1/claimFileAssessor/${wan}/estimation/calculation${
    idCart ? `/${idCart}` : ''
  }`

  return request({
    method,
    url,
    data: {
      data: {
        type: 'estimation',
        attributes: {
          calculation: {
            furnitures: cart.furnitures,
            packages: cart.packages,
            relatedCosts: cart.relatedCosts,
            exceedingGuaranteedCeilings: cart.overflows,
            noGuaranteedDamages: cart.noGuarantees,
            totals: {
              ...totals,
              settlements,
              precisions,
              instructions,
              ventilatedByInvolvedParties,
            },
            conclusion: cart.conclusion,
            optionsAndOtherPrejudices: cart.optionsAndOtherPrejudices,
            roomCoveringPackages: cart.roomCoveringPackages,
          },
          configuration: cart.configuration,
          easyEstimationAvailable: easyEstimation,
          asp: cart.asp,
        },
      },
    },
  })
    .then(res => {
      return res.data.attributes.updatedAt
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

export const fetchCart = wan => {
  return request({
    method: 'get',
    url: `/claimFiles/v1/claimFileAssessor/${wan}/estimation/`,
  })
    .then(res => {
      const settlements = path(['data', 'attributes', 'calculation', 'totals', 'settlements'], res)
      const precisions = path(['data', 'attributes', 'calculation', 'totals', 'precisions'], res)
      const instructions = path(
        ['data', 'attributes', 'calculation', 'totals', 'instructions'],
        res,
      )

      return {
        id: res.data.id,
        easyEstimation: res.data.attributes.easyEstimationAvailable,
        asp: res.data.attributes.asp,
        configuration: res.data.attributes.configuration,
        calculation: {
          packages: res.data.attributes.calculation.packages,
          furnitures: res.data.attributes.calculation.furnitures.map(
            furniture => furniture.content,
          ),
          relatedCosts: res.data.attributes.calculation.relatedCosts,
          overflows: res.data.attributes.calculation.exceedingGuaranteedCeilings,
          noGuarantees: res.data.attributes.calculation.noGuaranteedDamages,
          settlements: isNil(settlements) || type(settlements) === 'Array' ? null : settlements,
          precisions: isNil(precisions) || type(precisions) === 'Array' ? null : precisions,
          instructions: isNil(instructions) || type(instructions) === 'Array' ? null : instructions,
          conclusion: res.data.attributes.calculation.conclusion,
          optionsAndOtherPrejudices: res.data.attributes.calculation.optionsAndOtherPrejudices,
          roomCoveringPackages: res.data.attributes.calculation.roomCoveringPackages,
        },
        lastUpdatedAt: res.data.attributes.updatedAt,
      }
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

export const fetchRoomCoveringPackages = async ({ wan, covering, room }) => {
  try {
    const res = await request({
      method: 'GET',
      url: `claimFiles/v1/claimFileAssessor/${wan}/estimation/rcp`,
      params: { covering, room },
    })
    return path([0, 'data'], res)
  } catch (error) {
    console.log(error)
  }
}

export const toggleFavoritePackages = packageId => {
  return request({
    method: 'post',
    url: `/favorite/v1/package/${packageId}`,
    params: {
      'page[size]': 'all',
    },
  })
    .then(res => res.data)
    .catch(err => {
      return Promise.reject(err)
    })
}

export const getFavoritePackages = () => {
  return request({
    method: 'get',
    url: `/favorite/v1/package`,
    params: {
      'page[size]': 'all',
    },
  })
    .then(res => res.data)
    .catch(err => {
      return Promise.reject(err)
    })
}

export const loadObsolescenceRate = async (customerId, gta, formulaContractKeys) => {
  try {
    const res = await request({
      method: 'get',
      url: `/obsolescenceRates/v1/${customerId}/${gta}`,
      params: {
        formulaContractKeys,
      },
    })

    return path(['data', 'attributes'], res)
  } catch (err) {
    console.log(err)
    return []
  }
}

export const fetchRoomListSelfCare = async ({ wan, params = {} }) => {
  try {
    const res = await request({
      method: 'get',
      url: `/self-care/${wan}/rooms-complete`,
      params,
    })

    return res
  } catch (error) {
    throw error
  }
}
