import { useCallback, useEffect, useRef, useState } from 'react'
import OT from '@opentok/client'
import NetworkTest from 'opentok-network-test-js'

const networkTestVideoTimeout = 15000 // set as 15 seconds
const startTestOnLoad = true

export const useNetworkTest = ({ apikey, sessionId, token, videoSource }) => {
  /* const [otNetworkTest, setOtNetworkTest] = useState(null); */
  const otNetworkTest = useRef()
  const [runTest, setRunTest] = useState(startTestOnLoad)
  const [isRunning, setIsRunning] = useState(false)
  const [connectivityTest, setConnectivityTest] = useState({
    data: null,
    loading: true,
  })
  const [qualityTest, setQualityTest] = useState({ data: null, loading: true })

  const initNetworkTest = useCallback(() => {
    otNetworkTest.current = new NetworkTest(
      OT,
      {
        apiKey: apikey,
        sessionId,
        token,
      },
      {
        videoSource, //added to handle for multiple camera
      },
      { timeout: networkTestVideoTimeout },
    )
    // setOtNetworkTest(instance);
  }, [apikey, sessionId, token, videoSource])

  const runNetworkTest = useCallback(() => {
    if (otNetworkTest.current && runTest && !isRunning) {
      setRunTest(false)
      setIsRunning(true)
      setConnectivityTest(state => ({ data: state.data, loading: true }))
      setQualityTest(state => ({ data: state.data, loading: true }))
      otNetworkTest.current
        .testConnectivity()
        .then(results => {
          if (otNetworkTest.current) {
            setConnectivityTest(state => ({ loading: false, data: results }))
          }
          if (otNetworkTest.current) {
            otNetworkTest.current
              .testQuality(function updateCallback(stats) {
                //console.log('intermediate testQuality stats', stats)
                //no requirement with this data but need to get the whole data set
              })
              .then(results => {
                // This function is called when the quality test is completed.
                if (otNetworkTest.current) {
                  setQualityTest(state => ({
                    data: results,
                    loading: false,
                  }))
                }
                setIsRunning(false)
              })
              .catch(error => {
                setIsRunning(false)
              })
          }
        })
        .catch(function(error) {
          setIsRunning(false)
        })
    }
  }, [otNetworkTest, runTest, isRunning])

  /**
   * Stop the network testConnectivity test. Pleae check limitation
   * https://github.com/opentok/opentok-network-test-js#otnetworkteststop
   */
  const stopNetworkTest = useCallback(() => {
    if (otNetworkTest.current && !runTest) {
      otNetworkTest.current.stop()
      otNetworkTest.current = null
      setRunTest(true)
      setIsRunning(false)
      setConnectivityTest({ data: null, loading: true })
      setQualityTest({ data: null, loading: true })
    }
  }, [otNetworkTest, runTest])

  useEffect(() => {
    if (sessionId && token) {
      initNetworkTest()
    }
  }, [initNetworkTest, sessionId, token])

  return {
    connectivityTest,
    qualityTest,
    initNetworkTest,
    runNetworkTest,
    stopNetworkTest,
    runTest,
    setRunTest,
    isRunning,
  }
}
