import React from 'react'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'

import Button from 'components/Button/Button'

function ReadAt({
  t,
  isSelf,
  formatReadAt,
  markAsRead,
  markingAsRead,
  type,
  from,
  canMarkAsRead,
  senderUser,
}) {
  let content = null
  if (formatReadAt && (type !== 'DARVA' || (type === 'DARVA' && senderUser == null))) {
    content = `${t('messaging.message.readAt')} ${formatReadAt}`
  } else if (!isSelf && !formatReadAt && canMarkAsRead) {
    content = (
      <Button className="btn btn-link p-0" loading={markingAsRead} onClick={() => markAsRead()}>
        {t('messaging.message.markAsRead')}
      </Button>
    )
  } else if (type === 'SH') {
    content = `${t('messaging.message.notRead')}`
  }

  return <div className="d-flex justify-content-end info">{content}</div>
}

export default compose(withTranslation())(ReadAt)
