import React from 'react'
import PropTypes from 'prop-types'

export default class SelectVAT extends React.PureComponent {
  onChange = e => {
    const vat = parseFloat(e.target.value)
    this.props.onChange({ target: { name: 'vat', value: vat } })
  }

  render() {
    const { vat, style, selectWidth, disabled } = this.props

    return (
      <span style={style}>
        <select
          name="vat"
          className="form-control d-inline mr-1 text-right"
          value={vat}
          onChange={this.onChange}
          style={{ width: selectWidth }}
          disabled={disabled}
        >
          <option value="0">0</option>
          <option value="5.5">5,5</option>
          <option value="10">10</option>
          <option value="20">20</option>
        </select>
        %
      </span>
    )
  }
}

SelectVAT.defaultProps = {
  disabled: false,
  selectWidth: '63px',
  vat: 0,
  style: {},
  onChange: () => {},
}

SelectVAT.propTypes = {
  disabled: PropTypes.bool,
  selectWidth: PropTypes.string,
  onChange: PropTypes.func,
  vat: PropTypes.number,
  style: PropTypes.object,
}
