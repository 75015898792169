import React, { Fragment, useState } from 'react'
import { inject, observer } from 'mobx-react'
import classNames from 'classnames'
import DropDown from 'pages/SelfCare/Estimation/Dropdown'
import CurrentRoom from 'pages/SelfCare/Estimation/CurrentRoom'
import Furnitures from 'pages/SelfCare/Estimation/Furnitures'
import RelatedCosts from 'pages/SelfCare/Estimation/RelatedCosts'
import OverViewModal from 'pages/SelfCare/Estimation/OverViewModal'
import ButtonWithConfirmation from 'components/ButtonWithConfirmation/ButtonWithConfirmation'
import Button from 'components/Button/Button'
import { NO_DAMAGE } from 'stores/SelfCare/domain/SelfCareStore'

const SelfCareEstimation = ({
  SelfCareStore: { wan },
  SelCareEstimationCtrl: { isValid, loading, back },
  PropertyEmbellishmentCtrl: { setProperty, listRooms, selectedRoom },
  SelCareWhereCtrl: { damageRoomTypes },
}) => {
  const [showNewForm, setShowNewForm] = useState(false)
  const [showOverView, setShowOverView] = useState(false)

  return (
    <Fragment>
      <DropDown title="Immobilier" showDropdown={true}>
        {damageRoomTypes.includes(NO_DAMAGE) && (
          <div className="row no-gutters d-flex justify-content-between align-items-center">
            <div className="col-12">Vous avez déclaré : "Aucun dommage subi"</div>
          </div>
        )}

        {!damageRoomTypes.includes(NO_DAMAGE) && (
          <Fragment>
            <div className="row no-gutters d-flex justify-content-between align-items-center">
              <div className="col-12">
                <ul className="nav nav-tabs">
                  {listRooms.map(({ cde, selfCareLabel, id }) => (
                    <li className="nav-item" key={`room-${cde}`}>
                      <span
                        className={classNames('nav-link clickable py-2', {
                          active: selectedRoom === id,
                        })}
                      >
                        <span onClick={() => setProperty('selectedRoom', id)}>{selfCareLabel}</span>
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <CurrentRoom showNewForm={showNewForm} setShowNewForm={setShowNewForm} />
          </Fragment>
        )}
      </DropDown>

      <DropDown title="Mobilier" showDropdown={true}>
        <Furnitures />
      </DropDown>

      <DropDown title="Détériorations supplémentaires" showDropdown={true}>
        <RelatedCosts />
      </DropDown>

      <div className="row no-gutters mt-4 d-flex justify-content-between">
        {isValid && (
          <ButtonWithConfirmation
            className="btn btn-secondary"
            text="Etape précédente"
            onClick={() => back(wan)}
            disabled={loading}
            loading={loading}
            confirmText="Si vous revenez en arrière, vous perdrez tous les postes de préjudices déjà saisis"
          />
        )}

        {!isValid && (
          <Button
            className="btn btn-secondary"
            onClick={() => back(wan)}
            disabled={loading}
            loading={loading}
          >
            Etape précédente
          </Button>
        )}

        <Button
          className="btn btn-primary"
          onClick={() => setShowOverView(true)}
          disabled={!isValid || loading}
          loading={loading}
        >
          Etape suivante
        </Button>
      </div>
      <OverViewModal showModal={showOverView} setShowModal={setShowOverView} />
    </Fragment>
  )
}

export default inject(
  'SelfCareStore',
  'SelCareEstimationCtrl',
  'SelCareWhereCtrl',
  'PropertyEmbellishmentCtrl',
)(observer(SelfCareEstimation))
