import { observable, computed, action, decorate, runInAction, reaction } from 'mobx'
import { path, type, isNil } from 'ramda'
import i18next from 'i18next'

import {
  arrayToTree,
  runTree,
  runEvaluation,
  shouldShow,
  evaluationRule,
  inputType,
  dataForEntity,
  dataForValue,
  dataForEmbeddedCollection,
  dataForEmbeddedCollectionValue,
} from './formHelper'
import MissionStore from 'stores/Mission/domain/MissionStore'
import UserStore from 'stores/Common/domain/UserStore'
import AlertCtrl from 'stores/Common/view/AlertCtrl'
import { uid } from 'utils'

class Form {
  id = uid(8)
  name = ''
  inputs = []
  inputsWithRules = []
  inputsWithValidationRules = []
  inputsTopParent = []
  saving = false
  saved = false
  isCreationForm = false
  tree = []
  groups = [
    {
      groupId: null,
      group: '',
      groupLabel: '',
      open: true,
    },
  ]
  inputsWithoutRules = []
  rulesDisposer = null
  indexation = []
  hasChanged = false
  validateRequired = false

  constructor({ form, data, embeddedCollection = false, isCreationForm = false }) {
    this.id = path(['id'], data) || uid(8)
    this.name = form.name
    this.isCreationForm = isCreationForm
    this.validateRequired = path(['extraData', 'validateRequired'], form) || false

    this.indexation = {
      name: form.name,
      fields: form.fields.map(field => ({
        name: field.name,
        path: field.path,
        label: field.label,
        groupId: field.groupId,
        formCtrl: form.name,
      })),
    }

    // FORM FIELDS
    form.fields.forEach(inputData => {
      // SETTING DATA
      if (embeddedCollection && inputData.type !== 'embeddedCollection') {
        // ADDING EXTRA DATA FOR IRSI INVOLVED PARTY
        if (
          this.name === 'irsiInvolvedPartys' &&
          inputData.path === 'irsi.irsiInvolvedPartys.statusKey'
        ) {
          inputData.extraData = { involvedPartyId: data.involvedPartyId }
        }
        dataForEmbeddedCollectionValue(inputData, data)
      } else if (type(data) === 'Object') {
        if (inputData.type === 'entity') {
          dataForEntity(inputData, data, form)
        } else if (inputData.type === 'embeddedCollection') {
          inputData = dataForEmbeddedCollection(inputData, data)
        } else {
          dataForValue(inputData, data)
        }
      }

      // FIND PARENT AND SET IF INPUT SHOULD BE SHOWN
      inputData.show = inputData.parent ? shouldShow(form.fields, inputData) : true

      // FIND EVALUATION RULE AND SET IF INPUT SHOULD BE SHOWN
      inputData.show = inputData.evaluationRule
        ? evaluationRule(inputData.evaluationRule, this.asJson)
        : inputData.show

      const field = inputType(inputData, this.validateRequired)

      if (field !== null) {
        this.inputs.push(field)
        if (field.parent) this.inputsWithRules.push(field)
        else this.inputsWithoutRules.push(field)
        if (field.evaluationRule) this.inputsWithValidationRules.push(field)
      }
    })

    this.inputs.forEach(input => {
      if (input.groupId && this.groups.every(({ groupId }) => groupId !== input.groupId)) {
        this.groups.push({
          groupId: input.groupId,
          group: input.group,
          groupLabel: input.groupLabel,
          open: input.groupOpen,
        })
      }
    })

    // FIND TOP PARENT
    this.inputsWithRules.forEach(inputWithRules => {
      const inputTopParent = this.inputsWithoutRules.find(
        inputWithoutRules => inputWithoutRules.name === inputWithRules.parent.field,
      )
      if (inputTopParent && !this.inputsTopParent.some(({ name }) => inputTopParent.name === name))
        this.inputsTopParent.push(inputTopParent)
    })

    this.tree = arrayToTree([...this.inputsTopParent, ...this.inputsWithRules])
    runTree(this.tree)
    runEvaluation(this.inputsWithValidationRules, this.asJson)

    reaction(
      () =>
        this.inputs
          .filter(({ children }) => Array.isArray(children) && children.length)
          .map(({ value }) => value),
      () => {
        runTree(this.tree)
      },
    )

    reaction(
      () => {
        return this.inputs.map(({ value }) => value)
      },
      () => {
        runEvaluation(this.inputsWithValidationRules, this.asJson)
      },
    )
  }

  get asJson() {
    return this.inputs.reduce((data, currentInput) => {
      let value = ''

      if (currentInput.type === 'embeddedCollection') {
        value = currentInput.asJson
      } else if (isNil(currentInput.value) || currentInput.value.length === 0) {
        value = null
      } else if (currentInput.type === 'number') {
        value = parseFloat(currentInput.value)
      } else if (currentInput.type === 'datetime') {
        value = currentInput.ISOString
      } else {
        value = currentInput.value
      }

      data[currentInput.name] = value

      return data
    }, {})
  }

  get asJsonWithKey() {
    const asJsonWithKey = this.inputs.reduce((data, input) => {
      if (input.type === 'embeddedCollection') {
        data[input.name] = input.asJson
        return data
      }

      const path = input.path ? input.path.split('.').pop() : ''
      if (path === 'key') {
        let value = null
        if (!isNil(input.value) && type(input.value) === 'String' && input.value.length > 0) {
          value = input.value
        }

        data[input.name + 'Key'] = value
        return data
      }

      let value = ''
      if (input.value === null || input.value.length === 0) {
        value = null
      } else if (input.type === 'number') {
        value = parseFloat(input.value)
      } else if (input.type === 'datetime') {
        value = input.ISOString
      } else {
        value = input.value
      }
      data[input.name] = value
      return data
    }, {})

    return asJsonWithKey
  }

  get changed() {
    return this.inputs.some(input => input.changed) || this.hasChanged
  }

  get withAccordion() {
    return this.groups && this.groups.length > 1
  }

  get isValid() {
    if (!this.validateRequired) return true

    let isValid = true

    this.inputs.forEach(input => {
      if (!input.isValid) isValid = false
    })

    return isValid
  }

  getInput(name) {
    return this.inputs.find(field => field.name === name)
  }

  isGroupOpen = id => {
    const group = this.groups.find(({ groupId }) => groupId === id)
    return group ? group.open : false
  }

  isGroupNotEmpty = id => {
    return this.inputs.some(({ show, groupId }) => groupId === id && show)
  }

  resetForm = () => {
    this.saved = false
    this.hasChanged = false
    this.inputs.forEach(input => {
      input.setOriginalData()
      //input.resetMissionData()
    })
  }

  afterSave() {
    this.inputs.forEach(input => {
      if (input.type === 'embeddedCollection') input.afterSave()
      else if (input.type !== 'table') input.setProperty('originalValue', input.value)
    })
    this.hasChanged = false
  }

  save = async service => {
    this.saving = true

    try {
      const res = await service(this.asJson)
      if (!this.isCreationForm) {
        AlertCtrl.alert('success', i18next.t('common.saveSuccess'))
      }
      runInAction(() => {
        this.afterSave()
        this.saved = true
        if (!UserStore.isClaimManager && MissionStore.appointmentDateRule && !this.isCreationForm) {
          MissionStore.updateStatus('CAAD', 'CAAF')
        }
      })
      return res
    } catch (err) {
      AlertCtrl.alert('danger', i18next.t('common.saveFailed'))
      if (!Array.isArray(err)) {
        console.warn(err)
        throw err
      }

      runInAction(() => {
        err.forEach(error => {
          const input = this.inputs.find(input => input.name === error.key)
          if (input) input.setProperty('errors', [error.value])
        })
      })
      throw err
    } finally {
      runInAction(() => (this.saving = false))
    }
  }

  setProperty = (key, value) => {
    this[key] = value
  }

  openCloseGroup = id => {
    const group = this.groups.find(({ groupId }) => id === groupId)
    if (group) group.open = !group.open
  }

  changeChoicesWhenLinked = nameLinkedTo => {
    this.inputs.forEach(inputData => {
      if (inputData.linked && inputData.linked.field === nameLinkedTo) {
        const linkedTo = this.inputs.find(field => field.name === inputData.linked.field).value
        inputData.value = ''
        inputData.choices =
          inputData.allChoices[linkedTo] &&
          inputData.allChoices[linkedTo].reduce((choices, cur) => {
            choices[cur.value] = cur.id
            return choices
          }, {})
      }
    })
  }

  getInput = name => {
    const inputData = this.inputs.find(inputData => inputData.name === name)
    return inputData
  }
}

const DecoratedForm = decorate(Form, {
  name: observable,
  inputs: observable,
  inputsWithRules: observable,
  inputsWithValidationRules: observable,
  inputsTopParent: observable,
  saving: observable,
  saved: observable,
  tree: observable,
  groups: observable,
  hasChanged: observable,

  asJson: computed,
  asJsonWithKey: computed,
  changed: computed,
  withAccordion: computed,
  isValid: computed,

  resetForm: action,
  save: action,
  changeChoicesWhenLinked: action,
  setProperty: action,
  openCloseGroup: action,
  afterSave: action,
})

export default DecoratedForm
