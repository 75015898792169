import { action, computed, decorate, observable, runInAction } from 'mobx'
import SelfCareStore from 'stores/SelfCare/domain/SelfCareStore'
import CartStore from 'stores/Mission/domain/CartStore'
import AlertCtrl from 'stores/Common/view/AlertCtrl'
import { saveSelfCareForm, backSelfCare } from 'services/selfCare'

class SelCareEstimationCtrl {
  loading = false

  get isValid() {
    const { packages, furnitures, relatedCostPackages } = CartStore

    return packages.length > 0 || furnitures.length > 0 || relatedCostPackages.length > 0
  }

  loadData = claim => {}

  setProperty = (key, value) => {
    this[key] = value
  }

  save = async wan => {
    if (!this.isValid) return

    this.loading = true
    let attributes = {}

    try {
      await CartStore.save()
      const res = await saveSelfCareForm(wan, attributes, 'selfCareEstimation')
      SelfCareStore.updateClaim(res)
    } catch (err) {
      console.error(err)
      AlertCtrl.alert('danger', 'selfCare.saveError')
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  back = async wan => {
    this.loading = true
    try {
      const res = await backSelfCare(wan, 'selfCareEstimation')
      SelfCareStore.updateClaim(res)
    } catch (err) {
      console.error(err)
      AlertCtrl.alert('danger', 'selfCare.saveError')
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }
}

const DecoratedSelCareEstimationCtrl = decorate(SelCareEstimationCtrl, {
  loading: observable,

  isValid: computed,

  loadData: action,
  setProperty: action,
  save: action,
  back: action,
})

export default new DecoratedSelCareEstimationCtrl()
