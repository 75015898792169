import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import classNames from 'classnames'

import MeteoSteps from 'components/Meteo/MeteoSteps'
import Button from 'components/Button/Button'

const Meteo = ({ hasContract, retrieveMeteo }) => {
  const [showMeteoData, setShowMeteoData] = useState([])
  const [retreiving, setRetreiving] = useState(false)
  const [retreived, setRetreived] = useState(false)
  const [hour, setHour] = useState(0)

  const { t } = useTranslation()

  const retrieveMeteoData = async () => {
    setRetreiving(true)
    const res = await retrieveMeteo()
    setShowMeteoData(res)
    setRetreiving(false)
    setRetreived(true)
  }

  if (!hasContract('METEO')) return null

  return (
    <Fragment>
      <div className="row">
        <div className="col-4 col-sm-3 col-md-3 col-lg-3"></div>
        <div className="col">
          {!retreived && (
            <Button
              className="btn btn-primary"
              disabled={retreiving}
              loading={retreiving}
              onClick={retrieveMeteoData.bind(this)}
            >
              <i className="fa fa-cloud"></i>&nbsp;{t('mission.meteo.infoMeteo')}
            </Button>
          )}
        </div>
      </div>
      {showMeteoData.length > 0 && (
        <Fragment>
          <div className={classNames('collapse', { show: showMeteoData.length > 0 })}>
            <div className="row">
              <div className="font-weight-bold">
                {t('mission.meteo.infoMeteo')} {t('common.at')} {showMeteoData[hour].time}
              </div>
            </div>
            <div className="row">
              <div className="col-20">
                <MeteoSteps
                  setHour={setHour}
                  meteo={showMeteoData.length > 0}
                  meteoData={showMeteoData}
                />
              </div>
              <br />
            </div>
            <div className="row">
              <div className="col-4 col-sm-3 col-md-3 col-lg-3 pr-0">
                {t('mission.meteo.airTemperature')}
              </div>
              <div className="col-8 col-sm-9 col-md-9 col-lg-9 pr-0">
                {showMeteoData[hour].airTemperature} °C
              </div>
            </div>
            <div className="row">
              <div className="col-4 col-sm-3 col-md-3 col-lg-3 pr-0">{t('mission.meteo.gust')}</div>
              <div className="col-8 col-sm-9 col-md-9 col-lg-9 pr-0">
                {showMeteoData[hour].gust} km/h
              </div>
            </div>
            <div className="row">
              <div className="col-4 col-sm-3 col-md-3 col-lg-3 pr-0">
                {t('mission.meteo.windSpeed')}
              </div>
              <div className="col-8 col-sm-9 col-md-9 col-lg-9 pr-0">
                {showMeteoData[hour].windSpeed} km/h
              </div>
            </div>
            <div className="row">
              <div className="col-4 col-sm-3 col-md-3 col-lg-3 pr-0">
                {t('mission.meteo.windDirection')}
              </div>
              <div className="col-8 col-sm-9 col-md-9 col-lg-9 pr-0">
                {showMeteoData[hour].windDirection}° (0° indique que le vent vient du Nord)
              </div>
            </div>
            <div className="row">
              <div className="col-4 col-sm-3 col-md-3 col-lg-3 pr-0">
                {t('mission.meteo.precipitation')}
              </div>
              <div className="col-8 col-sm-9 col-md-9 col-lg-9 pr-0">
                {showMeteoData[hour].precipitation} mm
              </div>
            </div>
            <div className="row">
              <div className="col-4 col-sm-3 col-md-3 col-lg-3 pr-0">
                {t('mission.meteo.cloudCover')}
              </div>
              <div className="col-8 col-sm-9 col-md-9 col-lg-9 pr-0">
                {showMeteoData[hour].cloudCover} %
              </div>
            </div>
            <div className="row">
              <div className="col-4 col-sm-3 col-md-3 col-lg-3 pr-0">
                {t('mission.meteo.visibility')}
              </div>
              <div className="col-8 col-sm-9 col-md-9 col-lg-9 pr-0">
                {showMeteoData[hour].visibility} km
              </div>
            </div>
            <div className="row">
              <div className="col-4 col-sm-3 col-md-3 col-lg-3 pr-0">
                {t('mission.meteo.pressure')}
              </div>
              <div className="col-8 col-sm-9 col-md-9 col-lg-9 pr-0">
                {showMeteoData[hour].pressure} hPa
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  )
}

export default observer(Meteo)
