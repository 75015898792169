import request from './httpRequest'

export const startNewSession = async (wan, phoneNumber, email, wanType = 'cfa') => {
  const res = await request({
    method: 'POST',
    url: `/conference/v1/${wan}/new-session-${wanType}`,
    data: {
      data: {
        type: 'conference',
        attributes: {
          phoneNumber,
          email,
        },
      },
    },
  })
  console.log(res)
  return res.data.attributes
}

export const getNetworkToken = async () => {
  try {
    const res = await request({
      method: 'get',
      url: `/get-network-session-data`,
    })
    return res.data ? res.data.attributes : null
  } catch (err) {
    return Promise.reject(err)
  }
}
