import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

const LinkExpired = () => {
  const { t } = useTranslation()
  return (
    <Fragment>
      <div className="d-flex flex-column">
        <h1 className="text-center text-primary">{t('linkExpired.title')}</h1>
        <h4 className="text-center text-primary mt-4">{t('linkExpired.subTitle')}</h4>
      </div>
    </Fragment>
  )
}

export default LinkExpired
