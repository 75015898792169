import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const Dropdown = ({ title, children, showDropdown }) => {
  const [show, setShow] = useState(showDropdown)

  return (
    <React.Fragment>
      <div className="row no-gutters d-flex justify-content-between align-items-center mt-4">
        <div className="col-12">
          <i
            className={classNames('clickable fa p-1', {
              'fa-angle-down': show,
              'fa-angle-up': !show,
            })}
            onClick={() => setShow(!show)}
          />
          <span
            className="no-user-select font-weight-bold mx-3 clickable"
            onClick={() => setShow(!show)}
          >
            {title}
          </span>
        </div>
      </div>

      <hr className="my-2" />

      {show && children}
    </React.Fragment>
  )
}

Dropdown.defaultProps = {
  total: 0,
  showDropdown: false,
}

Dropdown.propTypes = {
  title: PropTypes.string.isRequired,
  showDropdown: PropTypes.bool,
}

export default Dropdown
