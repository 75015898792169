import { action, computed, decorate, observable, runInAction } from 'mobx'
import { path } from 'ramda'
import SelfCareStore, { ORIGIN_EVENT_THIRD_PARTY } from 'stores/SelfCare/domain/SelfCareStore'
import AlertCtrl from 'stores/Common/view/AlertCtrl'
import { saveSelfCareForm, backSelfCare } from 'services/selfCare'
import { savePartyInvolved } from 'services/partyInvolved'
import PartyInvolvedStore from 'stores/Common/domain/PartyInvolvedStore'

class SelCareWhoCtrl {
  loading = false
  originEvent = null
  thirdPartyCivility = 'M.'
  thirdPartyFirstName = ''
  thirdPartyLastName = ''
  thirdPartyId = null

  get isValid() {
    return this.originEvent
  }

  loadData = claim => {
    this.originEvent = path(['causesAndCircumstances', 'originEvent'], claim)
    this.thirdPartyId = path(['causesAndCircumstances', 'responsibleThirdPartyId'], claim)
    if (this.thirdPartyId) {
      const thirdParty = PartyInvolvedStore.findIpFromId(this.thirdPartyId)
      if (!thirdParty) return
      this.thirdPartyCivility = thirdParty.civility
      this.thirdPartyFirstName = thirdParty.firstName
      this.thirdPartyLastName = thirdParty.lastName
    }
  }

  setProperty = (key, value) => {
    this[key] = value
  }

  savePartyInvolved = async wan => {
    if (this.originEvent !== ORIGIN_EVENT_THIRD_PARTY) return null
    if (!this.thirdPartyFirstName && !this.thirdPartyLastName) return null

    const url = `/claimFiles/v1/claimFileManager/${wan}/involvedParties`
    const partyInvolvedData = {
      firstName: this.thirdPartyFirstName,
      lastName: this.thirdPartyLastName,
      civility: this.thirdPartyCivility,
      type: 'personal',
      country: 'FR',
      thirdPartyRole: 'THP06', // TIERS
      involvedPartyResponsability: 'THIRD_PARTY_RESPONSIBLE', // TIERS
      addressLine1: 'INCONNU',
      city: '-',
      zipCode: '99999',
    }

    const res = await savePartyInvolved({
      method: this.thirdPartyId ? 'patch' : 'post',
      url: this.thirdPartyId ? `${url}/${this.thirdPartyId}` : url,
      partyInvolvedData,
    })

    runInAction(() => {
      this.thirdPartyId = res.data.id
    })

    return this.thirdPartyId
  }

  save = async wan => {
    if (!this.isValid) return

    this.loading = true

    try {
      const thirdPartyId = await this.savePartyInvolved(wan)
      let attributes = {
        originEvent: this.originEvent,
        thirdPartyIsResponsible: this.originEvent === ORIGIN_EVENT_THIRD_PARTY,
        responsibleThirdParty: thirdPartyId,
      }
      const res = await saveSelfCareForm(wan, attributes, 'selfCareWho')
      SelfCareStore.updateClaim(res)
    } catch (err) {
      console.error(err)
      AlertCtrl.alert('danger', 'selfCare.saveError')
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  back = async wan => {
    this.loading = true
    try {
      const res = await backSelfCare(wan, 'selfCareWho')
      SelfCareStore.updateClaim(res)
    } catch (err) {
      console.error(err)
      AlertCtrl.alert('danger', 'selfCare.saveError')
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }
}

const DecoratedSelCareWhoCtrl = decorate(SelCareWhoCtrl, {
  loading: observable,
  originEvent: observable,
  thirdPartyCivility: observable,
  thirdPartyFirstName: observable,
  thirdPartyLastName: observable,
  thirdPartyId: observable,

  isValid: computed,

  loadData: action,
  setProperty: action,
  savePartyInvolved: action,
  save: action,
  back: action,
})

export default new DecoratedSelCareWhoCtrl()
