import React, { Fragment, useState } from 'react'
import { inject, observer } from 'mobx-react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import Label from 'components/Label/Label'
import DatePicker from 'components/Forms/DatePicker'
import ImgRoom from 'pages/EasyEstimation/components/ImgRoom'
import Button from 'components/Button/Button'
import { NO_DAMAGE } from 'stores/SelfCare/domain/SelfCareStore'
import { getDay, setHours, setMinutes } from 'date-fns'
import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'

const SelfCareWhere = ({
  SelfCareStore: { wan, RISK_TYPES, ROOM_TYPES, ROOMS_LIST },
  SelCareWhereCtrl: {
    dateOfLoss,
    riskType,
    damageRoomTypes,
    damageRoomTypesWithoutNoDamage,
    damageRooms,
    setProperty,
    setRiskType,
    setDamageRoomTypes,
    setDamageRooms,
    isValid,
    loading,
    save,
    appointmentDate,
    isValidWithAppointmentDate,
  },
}) => {
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)
  const [grabbing, setGrabbing] = useState(false)

  const today = new Date()
  let tomorrow = new Date()
  tomorrow.setDate(today.getDate() + 1)
  tomorrow.setHours(9)
  tomorrow.setMinutes(0)

  const minTime = setMinutes(setHours(new Date(), 9), 0)
  const maxTime = setMinutes(setHours(new Date(), 17), 0)

  const isWeekday = date => {
    const day = getDay(date)
    return day !== 0 && day !== 6
  }

  const onSave = () => {
    if (damageRoomTypes.includes(NO_DAMAGE)) {
      setShowModal(true)
      return
    }

    save(wan)
  }

  return (
    <Fragment>
      <div className="row no-gutters">
        <div className="form-group col-12">
          <Label required={true}>Date survenance du sinistre</Label>
          <DatePicker
            className="form-control"
            name="dateOfLoss"
            dateFormat="dd/MM/y"
            onChange={value => setProperty('dateOfLoss', value)}
            selected={dateOfLoss}
            maxDate={new Date()}
            placeholder="jj/mm/aaa"
            withPortal={true}
            showMonthDropdown={false}
            showYearDropdown={false}
            onFocus={e => (e.target.readOnly = true)}
            onBlur={e => (e.target.readOnly = false)}
          />
        </div>
      </div>
      <div className="row no-gutters">
        <div className="form-group col-12 mt-4">
          <Label required={true} className="d-block">
            Type de Batiment
          </Label>
          <div className="btn-group pl-0 d-flex">
            {RISK_TYPES.map(rt => (
              <button
                key={`button-riskType-${rt.id}`}
                className={classNames('btn flex-basis-1-3', {
                  'btn-primary': riskType === rt.id,
                  'btn-outline-primary': riskType !== rt.id,
                })}
                onClick={() => setRiskType(rt.id)}
              >
                {rt.value}
              </button>
            ))}
          </div>
        </div>
      </div>

      <div className="row no-gutters">
        <div className="form-group col-12 mt-4">
          <Label required={true} className="d-block">
            Localisation des dommages
          </Label>
          <div className="btn-group pl-0 d-flex">
            {ROOM_TYPES.map(room => (
              <button
                key={`button-room-${room}`}
                className={classNames('btn flex-basis-1-3', {
                  'btn-primary': damageRoomTypes.includes(room),
                  'btn-outline-primary': !damageRoomTypes.includes(room),
                })}
                onClick={() => setDamageRoomTypes(room)}
              >
                {t(`roomTypes.${room}`)}
              </button>
            ))}
          </div>
        </div>
      </div>

      {riskType &&
        damageRoomTypesWithoutNoDamage.length > 0 &&
        damageRoomTypesWithoutNoDamage.map(damageRoomType => (
          <div className="row no-gutters" key={`damage-room-type-${damageRoomType}`}>
            <div className="form-group col-12 mt-4">
              <Label className="d-block"> {t(`roomTypes.${damageRoomType}`)} :</Label>
              <div className="d-flex flex-row flex-wrap p-3">
                {ROOMS_LIST[riskType].map(room => {
                  if (room.location !== damageRoomType) return null
                  return (
                    <div
                      key={`room-${room.cde}`}
                      className={classNames(
                        'img-box d-flex flex-column align-items-center border rounded mr-2 mb-2 text-center overflow-auto pt-3 clickable',
                        {
                          'border-primary': damageRooms.includes(room.cde),
                          'text-primary': damageRooms.includes(room.cde),
                          'text-muted': !damageRooms.includes(room.cde),
                        },
                      )}
                      onClick={() => setDamageRooms(room.cde)}
                    >
                      <ImgRoom selected={damageRooms.includes(room.cde)} imgId={room.cde} />
                      <span>{room.selfCareLabel}</span>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        ))}

      <div className="row no-gutters mt-4 d-flex justify-content-end">
        <ModalWithBackdrop
          show={showModal}
          size="modal-lg"
          close={() => setShowModal(false)}
          draggable
          setGrabbing={setGrabbing}
        >
          <div className={classNames('modal-header', { grabbable: !grabbing, grabbing: grabbing })}>
            <h5 className="modal-title">{t('selfCare.noDamage.title')}</h5>
            <button type="button" className="close" onClick={() => setShowModal(false)}>
              <span>&times;</span>
            </button>
          </div>

          <div className="modal-body">
            <div className="row no-gutters">
              <div className="col-12">{t('selfCare.noDamage.contactTitle')}</div>
            </div>

            <div className="row no-gutters mt-2">
              <div className="form-group col-12">
                <Label required={true}>{t('selfCare.noDamage.contactTitle2')}</Label>
                <DatePicker
                  className="form-control"
                  name="appointmentDate"
                  dateFormat="dd/MM/y H:mm"
                  onChange={value => setProperty('appointmentDate', value)}
                  selected={appointmentDate}
                  minDate={tomorrow}
                  placeholder="jj/mm/aaa"
                  withPortal={true}
                  portalId="body"
                  showMonthDropdown={false}
                  showYearDropdown={false}
                  showTimeSelect={true}
                  timeIntervals={30}
                  minTime={minTime}
                  maxTime={maxTime}
                  filterDate={isWeekday}
                  required={true}
                  onFocus={e => (e.target.readOnly = true)}
                  onBlur={e => (e.target.readOnly = false)}
                />
              </div>
            </div>
          </div>

          <div className="modal-footer pb-2 pt-2 mt-2 d-flex justify-content-between">
            <Button
              className="btn btn-secondary"
              onClick={() => setShowModal(false)}
              disabled={loading}
              loading={loading}
            >
              {t('common.dismiss')}
            </Button>

            <Button
              className="btn btn-warning"
              onClick={() => save(wan)}
              disabled={!isValidWithAppointmentDate || loading}
              loading={loading}
            >
              {t('common.confirm')}
            </Button>
          </div>
        </ModalWithBackdrop>

        <Button
          className="btn btn-primary"
          onClick={onSave}
          disabled={!isValid || loading}
          loading={loading}
        >
          Etape suivante
        </Button>
      </div>
    </Fragment>
  )
}

export default inject('SelfCareStore', 'SelCareWhereCtrl')(observer(SelfCareWhere))
