import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { noop } from 'utils'
import { euro } from 'utils/currency'

const Dropdown = ({
  title,
  children,
  showAdd,
  addAction,
  total,
  showDropdown,
  priceType,
  showLabel,
}) => {
  const [show, setShow] = useState(showDropdown)

  return (
    <React.Fragment>
      <div className="row no-gutters d-flex justify-content-between align-items-center">
        <div className="col-5">
          <i
            className={classNames('clickable fa p-1', {
              'fa-angle-down': show,
              'fa-angle-up': !show,
            })}
            onClick={() => setShow(!show)}
          />
          <span
            className="no-user-select font-weight-bold mx-3 clickable"
            onClick={() => setShow(!show)}
          >
            {title}
          </span>
          {showAdd && (
            <button className="btn btn-outline-primary rounded-circle" onClick={addAction}>
              <i className="fa fa-plus" />
            </button>
          )}
        </div>

        {showLabel && <div className="font-weight-bold">Prix entreprise</div>}
        {showLabel && <div className="font-weight-bold ml-4">Gré-à-Gré</div>}
        <div className="font-weight-bold">
          {priceType} {euro(total)}
        </div>
      </div>

      <hr className="my-2" />

      {show && children}
    </React.Fragment>
  )
}

Dropdown.defaultProps = {
  showAdd: false,
  addAction: noop,
  changeAllPackageTo: noop,
  total: 0,
  showDropdown: false,
  priceType: '',
  showLabel: false,
}

Dropdown.propTypes = {
  title: PropTypes.string.isRequired,
  addAction: PropTypes.func,
  showAdd: PropTypes.bool,
  priceType: PropTypes.string,
  total: PropTypes.number,
  showDropdown: PropTypes.bool,
  showLabel: PropTypes.bool,
}

export default Dropdown
