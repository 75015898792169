import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { path } from 'ramda'
import { compose } from 'recompose'
import classNames from 'classnames'

import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'
import Label from 'components/Label/Label'
import Input from 'components/Forms/Input'
import DatePicker from 'components/Forms/DatePicker'
import Select from 'components/ResponsiveSelect/ResponsiveSelect'
import Address from 'components/Address/Address'
import TextArea from 'components/Forms/TextArea'
import Button from 'components/Button/Button'
import NavigationButtons from './NavigationButtons'
import NavigationDate from './NavigationDate'
import SelectExpertInput from './SelectExpertInput'
import { concatAddress } from 'utils'
import { MISSION_TYPE_ID, MISSION_WAIT_TYPE_ID } from 'utils/constants'
import AppointmentRecurrence from 'pages/Calendar/AppointmentRecurrence'
function AppointmentModal({
  t,
  CommonStore: { appointmentTypeForSelectInput },
  AppointmentCtrl: {
    showModal,
    currentAppointment,
    currentExpert,
    hideModal: closeModal,
    saveAppointment,
    deleteAppointment,
  },
  UserStore: { isExpert },
  AlertCtrl: { alert },
}) {
  const [grabbing, setGrabbing] = useState(false)
  const history = useHistory()

  if (!currentAppointment && !currentExpert) return null
  const {
    id,
    title,
    start,
    end,
    type,
    address,
    comment,
    setProperty,
    loading,
    isValid,
    changeStartDate,
    loadingValidation,
    insurerClaimId,
    getWans,
    wan,
    wans,
    wansForSelect,
    loadingWans,
    cfiWan,
    modifyReccurenceOptionIsSatify,
    appointmentRecurrentId,
    setAppointmentType,
    isRecurrent,
  } = currentAppointment

  const modifyRecurenceOptions = [
    { value: 'one', label: t('appointmentReccurence.modifyOnly') },
    { value: 'all', label: t('appointmentReccurence.modifyAll') },
  ]

  const onChange = e => setProperty(e.target.name, e.target.value)

  const onChangeAddress = address => setProperty('address', address)

  const onWanSelected = e => {
    setProperty('wan', e.target.value)
    const elem = wans.find(item => e.target.value === item.wan)
    const addressWan = path(['cfi', 'claimInformation', 'addressOfLoss'], elem)
    if (addressWan) setProperty('address', addressWan)
  }

  const onChangeStartDate = value => changeStartDate(value)

  const goTo = async () => {
    if (wan) {
      closeModal()
      history.push(`/${isExpert ? 'mission' : 'claim'}/${isExpert ? wan : cfiWan}/information`)
    } else {
      alert('danger', t('calendar.appointment.notValidWan'))
    }
  }

  const onChangeAppointmentType = appointmentType => {
    let title = ''
    appointmentTypeForSelectInput.forEach(select => {
      if (select.value === appointmentType) title = select.label
    })
    setAppointmentType(appointmentType)
    setProperty('title', title)
  }

  return (
    <ModalWithBackdrop
      size="modal-lg"
      show={showModal}
      close={closeModal}
      draggable
      scrollable
      limitHeight
      backdropOpacity={0.1}
      setGrabbing={setGrabbing}
    >
      <div
        className={classNames('modal-header d-block', { grabbable: !grabbing, grabbing: grabbing })}
      >
        <div
          className={classNames('d-flex justify-content-between', {
            grabbable: !grabbing,
            grabbing: grabbing,
          })}
        >
          <h5>{t(`calendar.appointment.${id ? 'edit' : 'new'}`)}</h5>

          <i className="fa fa-close clickable" onClick={closeModal} />
        </div>

        <div className="navigation d-flex align-items-center">
          <NavigationButtons>
            <NavigationDate />
          </NavigationButtons>
        </div>
      </div>

      <div className="modal-body pb-0 overflow-x-hidden">
        <SelectExpertInput />

        <div className="form-group row">
          <div className="col-sm-3 d-flex align-items-center">
            <Label required>{t('calendar.appointment.type')}</Label>
          </div>
          <div className="col">
            <Select
              name="type"
              options={appointmentTypeForSelectInput}
              value={type}
              onChange={e => onChangeAppointmentType(e.target.value)}
            />
          </div>
        </div>
        <div className="row form-group">
          <div className="col-sm-3 d-flex align-items-center">
            <Label required>{t('calendar.appointment.title')}</Label>
          </div>
          <div className="col">
            <Input
              className="form-control"
              name="title"
              onChange={onChange}
              value={title}
              max={200}
            />
          </div>
        </div>
        <div className="row form-group">
          <div className="col-sm-3 d-flex align-items-center">
            <Label required>{t('calendar.appointment.startDate')}</Label>
          </div>
          <div className="col">
            <DatePicker
              className="form-control"
              name="start"
              dateFormat="dd/MM/y HH:mm"
              showTimeSelect
              onChange={value => onChangeStartDate(value)}
              selected={start}
              timeIntervals={15}
            />
          </div>
        </div>

        <div className="row form-group">
          <div className="col-sm-3 d-flex align-items-center">
            <Label required>{t('calendar.appointment.endDate')}</Label>
          </div>
          <div className="col">
            <DatePicker
              className="form-control"
              name="end"
              dateFormat="dd/MM/y HH:mm"
              showTimeSelect
              onChange={value => setProperty('end', value)}
              selected={end}
              timeIntervals={15}
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-sm-3 d-flex align-items-center">
            <Label>{t('calendar.appointment.address')}</Label>
          </div>
          <div className="col">
            <Address
              name="address"
              placeholder=" "
              initialValue={address ? concatAddress(address) : ''}
              onSelected={onChangeAddress}
              value={address}
            />
          </div>
        </div>
        {type !== MISSION_TYPE_ID && type !== MISSION_WAIT_TYPE_ID && <AppointmentRecurrence />}
        {(type === MISSION_TYPE_ID || type === MISSION_WAIT_TYPE_ID) && (
          <div className="form-group row">
            <div className="col-sm-3 d-flex align-items-center">
              <Label required>{`${t('calendar.appointment.insurerClaimId')} / ${t(
                'calendar.appointment.wan',
              )}`}</Label>
            </div>
            <div className="col d-flex align-items-start">
              <div className="d-flex flex-fill sinput-group d-inline-block">
                <Input
                  className="form-control"
                  name="insurerClaimId"
                  value={insurerClaimId}
                  onChange={onChange}
                  disabled={!!id}
                />
                {!id && (
                  <div className="input-group-append">
                    <Button
                      onClick={() => getWans(insurerClaimId)}
                      className="btn btn-success"
                      disabled={!insurerClaimId || loadingWans}
                      loading={loadingWans}
                    >
                      <i className="fa fa-search" />
                    </Button>
                  </div>
                )}
              </div>

              <div className="flex-fill ml-3">
                {!wan && (
                  <Select
                    placeholder={t(
                      `calendar.appointment.${wansForSelect.length ? 'selectValue' : 'wanInfo'}`,
                    )}
                    name="wan"
                    options={wansForSelect}
                    value={wan}
                    onChange={onWanSelected}
                  />
                )}
                {wan && <Input className="form-control" name="wan" value={wan} disabled />}
              </div>

              <div className="flex-shrink-1">
                {wan && id && (
                  <Button
                    onClick={goTo}
                    className="btn btn-primary ml-2"
                    loading={loadingValidation}
                  >
                    {t('common.open')}
                  </Button>
                )}
              </div>
            </div>
          </div>
        )}

        <div className="form-group row">
          <div className="col-sm-3 d-flex">
            <Label>{t('calendar.appointment.comment')}</Label>
          </div>
          <div className="col">
            <TextArea className="form-control" name="comment" onChange={onChange} value={comment} />
          </div>
        </div>
      </div>

      <div className="modal-footer pb-2 pt-2">
        <div className="col-sm-8">
          {appointmentRecurrentId &&
            isRecurrent &&
            modifyRecurenceOptions.map((option, index) => (
              <div key={`modifyRecurenceOptions-${index}`} className="form-check">
                <input
                  classNames="form-check-input"
                  type="radio"
                  name="modifyRecurrencesSelectedOption"
                  value={option.value}
                  onChange={onChange}
                  id={`modifyRecurrencesOption-${option.value}`}
                />
                <label
                  required
                  className="form-check-label ml-4"
                  htmlFor={`modifyRecurrencesOption-${option.value}`}
                >
                  {option.label}
                </label>
              </div>
            ))}
        </div>
        <div className="col-sm-4 d-flex justify-content-between">
          {id ? (
            <Button
              className="btn btn-danger"
              onClick={deleteAppointment}
              disabled={loading || !modifyReccurenceOptionIsSatify}
              loading={loading}
            >
              {t('common.delete')}
            </Button>
          ) : (
            <Button className="btn btn-secondary" onClick={closeModal} disabled={loading}>
              {t('common.cancel')}
            </Button>
          )}
          <Button
            className="btn btn-success"
            onClick={saveAppointment}
            disabled={loading || !isValid || !modifyReccurenceOptionIsSatify}
            loading={loading}
          >
            {t('common.save')}
          </Button>
        </div>
      </div>
    </ModalWithBackdrop>
  )
}

export default compose(
  inject('AppointmentCtrl', 'CommonStore', 'AlertCtrl', 'UserStore'),
  withTranslation(),
  observer,
)(AppointmentModal)
