import React from 'react'
import { inject, observer } from 'mobx-react'

import { euro } from 'utils/currency'
import TotalPriceWarning from './TotalPriceWarning'
import InputPrice from 'components/InputPrice/InputPrice'

const SettlementAspTable = ({
  CartStore: {
    instructions: { negotiation },
    setInstructions,
    leakTotalPriceCatalog,
    totalRelatedCostsAnnexPriceWithVAT,
    leakDeffered,
    leakImmediate,
    totalColumnDeffered,
    leakTotal,
    totalColumnTotal,
    totalPropertyAndEmbellishment,
    propertyAndEmbellishmentImmediate,
    relatedCostImmediate,
    relatedCostDeffered,
    totalColumnImmediate,
    propertyAndEmbellishmentDeffered,
    relatedCostTotal,
    configuration: { maxDifferenceAsp = Infinity },
    franchiseImmediate,
    franchiseDeffered,
    franchiseTotal,
    totalAspImmediate,
    totalAspDeffered,
    totalAsp,
    totalAdvanceAspImmediate,
    totalAdvanceAspDeffered,
    totalAdvanceAsp,
    totalAspGarantie,
    configuration: { travelPackageManageable = false },
    hasTravelRCPPackage,
    travelRCPPackage,
  },
  InstructionCtrl: { isFranchiseMustBeDeducted },
}) => {
  const onChange = e => {
    setInstructions(e.target.name, e.target.value === '' ? 0 : parseFloat(e.target.value), true)
  }

  return (
    <table className="table table-bordered d-none d-md-table mt-5">
      <tbody>
        <tr>
          <th className="text-center align-middle">Modalité de Règlement</th>
          <th className="text-center align-middle">Réglement immédiat TTC</th>
          <th className="text-center align-middle">Réglement différé TTC</th>
          <th className="text-center align-middle">Total réglement TTC</th>
        </tr>

        {/* PROPERTY + EMBELLISHMENT */}
        <tr>
          <td className="text-center align-middle">Immobilier / Embellissements</td>
          <td className="text-center align-middle">
            <InputPrice
              name="propertyAndEmbellishment.immediate"
              price={propertyAndEmbellishmentImmediate}
              onChange={onChange}
              width={80}
            />
          </td>
          <td className="text-center align-middle">
            <InputPrice
              name="propertyAndEmbellishment.deffered"
              price={propertyAndEmbellishmentDeffered}
              onChange={onChange}
              width={80}
            />
          </td>
          <td className="text-center align-middle">{euro(totalPropertyAndEmbellishment)}</td>
        </tr>

        {/* LEAK */}
        <tr>
          <td className="text-center align-middle">Recherche de fuite</td>
          <td className="text-center align-middle">
            <InputPrice
              name="leak.immediate"
              price={leakImmediate}
              onChange={onChange}
              width={80}
            />
          </td>
          <td className="text-center align-middle">
            <InputPrice name="leak.deffered" price={leakDeffered} onChange={onChange} width={80} />
          </td>
          <td className="text-center align-middle">
            <TotalPriceWarning total={leakTotal} trueTotal={leakTotalPriceCatalog} />
          </td>
        </tr>

        {/* RELATED COST */}
        <tr>
          <td className="text-center align-middle">Frais annexes</td>
          <td className="text-center align-middle">
            <InputPrice
              name="relatedCost.immediate"
              price={relatedCostImmediate}
              onChange={onChange}
              width={80}
            />
          </td>
          <td className="text-center align-middle">
            <InputPrice
              name="relatedCost.deffered"
              price={relatedCostDeffered}
              onChange={onChange}
              width={80}
            />
          </td>
          {/*<td className="text-center align-middle table-active"></td>*/}
          <td className="text-center align-middle">
            <TotalPriceWarning
              total={relatedCostTotal}
              trueTotal={totalRelatedCostsAnnexPriceWithVAT}
            />
          </td>
        </tr>

        {/* NEGOTIATION */}
        <tr>
          <td className="text-center align-middle">Négociation</td>
          <td className="text-center align-middle">
            <InputPrice
              name="negotiation.immediate"
              price={negotiation.immediate}
              onChange={onChange}
              width={80}
            />
          </td>
          <td className="text-center align-middle table-active"></td>
          <td className="text-center align-middle">{euro(negotiation.immediate)}</td>
        </tr>

        {/* Forfait déplacement */}
        {hasTravelRCPPackage && (
          <tr>
            <td className="text-center align-middle">Forfait déplacement</td>
            <td className="text-center align-middle pl-5">
              {travelPackageManageable && (
                <InputPrice
                  name="travelRCPPackage.immediateOvverride"
                  price={travelRCPPackage.immediate}
                  onChange={onChange}
                  width={80}
                />
              )}
              {!travelPackageManageable && euro(travelRCPPackage.immediate)}
            </td>
            <td className="text-center align-middle pl-5">
              {travelPackageManageable && (
                <InputPrice
                  name="travelRCPPackage.defferedOverride"
                  price={travelRCPPackage.deffered}
                  onChange={onChange}
                  width={80}
                />
              )}
              {!travelPackageManageable && euro(travelRCPPackage.deffered)}
            </td>
            <td className="text-center align-middle">
              {euro(travelRCPPackage.immediate + travelRCPPackage.deffered)}
            </td>
          </tr>
        )}

        <tr>
          <td className="text-center align-middle">Total TTC</td>
          <td className="text-center align-middle pl-5">{euro(totalColumnImmediate)}</td>
          <td className="text-center align-middle pl-5">{euro(totalColumnDeffered)}</td>
          <td className="text-center align-middle">{euro(totalColumnTotal)}</td>
        </tr>

        <tr>
          <td className="text-center align-middle">Montant TTC Avis sur pièce</td>
          <td className="text-center align-middle pl-5">{euro(totalAspImmediate)}</td>
          <td className="text-center align-middle pl-5">{euro(totalAspDeffered)}</td>
          <td className="text-center align-middle">
            {totalAsp > totalColumnTotal && maxDifferenceAsp < totalAsp - totalColumnTotal && (
              <div className="text-danger">
                <i className="fa fa-warning" /> Ecart ASP dépassé
              </div>
            )}
            {euro(totalAsp)}
          </td>
        </tr>

        {!isFranchiseMustBeDeducted && (
          <tr>
            <td className="text-center align-middle">Franchise à déduire</td>
            <td className="text-center align-middle pl-5">- {euro(0)}</td>
            <td className="text-center align-middle pl-5">- {euro(0)}</td>
            <td className="text-center align-middle">- {euro(0)}</td>
          </tr>
        )}

        {isFranchiseMustBeDeducted && (
          <tr>
            <td className="text-center align-middle">Franchise à déduire</td>
            <td className="text-center align-middle table-active">
              {' - '}
              <InputPrice
                name="franchiseImmediate"
                price={franchiseImmediate}
                width={80}
                disabled={true}
              />
            </td>
            <td className="text-center align-middle table-active">
              {' - '}
              <InputPrice
                name="franchiseDeffered"
                price={franchiseDeffered}
                width={80}
                disabled={true}
              />
            </td>
            <td className="text-center align-middle"> - {euro(franchiseTotal)}</td>
          </tr>
        )}

        <tr>
          <td className="text-center align-middle">Acompte TTC à déduire</td>
          <td className="text-center align-middle pl-5"> - {euro(totalAdvanceAspImmediate)}</td>
          <td className="text-center align-middle pl-5"> - {euro(totalAdvanceAspDeffered)}</td>
          <td className="text-center align-middle"> - {euro(totalAdvanceAsp)}</td>
        </tr>

        <tr>
          <td className="text-center align-middle">Total TTC montant Garanti</td>
          <td className="text-center align-middle pl-5">{euro(totalAspGarantie.immediate)}</td>
          <td className="text-center align-middle pl-5">{euro(totalAspGarantie.deffered)}</td>
          <td className="text-center align-middle">
            {' '}
            {euro(totalAspGarantie.immediate + totalAspGarantie.deffered)}
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default inject('CartStore', 'InstructionCtrl')(observer(SettlementAspTable))
