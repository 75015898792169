import React from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styled from 'styled-components'
import { isNil, test } from 'ramda'

import './InputPrice.css'
import Unit from 'components/Unit/Unit'
import { euro, roundDecimal } from 'utils/currency'

const OverrodeText = styled.span`
  height: 24px;
  display: inline-block;
  text-decoration: line-through;
`

const ResetIcon = ({ changed, resetPrice }) => {
  if (changed)
    return <i className="fa fa-times-circle text-danger clickable mr-2" onClick={resetPrice} />
  return null
}

const formatPrice = priceString => {
  if (priceString === '') return '0'

  const uniformString = priceString.replace(/,|\./g, ',')
  const arrayString = uniformString.split(',')
  const joinString = arrayString.join('')

  if (joinString === '' || joinString === '0') return '0'

  if (arrayString.length === 1) return joinString
  else if (arrayString[arrayString.length] === '') return joinString

  const decimal = arrayString.pop()

  return arrayString.join('') + ',' + decimal
}

class InputPrice extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      onFocus: false,
      changed: props.changed,
      priceString: formatPrice(isNil(props.price) ? '0' : props.price.toString()),
    }

    this.input = React.createRef()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.price !== nextProps.price) {
      this.setState({ priceString: formatPrice(nextProps.price.toString()) })
    }
  }

  onKeyPress = e => {
    // Block if it is at maximum length
    const totalMaxLength = this.maxLengthNumber + this.maxLengthDecimal + 1
    if (e.target.value.length > totalMaxLength) {
      e.preventDefault()
      return
    }
    // Block , or . if there is already a , or .
    if (test(/,|\./g, e.target.value) && (e.charCode === 44 || e.charCode === 46)) {
      e.preventDefault()
      return
    }

    if (e.charCode === 44 || e.charCode === 46) return
    else if (e.charCode >= 48 && e.charCode <= 57) return
    else e.preventDefault()
  }

  onChange = e => {
    // Block if there is maxLengthDecimal number after the decimal
    if (
      test(/,/g, e.target.value) &&
      e.target.value.split(',').pop().length > this.props.maxLengthDecimal
    )
      return
    else if (
      test(/\./g, e.target.value) &&
      e.target.value.split('.').pop().length > this.props.maxLengthDecimal
    )
      return

    // Block if there is more than maxLengthNumber number
    if (!test(/,|\./g, e.target.value) && e.target.value.length > this.props.maxLengthNumber) return

    // Block if there is more than maxLengthNumber number before the decimal
    if (
      test(/,/g, e.target.value) &&
      e.target.value.split(',').shift().length > this.props.maxLengthNumber
    )
      return
    else if (
      test(/\./g, e.target.value) &&
      e.target.value.split('.').shift().length > this.props.maxLengthNumber
    )
      return

    if (this.props.overridable && !this.state.changed) {
      this.setState({ changed: true, priceString: e.target.value })
    } else {
      this.setState({ priceString: e.target.value })
    }
  }

  onClick = e => {
    if (!this.state.onFocus) e.target.setSelectionRange(0, e.target.value.length, 'forward')
  }

  onFocus = e => {
    this.setState({ onFocus: true }, () =>
      this.input.setSelectionRange(0, this.input.value.length, 'forward'),
    )
  }

  onBlur = () => {
    const formattedPriceString = formatPrice(this.state.priceString)
    let price = isNil(this.props.price) ? 0 : this.props.price
    if (price.toString() === formattedPriceString.replace(',', '.')) {
      this.setState({ onFocus: false })
      return
    }

    price = roundDecimal(parseFloat(formattedPriceString.replace(',', '.')))
    this.props.onChange({ target: { name: this.props.name, value: price } })

    if (formattedPriceString !== this.state.priceString) {
      this.setState({ onFocus: false, priceString: formattedPriceString })
    } else {
      this.setState({ onFocus: false })
    }
  }

  resetPrice = () => {
    this.setState({ changed: false })
    this.props.onChange({ target: { name: this.props.name, value: null } })
  }

  render() {
    const {
      name,
      className,
      classNameForPrice,
      disabled,
      overrode,
      unit,
      price,
      showUnit,
      disableShowUnit,
      width,
      autoFocus,
      autoComplete,
    } = this.props
    const { onFocus, priceString, changed } = this.state

    if (overrode) {
      return (
        <OverrodeText
          className={classNames(classNameForPrice, 'overrode-price', {
            'without-unit': !disableShowUnit && !showUnit,
          })}
        >
          {euro(price)}
          {showUnit ? <Unit className="unit" unit={unit} /> : null}
        </OverrodeText>
      )
    }
    const style = {}
    if (width !== undefined) style.width = `${width}px`

    return (
      <div
        className={classNames('input-price-wrapper', {
          'without-unit': !disableShowUnit && !showUnit,
        })}
      >
        <ResetIcon changed={changed} resetPrice={this.resetPrice} />
        <input
          ref={ref => (this.input = ref)}
          name={name}
          className={classNames('input-price text-right', className, { disabled })}
          value={onFocus ? priceString : euro(price)}
          type="text"
          onChange={this.onChange}
          onKeyPress={this.onKeyPress}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          onClick={this.onClick}
          autoFocus={autoFocus}
          disabled={disabled}
          style={style}
          autoComplete={autoComplete}
        />

        {showUnit ? <Unit className="unit text-right" unit={unit} /> : null}
      </div>
    )
  }
}

InputPrice.defaultProps = {
  className: '',
  classNameForPrice: '',
  disabled: false,
  showUnit: false,
  overrode: false,
  overridable: false,
  disableShowUnit: true,
  changed: false,
  maxLengthNumber: 5,
  maxLengthDecimal: 2,
  autoFocus: false,
  autoComplete: 'on',
}

InputPrice.propTypes = {
  name: PropTypes.string.isRequired,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  classNameForPrice: PropTypes.string,
  showUnit: PropTypes.bool,
  disableShowUnit: PropTypes.bool,
  width: PropTypes.number,
  unit: PropTypes.string,
  disabled: PropTypes.bool,
  overrode: PropTypes.bool,
  overridable: PropTypes.bool,
  changed: PropTypes.bool,
  maxLengthNumber: PropTypes.number,
  maxLengthDecimal: PropTypes.number,
  autoFocus: PropTypes.bool,
  autoComplete: PropTypes.string,
}

export default observer(InputPrice)
