import React from 'react'
import { observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames'

import SearchPackageCtrl from 'stores/Mission/view/SearchPackageCtrl'
import CartStore from 'stores/Mission/domain/CartStore'
import ResponsiveSelect from 'components/ResponsiveSelect/ResponsiveSelect'

export default withTranslation()(
  observer(({ t, packageType }) => {
    if (!CartStore.configuration) return null

    const { priceType } = SearchPackageCtrl
    const { priceListOptions } = CartStore.configuration
    const clickTab = value => {
      const priceList = value === 'catalog' ? value : parseInt(value, 10)
      SearchPackageCtrl.setProperty('priceType', priceList)
    }
    const onChange = e => clickTab(e.target.value)

    if (priceListOptions.length === 0) {
      return (
        <div
          name="priceType"
          className={classNames('tab col-12 col-sm-4 col-md-4 col-lg-auto', {
            selected: priceType === 'catalog',
          })}
          onClick={clickTab.bind('catalog')}
        >
          {t('mission.calculation.searchPackage.catalog')}
        </div>
      )
    }

    if (priceListOptions.length <= 1) {
      return (
        <React.Fragment>
          <div
            name="priceType"
            className={classNames('tab col-12 col-sm-4 col-md-4 col-lg-auto', {
              selected: priceType === 'catalog',
            })}
            onClick={clickTab.bind(this, 'catalog')}
          >
            {t('mission.calculation.searchPackage.catalog')}
          </div>
          {priceListOptions.length === 1 ? (
            <div
              name="priceType"
              className={classNames('tab col-12 col-sm-4 col-md-4 col-lg-auto', {
                selected: priceType !== 'catalog',
              })}
              onClick={clickTab.bind(this, priceListOptions[0].value)}
            >
              {priceListOptions[0].label}
            </div>
          ) : null}
        </React.Fragment>
      )
    }

    return (
      <ResponsiveSelect
        className="col-12 col-sm-4 col-md-4 col-lg-auto select-pricelist mr-2"
        name="priceType"
        options={priceListOptions}
        value={priceType.toString()}
        onChange={onChange}
      />
    )
  }),
)
