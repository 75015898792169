import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { compose } from 'recompose'
import { isNil, keys, path } from 'ramda'
import classNames from 'classnames'

import RiskConformityCtrl from 'stores/Mission/view/RiskConformityCtrl'
import CausesAndCircumstancesCtrl from 'stores/Mission/view/CausesAndCircumstancesCtrl'
import DamageDescriptionCtrl from 'stores/Mission/view/DamageDescriptionCtrl'
import ConclusionCtrl from 'stores/Mission/ConclusionCtrl'
import IrsiCtrl from 'stores/Mission/view/IrsiCtrl'
import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'
import Loader from 'components/Loader/Loader'

const ErrorUrl = ({ error }) => {
  if (isNil(error.label)) return <span className="mx-1">{error.message}</span>

  const openGroupAccordion = () => {
    let ctrl = null

    switch (error.formCtrl) {
      case 'damageDescription':
        ctrl = DamageDescriptionCtrl
        break
      case 'riskConformity':
        ctrl = RiskConformityCtrl
        break
      case 'causesAndCircumstances':
        ctrl = CausesAndCircumstancesCtrl
        break
      case 'conclusion':
        ctrl = ConclusionCtrl
        break
      case 'irsi':
        ctrl = IrsiCtrl
        break
      default:
        break
    }
    const form = path(['form'], ctrl)

    if (isNil(form)) console.warn(`Controller for ${error.formCtrl} not found.`)
    else {
      if (!form.isGroupOpen(error.groupId)) form.openCloseGroup(error.groupId)
    }
  }

  return (
    <Link
      className="mx-1"
      to={{ pathname: error.redirectTo, state: { focus: error.focus } }}
      onClick={openGroupAccordion}
    >
      {error.message}
    </Link>
  )
}

const Errors = ({ t, data }) => {
  if (data.length === 0) {
    return (
      <p className="text-success">
        <i className="fa fa-check" aria-hidden="true" /> {t('mission.report.validator.success')}
      </p>
    )
  }

  return keys(data).map(errorGroupId => {
    return (
      <div className="mb-3" key={`errorGroupId-${errorGroupId}`}>
        <h5 className="mb-2">
          <i className="fa fa-exclamation-triangle text-warning" />{' '}
          <span className="text-underline">
            {errorGroupId !== 'noGroup'
              ? t(`mission.sideNav.${errorGroupId}`)
              : t('mission.report.validator.noGroup')}{' '}
            :
          </span>
        </h5>

        {data[errorGroupId].map(error => {
          return (
            <div className="row pl-3" key={error.property_path}>
              <span>
                -<ErrorUrl error={error} />
              </span>
            </div>
          )
        })}
      </div>
    )
  })
}

const ValidatorModal = ({
  t,
  ReportCtrl: {
    setProperty,
    showValidatorModal,
    loadingValidator,
    validatorErrors,
    currentReportType,
  },
}) => {
  const [grabbing, setGrabbing] = useState(false)
  const onClose = () => setProperty('showValidatorModal', false)

  return (
    <ModalWithBackdrop
      size="modal-md"
      show={showValidatorModal}
      close={onClose}
      scrollable
      draggable
      setGrabbing={setGrabbing}
    >
      <div className={classNames('modal-header', { grabbable: !grabbing, grabbing: grabbing })}>
        <h4 className="modal-title">
          {t(
            `mission.report.validator.modalTitle${currentReportType === 'WR' ? 'WorkRequest' : ''}`,
          )}
        </h4>
        <button type="button" className="close" onClick={onClose}>
          <span>&times;</span>
        </button>
      </div>

      <div className="modal-body overflow-x-hidden">
        {loadingValidator ? <Loader /> : <Errors t={t} data={validatorErrors} />}
      </div>

      <div className="modal-footer pb-2 pt-2">
        <button type="button" className="btn btn-primary" onClick={onClose}>
          {t('common.close')}
        </button>
      </div>
    </ModalWithBackdrop>
  )
}

export default compose(
  inject('ReportCtrl'),
  withTranslation(),
  observer,
)(ValidatorModal)
