import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import OrientationCtrl from 'stores/ManagerClaim/view/OrientationCtrl'
import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'
import Loader from 'components/Loader/Loader'
import Form from 'components/DynForm/Form'
import classNames from 'classnames'

const OrientationModal = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const { loadOrientationForm, loadingForm, form, save, showModal } = OrientationCtrl
  const [grabbing, setGrabbing] = useState(false)

  useEffect(() => {
    loadOrientationForm(id)
  }, [showModal, loadOrientationForm, id])

  const closeModal = () => {
    OrientationCtrl.setProperty('showModal', false)
    OrientationCtrl.setProperty('choice', null)
    OrientationCtrl.setProperty('form', null)
  }

  return (
    <ModalWithBackdrop
      show={showModal}
      size="modal-xl"
      close={closeModal}
      draggable
      setGrabbing={setGrabbing}
    >
      <div className={classNames('modal-header', { grabbable: !grabbing, grabbing: grabbing })}>
        <h5 className="modal-title">{t('managerClaim.orientation.solution')}</h5>
        <button type="button" className="close" onClick={closeModal}>
          <span>&times;</span>
        </button>
      </div>

      <div className="modal-body">
        {(loadingForm || !form) && <Loader />}
        {form && (
          <Form
            forceEnable={true}
            formObj={form}
            saveService={save.bind(this, id)}
            saveLabel="Valider"
          />
        )}
      </div>
    </ModalWithBackdrop>
  )
}

export default observer(OrientationModal)
