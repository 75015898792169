import React from 'react'
import { observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { NavLink, withRouter } from 'react-router-dom'

import SearchPackageCtrl from 'stores/Mission/view/SearchPackageCtrl'
import CartStore from 'stores/Mission/domain/CartStore'
import CollapseList from './CollapseList'

class LeaksList extends React.Component {
  state = { open: true }
  open = () => {
    const { leakPackages } = CartStore

    if (leakPackages.length > 0) this.setState({ open: !this.state.open })
  }
  setContext = () => {
    SearchPackageCtrl.resetSearchContext('DAT_LEAK')
    SearchPackageCtrl.setProperty('roomType', 'noFilter')
    SearchPackageCtrl.setProperty('priceType', 'catalog')
  }

  render() {
    const {
      t,
      match: {
        params: { id, type },
      },
    } = this.props
    const { leakPackagesByRoom } = CartStore

    return (
      <div className="container border-bottom border-color-primary">
        <CollapseList
          title={t('mission.calculation.cart.titleLeak')}
          type="DAT_LEAK"
          rooms={leakPackagesByRoom}
        >
          <NavLink
            className="btn btn-outline-primary rounded-circle"
            to={`/${type}/${id}/calculation/searchPackage`}
            onClick={this.setContext}
          >
            <i className="fa fa-plus" />
          </NavLink>
        </CollapseList>
      </div>
    )
  }
}

export default withRouter(withTranslation()(observer(LeaksList)))
