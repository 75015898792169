import React from 'react'
import { observer } from 'mobx-react'
import { isNil } from 'ramda'
import classNames from 'classnames'

import Label from 'components/Forms/Label'

const FormLabel = ({ input, fold = false, onClickFold }) => {
  if (input.type === 'table' || input.type === 'embeddedCollection' || input.type === 'label') {
    return null
  } else if (!isNil(input.labelUrl) && !isNil(input.url)) {
    return (
      <Label className="col-sm-4" required={input.required} label={input.label}>
        (
        <a href={input.url} target="_blank" rel="noopener noreferrer">
          {input.labelUrl}
        </a>
        )
      </Label>
    )
  }

  return (
    <Label className="col-sm-4" required={input.required} label={input.label}>
      {input.foldable && (
        <i
          className={classNames('text-primary fa', { 'fa-eye-slash': fold, 'fa-eye': !fold })}
          onClick={onClickFold}
        ></i>
      )}

      {input.wysiwygMix && (
        <i
          className={classNames('btn btn-sm btn-outline-primary clickable fa ml-1', {
            'fa-file-word-o': !input.wysiwyg,
            'fa-text-width': input.wysiwyg,
          })}
          aria-hidden="true"
          onClick={input.toggleWysiwyg}
        ></i>
      )}
    </Label>
  )
}

export default observer(FormLabel)
