import React, { Fragment, useState } from 'react'
import { inject, observer } from 'mobx-react'
import classNames from 'classnames'
import { setHours, setMinutes, getDay } from 'date-fns'

import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'
import { euro } from 'utils/currency'
import Button from 'components/Button/Button'
import Label from 'components/Label/Label'
import DatePicker from 'components/Forms/DatePicker'
const SelfCareIndemnisation = ({
  SelfCareStore: { wan },
  SelCareIndemnisationCtrl: {
    loading,
    setProperty,
    save,
    choice,
    isValid,
    appointmentDate,
    formatAppointmentDate,
    appointmentDateHourStart,
    appointmentDateHourEnd,
    accepedChoice,
    gagAvailable,
    gagAmount,
    mobilierAvailable,
    mobilierAmount,
    renAvailable,
    ipAvailable,
    gagMobilierAvailable,
    gagMobilierAmount,
    gagMobilierTravauxAmount,
    gagMobilierMobilierAmount,
    renMobilierAvailable,
    renMobilierTravauxAmount,
    renMobilierMobilierAmount,
    contactInsuredAvailable,
    showModal,
    backToIndemnisation,
    confirmOrientation,
  },
}) => {
  const [grabbing, setGrabbing] = useState(false)

  const today = new Date()
  let tomorrow = new Date()
  tomorrow.setDate(today.getDate() + 1)
  tomorrow.setHours(9)
  tomorrow.setMinutes(0)

  const minTime = setMinutes(setHours(new Date(), 9), 0)
  const maxTime = setMinutes(setHours(new Date(), 17), 0)

  const isWeekday = date => {
    const day = getDay(date)
    return day !== 0 && day !== 6
  }

  const closeModal = () => {
    backToIndemnisation(wan)
  }

  return (
    <Fragment>
      <ModalWithBackdrop
        show={showModal}
        size="modal-lg"
        close={closeModal}
        draggable
        setGrabbing={setGrabbing}
      >
        <div className={classNames('modal-header', { grabbable: !grabbing, grabbing: grabbing })}>
          <h5 className="modal-title">Confirmation</h5>
          <Button className="close" disabled={loading} loading={loading} onClick={closeModal}>
            <span>&times;</span>
          </Button>
        </div>

        <div className="modal-body">
          <div className="row no-gutters">
            <div className="col-12">Vous vous apprétez à choisir :</div>
          </div>

          <div className="row no-gutters pl-4">
            <div className="col-12 font-weight-bold">
              {choice === 'gag' && 'Réparation par vous même'}
              {choice === 'mobilier' && 'Règlements pour les dommages mobiliers'}
              {choice === 'ren' && "Intervention d'une entreprise agréée"}
              {choice === 'ip' && "L'Intervention de mon entreprise"}
              {choice === 'gagMobilier' &&
                'Réparation par vous-même avec règlements pour les dommages mobiliers'}
              {choice === 'renMobilier' &&
                "intervention d'une entreprise agréée avec règlements pour les dommages mobiliers"}
              {choice === 'contactInsured' && 'Etre contacté par nos services'}
            </div>
          </div>

          <div className="row no-gutters mt-4 mb-4">
            <div className="col-12">
              <div className="form-group form-check">
                <input
                  id="accepedChoice"
                  className="form-check-input"
                  type="checkbox"
                  checked={accepedChoice}
                  onChange={() => setProperty('accepedChoice', !accepedChoice)}
                />
                <label htmlFor="accepedChoice" className="form-check-label">
                  {choice === 'gag' && (
                    <span>
                      J'accepte la somme de {euro(gagAmount)} pour règlement total de ce sinistre.{' '}
                      Je suis informé(e) que suite au versement de cette somme, le dossier sera
                      clos.
                    </span>
                  )}

                  {choice === 'gagMobilier' && (
                    <span>
                      J'accepte la somme de {euro(gagMobilierTravauxAmount)} pour les dommages
                      immobiliers et {euro(gagMobilierMobilierAmount)} pour les dommages mobiliers
                      de mon sinistre. Je suis informé(e) que suite au versement de cette somme, le
                      dossier sera clos.
                    </span>
                  )}

                  {choice === 'mobilier' && (
                    <span>
                      J'accepte la somme de {euro(mobilierAmount)} pour règlement total de ce
                      sinistre. Je suis informé(e) que suite au versement de cette somme, le dossier
                      sera clos.
                    </span>
                  )}

                  {choice === 'ren' && (
                    <span>
                      Je confirme le rendez-vous avec l'entreprise agréée le {formatAppointmentDate}{' '}
                      de {appointmentDateHourStart} à {appointmentDateHourEnd}
                    </span>
                  )}

                  {choice === 'renMobilier' && (
                    <span>
                      Je confirme le rendez-vous avec l'entreprise agréée le {formatAppointmentDate}{' '}
                      de {appointmentDateHourStart} à {appointmentDateHourEnd} et le versement d'un
                      montant de {euro(renMobilierMobilierAmount)} pour les dommages mobiliers.
                    </span>
                  )}

                  {choice === 'ip' && (
                    <span>
                      Je confirme souhaité être contacté par vos services le {formatAppointmentDate}{' '}
                      de {appointmentDateHourStart} à {appointmentDateHourEnd}
                    </span>
                  )}

                  {choice === 'contactInsured' && (
                    <span>
                      Je confirme souhaité être contacté par vos services le {formatAppointmentDate}{' '}
                      de {appointmentDateHourStart} à {appointmentDateHourEnd}
                    </span>
                  )}
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="modal-footer pb-2 pt-2 d-flex justify-content-between">
          <Button
            className="btn btn-secondary"
            onClick={closeModal}
            disabled={loading}
            loading={loading}
          >
            Retour
          </Button>

          <Button
            className="btn btn-success"
            onClick={() => confirmOrientation(wan)}
            disabled={!isValid || loading}
            loading={loading}
          >
            Je valide ma déclaration
          </Button>
        </div>
      </ModalWithBackdrop>

      <div className="row no-gutters mb-4">
        <div className="col-12">
          {' '}
          <h4>Veuillez choisir votre indemnisation</h4>
        </div>
      </div>

      {gagAvailable && (
        <div className="border border-primary rounded p-3 mb-3">
          <div className="row no-gutters">
            <div className="col-12">
              <h6>Réparation par vous même</h6>
              <br />
              <span>Le montant proposé vous sera réglé dans l'immédiat</span>
              <br />
              <span>Montant proposé de : </span>
              <span className="font-weight-bold">{euro(gagAmount)}</span>

              <div className="d-flex justify-content-end mt-4">
                <Button
                  className="btn btn-primary"
                  onClick={() => {
                    setProperty('choice', 'gag')
                    save(wan)
                  }}
                  disabled={loading}
                  loading={loading}
                >
                  Choisir cette indemnisation
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {mobilierAvailable && (
        <div className="border border-primary rounded p-3 mb-3">
          <div className="row no-gutters">
            <div className="col-12">
              <h6>Règlements pour les dommages mobiliers</h6>
              <br />
              <span>Le montant proposé vous sera réglé dans l'immédiat</span>
              <br />
              <span>Montant proposé de : </span>
              <span className="font-weight-bold">{euro(mobilierAmount)}</span>

              <div className="d-flex justify-content-end mt-4">
                <Button
                  className="btn btn-primary"
                  onClick={() => {
                    setProperty('choice', 'mobilier')
                    save(wan)
                  }}
                  disabled={loading}
                  loading={loading}
                >
                  Choisir cette indemnisation
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {renAvailable && (
        <div className="border border-primary rounded p-3 mb-3">
          <div className="row no-gutters">
            <div className="col-12">
              <h6 required>Intervention d'une entreprise agréée</h6>
              <br />
              <span>Votre assurance s'occupe de tous pour vous</span>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="form-group col-12">
              <Label required={true}>Quand souhaitez-vous être recontacté par nos services</Label>
              <DatePicker
                className="form-control"
                name="appointmentDate"
                dateFormat="dd/MM/y H:mm"
                onChange={value => setProperty('appointmentDate', value)}
                selected={appointmentDate}
                minDate={tomorrow}
                placeholder="jj/mm/aaa"
                withPortal={true}
                showMonthDropdown={false}
                showYearDropdown={false}
                showTimeSelect={true}
                timeIntervals={30}
                minTime={minTime}
                maxTime={maxTime}
                filterDate={isWeekday}
                required={true}
                onFocus={e => (e.target.readOnly = true)}
                onBlur={e => (e.target.readOnly = false)}
              />
            </div>
          </div>
          <div className="d-flex justify-content-end mt-4">
            <Button
              className="btn btn-primary"
              onClick={() => {
                setProperty('choice', 'ren')
                save(wan)
              }}
              disabled={loading || !appointmentDate}
              loading={loading}
            >
              Confirmer pour être contacté
            </Button>
          </div>
        </div>
      )}

      {ipAvailable && (
        <div className="border border-primary rounded p-3 mb-3">
          <div className="row no-gutters">
            <div className="col-12">
              <h6>L'intervention de mon entreprise</h6>
              <br />
            </div>
          </div>
          <div className="row no-gutters mt-2">
            <div className="form-group col-12">
              <Label required={true}>Quand souhaitez-vous être recontacté par nos services</Label>
              <DatePicker
                className="form-control"
                name="appointmentDate"
                dateFormat="dd/MM/y H:mm"
                onChange={value => setProperty('appointmentDate', value)}
                selected={appointmentDate}
                minDate={tomorrow}
                placeholder="jj/mm/aaa"
                withPortal={true}
                showMonthDropdown={false}
                showYearDropdown={false}
                showTimeSelect={true}
                timeIntervals={30}
                minTime={minTime}
                maxTime={maxTime}
                filterDate={isWeekday}
                required={true}
                onFocus={e => (e.target.readOnly = true)}
                onBlur={e => (e.target.readOnly = false)}
              />
            </div>
          </div>
          <div className="d-flex justify-content-end mt-4">
            <Button
              className="btn btn-primary"
              onClick={() => {
                setProperty('choice', 'ip')
                save(wan)
              }}
              disabled={loading || !appointmentDate}
              loading={loading}
            >
              Confirmer pour être contacté
            </Button>
          </div>
        </div>
      )}

      {gagMobilierAvailable && (
        <div className="border border-primary rounded p-3 mb-3">
          <div className="row no-gutters">
            <div className="col-12">
              <h6>Réparation par vous-même avec règlements pour les dommages mobiliers</h6>
              <br />
              <span>Le montant proposé vous sera réglé dans l'immédiat</span>
              <br />
              <span>Montant proposé de : </span>
              <span className="font-weight-bold">{euro(gagMobilierAmount)}</span>

              <div className="mt-2">
                <span>Montant des travaux : </span>{' '}
                <span className="font-weight-bold"> {euro(gagMobilierTravauxAmount)}</span>
                <br />
                <span>Montant des dommages mobiliers :</span>{' '}
                <span className="font-weight-bold"> {euro(gagMobilierMobilierAmount)}</span>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-end mt-4">
            <Button
              className="btn btn-primary"
              onClick={() => {
                setProperty('choice', 'gagMobilier')
                save(wan)
              }}
              disabled={loading}
              loading={loading}
            >
              Choisir cette indemnisation
            </Button>
          </div>
        </div>
      )}

      {renMobilierAvailable && (
        <div className="border border-primary rounded p-3 mb-3">
          <div className="row no-gutters">
            <div className="col-12">
              <h6 required>
                Souhaitez-vous l'intervention d'une entreprise agréée avec règlements pour les
                dommages mobiliers
              </h6>
              <br />
              <span>Votre assurance s'occupe de tous pour vous</span>

              <div className="mt-2">
                <span>Montant des dommages mobiliers :</span>{' '}
                <span className="font-weight-bold"> {euro(renMobilierMobilierAmount)}</span>
              </div>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="form-group col-12">
              <Label required={true}>Quand souhaitez-vous être recontacté par nos services</Label>
              <DatePicker
                className="form-control"
                name="appointmentDate"
                dateFormat="dd/MM/y H:mm"
                onChange={value => setProperty('appointmentDate', value)}
                selected={appointmentDate}
                minDate={tomorrow}
                placeholder="jj/mm/aaa"
                withPortal={true}
                showMonthDropdown={false}
                showYearDropdown={false}
                showTimeSelect={true}
                timeIntervals={30}
                minTime={minTime}
                maxTime={maxTime}
                filterDate={isWeekday}
                required={true}
                onFocus={e => (e.target.readOnly = true)}
                onBlur={e => (e.target.readOnly = false)}
              />
            </div>
          </div>
          <div className="d-flex justify-content-end mt-4">
            <Button
              className="btn btn-primary"
              onClick={() => {
                setProperty('choice', 'renMobilier')
                save(wan)
              }}
              disabled={loading || !appointmentDate}
              loading={loading}
            >
              Confirmer pour être contacté
            </Button>
          </div>
        </div>
      )}

      {contactInsuredAvailable && (
        <div className="border border-primary rounded p-3 mb-3">
          <div className="row no-gutters">
            <div className="col-12">
              <h6 required>Etre contacté par nos service</h6>
              <br />
            </div>
          </div>
          <div className="row no-gutters">
            <div className="form-group col-12">
              <Label required={true}>Quand souhaitez-vous être recontacté par nos services</Label>
              <DatePicker
                className="form-control"
                name="appointmentDate"
                dateFormat="dd/MM/y H:mm"
                onChange={value => setProperty('appointmentDate', value)}
                selected={appointmentDate}
                minDate={tomorrow}
                placeholder="jj/mm/aaa"
                withPortal={true}
                showMonthDropdown={false}
                showYearDropdown={false}
                showTimeSelect={true}
                timeIntervals={30}
                minTime={minTime}
                maxTime={maxTime}
                filterDate={isWeekday}
                required={true}
                onFocus={e => (e.target.readOnly = true)}
                onBlur={e => (e.target.readOnly = false)}
              />
            </div>
          </div>
          <div className="d-flex justify-content-end mt-4">
            <Button
              className="btn btn-primary"
              onClick={() => {
                setProperty('choice', 'contactInsured')
                save(wan)
              }}
              disabled={loading || !appointmentDate}
              loading={loading}
            >
              Confirmer pour être contacté
            </Button>
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default inject('SelfCareStore', 'SelCareIndemnisationCtrl')(observer(SelfCareIndemnisation))
