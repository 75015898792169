import request from 'services/httpRequest'

export const fetchRiskTypes = async () => {
  try {
    const res = await request({
      method: 'GET',
      url: '/businessData/v1/riskType',
    })

    return res.data.map(data => data.attributes)
  } catch (err) {
    throw err
  }
}

export const fetchRiskTypesSelfCare = async () => {
  try {
    const res = await request({
      method: 'GET',
      url: '/businessData/v1/riskType',
      params: {
        'filters[tag]': 'selfcare',
      },
    })

    return res.data.map(data => data.attributes)
  } catch (err) {
    throw err
  }
}
