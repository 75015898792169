import React, { useState } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'

import FormLabel from './FormLabel'
import InputComponent from './InputComponent'
import AlertMessageInputPrice from './AlertMessageInputPrice'

const Field = ({ cfa, input, formObj }) => {
  const [fold, setFold] = useState(input.foldable && !input.value)

  return (
    <div key={input.id} className="form-group row no-gutters">
      <FormLabel
        input={input}
        fold={fold}
        onClickFold={() => setFold(previousFold => !previousFold)}
      />
      <div className={classNames({ 'd-none': fold }, 'col')}>
        <InputComponent input={input} formObj={formObj} />
      </div>

      <AlertMessageInputPrice input={input} cfa={cfa} />
    </div>
  )
}

export default observer(Field)
