import { observable, computed, action, decorate, runInAction } from 'mobx'
import { isNil } from 'ramda'
import i18next from 'i18next'

import { fetchRelatedCostType } from 'services/relatedCostType'
import RelatedCost from 'stores/Mission/domain/RelatedCost'
import CartStore from 'stores/Mission/domain/CartStore'
import MissionStore from 'stores/Mission/domain/MissionStore'

const relatedCostMapping = (packageType, type) => ({
  value: type.cde,
  label: type.label,
  description: type.description || '',
  type: type.type,
  packageType: packageType,
  darvaLookupValue: type.darvaLookupValue,
  price: type.priceListPrice,
})

const RELATED_COST_SELF_CARE = '32'

class AddRelatedCostCtrl {
  context = 'new'
  relatedCost = null
  additionalRCLoaded = false
  relatedCostType = []
  overflowType = []
  noGuaranteeType = []
  fees = []
  nonMaterials = []
  editionId = null

  get irsiRC() {
    const feesOptions = this.fees.map(rc => ({
      ...rc,
      label: `${i18next.t('mission.calculation.relatedCost.typeFees')} ${rc.label}`,
    }))
    const nonMaterialsOptions = this.nonMaterials.map(rc => ({
      ...rc,
      label: `${i18next.t('mission.calculation.relatedCost.typeNonMaterials')} ${rc.label}`,
    }))

    return [...feesOptions, ...nonMaterialsOptions]
  }

  setNewRelatedCost(packageType = 'relatedCost') {
    this.relatedCost = new RelatedCost({
      packageType,
      involvedParty: MissionStore.isIRSI ? MissionStore.selectedIRSICart : null,
    })
  }

  setNewRelatedCostWithVatZero(packageType = 'relatedCost') {
    this.relatedCost = new RelatedCost({
      packageType,
      vat: 0,
      involvedParty: MissionStore.isIRSI ? MissionStore.selectedIRSICart : null,
    })
  }

  setRelatedCost(cde, involvedParty, optionsType, packageType, withVatZero = false) {
    const { relatedCostPackages, overflowPackages, noGuaranteePackages } = CartStore
    let foundPackage = null

    if (packageType === 'relatedCost') {
      foundPackage = relatedCostPackages.find(relatedCost => {
        return relatedCost.cde === cde && relatedCost.involvedParty === involvedParty
      })
    } else if (packageType === 'overflow') {
      foundPackage = overflowPackages.find(overflow => {
        return overflow.cde === cde && overflow.involvedParty === involvedParty
      })
    } else if (packageType === 'noGuarantee') {
      foundPackage = noGuaranteePackages.find(noGuarantee => {
        return noGuarantee.cde === cde && noGuarantee.involvedParty === involvedParty
      })
    } else {
      console.warn('Something went wrong packageType is undefined by frontend.')
      return
    }

    if (isNil(foundPackage)) {
      if (withVatZero) {
        this.setNewRelatedCostWithVatZero(packageType)
      } else {
        this.setNewRelatedCost(packageType)
      }
      this.relatedCost.setCde({ value: cde, options: optionsType })
      this.relatedCost.setProperty('involvedParty', involvedParty)
      this.context = 'new'
    } else {
      this.relatedCost = foundPackage
      this.context = 'edit'
    }
  }

  setRelatedCostSelfCare() {
    const cde = RELATED_COST_SELF_CARE
    const optionsType = this.relatedCostType

    const { relatedCostPackages } = CartStore

    let foundPackage = null

    foundPackage = relatedCostPackages.find(relatedCost => relatedCost.cde === cde)

    if (isNil(foundPackage)) {
      this.setNewRelatedCost('relatedCost')
      this.relatedCost.setCde({ value: cde, options: optionsType })
      this.context = 'new'
    } else {
      this.relatedCost = foundPackage
      this.context = 'edit'
    }
  }

  setProperty = (key, value) => {
    this[key] = value
  }

  async loadRelatedCostType({ wan, coverage }) {
    try {
      const [resRelatedCostAnnex, resOverflow, resNoGuarantee] = await Promise.all([
        fetchRelatedCostType({
          wan,
          coverage,
          endpoint: 'relatedCost-search',
          type: 'relatedCost_search',
          relatedCostType: 'ANNEX',
        }),
        fetchRelatedCostType({
          wan,
          coverage,
          endpoint: 'exceedingGuaranteedCeiling-search',
          type: 'exceedingGuaranteedCeiling_search',
        }),
        fetchRelatedCostType({
          wan,
          coverage,
          endpoint: 'noGuaranteedDamage-search',
          type: 'noGuaranteedDamage_search',
        }),
      ])

      runInAction(() => {
        this.relatedCostType = resRelatedCostAnnex.map(relatedCostMapping.bind(null, 'relatedCost'))
        this.overflowType = resOverflow.map(relatedCostMapping.bind(null, 'overflow'))
        this.noGuaranteeType = resNoGuarantee.map(relatedCostMapping.bind(null, 'noGuarantee'))
      })
    } catch (err) {
      console.log(err)
    }
  }

  async loadRelatedCostTypeSelfCare({ wan, coverage }) {
    try {
      // we only need Annex on self care
      const resRelatedCostAnnex = await fetchRelatedCostType({
        wan,
        coverage,
        endpoint: 'relatedCost-search',
        type: 'relatedCost_search',
        relatedCostType: 'ANNEX',
      })

      runInAction(() => {
        this.relatedCostType = resRelatedCostAnnex.map(relatedCostMapping.bind(null, 'relatedCost'))
      })
    } catch (err) {
      console.log(err)
    }
  }

  async loadAdditionalRelatedCostType({ wan, coverage }) {
    try {
      if (!this.additionalRCLoaded) {
        const [resRCFees, resRCNonMaterial] = await Promise.all([
          fetchRelatedCostType({
            wan,
            coverage,
            endpoint: 'relatedCost-search',
            type: 'relatedCost_search',
            relatedCostType: 'FEES',
          }),
          fetchRelatedCostType({
            wan,
            coverage,
            endpoint: 'relatedCost-search',
            type: 'relatedCost_search',
            relatedCostType: 'NONMATERIALS',
          }),
        ])
        runInAction(() => {
          this.additionalRCLoaded = true
          this.fees = resRCFees.map(relatedCostMapping.bind(null, 'relatedCost'))
          this.nonMaterials = resRCNonMaterial.map(relatedCostMapping.bind(null, 'relatedCost'))
        })
      }
    } catch (error) {
      console.warn(error)
    }
  }
}

const DecoratedAddRelatedCostCtrl = decorate(AddRelatedCostCtrl, {
  context: observable,
  editionId: observable,
  relatedCost: observable,
  additionalRCLoaded: observable,
  relatedCostType: observable,
  overflowType: observable,
  noGuaranteeType: observable,
  fees: observable,
  nonMaterials: observable,

  irsiRC: computed,

  setNewRelatedCost: action.bound,
  setNewRelatedCostWithVatZero: action,
  setProperty: action,
  setRelatedCost: action.bound,
  loadRelatedCostType: action.bound,
  loadAdditionalRelatedCostType: action.bound,
  loadRelatedCostTypeSelfCare: action.bound,
  setRelatedCostSelfCare: action.bound,
})

export default new DecoratedAddRelatedCostCtrl()
