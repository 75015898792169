import React from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useParams, NavLink } from 'react-router-dom'

import SearchPackageCtrl from 'stores/Mission/view/SearchPackageCtrl'
import CollapseList from './CollapseList'

const EmbellishmentList = ({ CartStore: { embellishmentPackagesByRoom } }) => {
  const { t } = useTranslation()
  const { id, type } = useParams()

  return (
    <div className="container border-bottom border-color-primary">
      <CollapseList
        title={t('mission.calculation.cart.titleEmbellishment')}
        type="DAT_EMB"
        rooms={embellishmentPackagesByRoom}
      >
        <NavLink
          className="btn btn-outline-primary rounded-circle"
          to={`/${type}/${id}/calculation/searchPackage`}
          onClick={() => SearchPackageCtrl.resetSearchContext('DAT_EMB')}
        >
          <i className="fa fa-plus" />
        </NavLink>
      </CollapseList>
    </div>
  )
}

export default inject('CartStore')(observer(EmbellishmentList))
