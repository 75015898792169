import React from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames'
import { compose } from 'recompose'

import Select from 'components/Forms/Select'
import SelectWithSearch from 'components/Select/Select'

/**
 * options should be in shape like : [
 *  {value : 'value', label: 'key'},
 *  {value : 'value', label: 'key'} ...
 * ]
 */

import './ResponsiveSelect.css'

class ResponsiveSelect extends React.Component {
  mobileOptions = options => {
    const obj = {}

    options.forEach(option => {
      obj[option.value] = option.label
    })

    return obj
  }

  render() {
    const { className, disabled, options, value, isMulti, disabledSort } = this.props
    const { isMobile } = this.props.UiCtrl

    let sortedOptions = []

    if (!disabledSort && options.length > 0) {
      sortedOptions = options.slice().sort((a, b) => {
        if (a.label < b.label) return -1
        if (a.label > b.label) return 1
        return 0
      })
    } else {
      sortedOptions = options
    }

    if (isMobile && !isMulti) {
      return (
        <Select
          {...this.props}
          value={value}
          className={classNames('form-control', className)}
          options={this.mobileOptions(sortedOptions)}
          multiple={isMulti}
        />
      )
    }

    return (
      <SelectWithSearch
        {...this.props}
        options={sortedOptions}
        className={classNames('sps-select', className)}
        isDisabled={disabled}
      />
    )
  }
}

ResponsiveSelect.defaultProps = {
  options: [],
  isMulti: false,
  disabledSort: false,
}

ResponsiveSelect.propTypes = {
  options: PropTypes.array,
  isMulti: PropTypes.bool,
  disabledSort: PropTypes.bool,
}

export default compose(
  inject('UiCtrl'),
  withTranslation(),
  observer,
)(ResponsiveSelect)
