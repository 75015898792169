import React, { useState } from 'react'
import { observer, inject } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import ChangePasswordCtrl from 'stores/Parameters/ChangePasswordCtrl'
import { saveUser } from 'services/userManagement'
import CardLayout from 'components/CardLayout/CardLayout'
import Loader from 'components/Loader/Loader'
import ChangePassword from 'pages/Parameters/Pages/Informations/ChangePassword'
import Toggle from 'components/Toggle/index'
import Button from 'components/Button/Button'
import { concatAddress, formatCustomerId } from 'utils'

const Informations = ({
  UserStore: {
    id: userId,
    loading,
    isExpert,
    easyEstimationDefault,
    setProperty,
    customer: { id: customerId, name, address, hasContract },
  },
}) => {
  const { t } = useTranslation()
  const [saving, setSaving] = useState(false)

  const save = async e => {
    e.preventDefault()
    try {
      setSaving(true)
      if (isExpert && hasContract('EE')) {
        await saveUser({
          user: {
            id: userId,
            easyEstimationByDefault: easyEstimationDefault,
          },
          method: 'patch',
          path: 'easy-estimation',
        })
      }

      if (ChangePasswordCtrl.valid) {
        await ChangePasswordCtrl.saveNewPassword()
      }
    } catch (error) {
      console.log(error)
    } finally {
      setSaving(false)
    }
  }

  if (loading) return <Loader />

  return (
    <div className="informations-page">
      <div className="row no-gutters">
        <h5 className="mb-4">{t('parameters.sideNav.informations')}</h5>
      </div>

      <CardLayout className="parameters-informations">
        <h6 className="mb-4 font-weight-bold">{t('parameters.informations.companyName')}</h6>

        <div className="row no-gutters mb-2">{name}</div>

        {address ? <div className="row no-gutters mb-2">{concatAddress(address)}</div> : null}

        <div className="row no-gutters text-primary mb-2">
          {t('parameters.informations.customerId')} : {formatCustomerId(customerId)}
        </div>

        <hr />

        {isExpert && hasContract('EE') && (
          <React.Fragment>
            <h6 className="mb-4 font-weight-bold">{t('parameters.informations.settings')}</h6>

            <div className="row">
              <div className="col-5">{t('parameters.informations.easyEstimationSetting')}</div>

              <div className="col-7">
                <Toggle
                  onChange={value => {
                    setProperty('easyEstimationDefault', value)
                  }}
                  checked={easyEstimationDefault}
                />
              </div>
            </div>
          </React.Fragment>
        )}

        <hr />

        <ChangePassword />

        <div className="d-flex justify-content-end">
          <Button
            disabled={saving}
            loading={saving}
            type="submit"
            className="btn btn-primary"
            onClick={save}
          >
            {t('parameters.informations.changePassword.save')}
          </Button>
        </div>
      </CardLayout>
    </div>
  )
}

export default inject('UserStore')(observer(Informations))
