import React from 'react'
import { observer } from 'mobx-react'
import { Switch, useLocation, useParams, Redirect, Route } from 'react-router-dom'

import NotFound from 'pages/NotFound/NotFound'
import Cart from 'pages/Mission/pages/Calculation/Cart/Cart'
import SearchPackage from 'pages/Mission/pages/Calculation/SearchPackage/SearchPackage'
import DetailPackage from 'pages/Mission/pages/Calculation/DetailPackage/DetailPackage'
import SearchFurniture from 'pages/Mission/pages/Calculation/SearchFurniture/SearchFurniture'
import AddFurniture from 'pages/Mission/pages/Calculation/AddFurniture/AddFurniture'
import AddRelatedCost from 'pages/Mission/pages/Calculation/AddRelatedCost/AddRelatedCost'
import CreatePackage from 'pages/Mission/pages/Calculation/CreatePackage/CreatePackage'

const Calculation = () => {
  const { pathname } = useLocation()
  const { id, type } = useParams()

  if (pathname === `/${type}/${id}/calculation`) {
    return <Redirect to={`/${type}/${id}/calculation/listPackage`} />
  }

  return (
    <Switch>
      <Route exact path={`/:type/:id/calculation/listPackage`} component={Cart} />
      <Route exact path={`/:type/:id/calculation/searchPackage`} component={SearchPackage} />
      <Route exact path={`/:type/:id/calculation/searchOutdoorPackage`} component={SearchPackage} />

      <Route exact path={`/:type/:id/calculation/createPackage`} component={CreatePackage} />
      <Route exact path={`/:type/:id/calculation/createOutdoorPackage`} component={CreatePackage} />

      <Route exact path={`/:type/:id/calculation/detailPackage`} component={DetailPackage} />
      <Route exact path={`/:type/:id/calculation/detailOutdoorPackage`} component={DetailPackage} />

      <Route
        exact
        path={`/:type/:id/calculation/searchRegularFurniture`}
        component={SearchFurniture}
      />
      <Route
        exact
        path={`/:type/:id/calculation/searchOutdoorFurniture`}
        component={SearchFurniture}
      />

      <Route exact path={`/:type/:id/calculation/addRegularFurniture`} component={AddFurniture} />
      <Route exact path={`/:type/:id/calculation/addOutdoorFurniture`} component={AddFurniture} />

      <Route exact path={`/:type/:id/calculation/addRelatedCost`} component={AddRelatedCost} />
      <Route exact path={`/:type/:id/calculation/addOverflow`} component={AddRelatedCost} />
      <Route exact path={`/:type/:id/calculation/addNoGuarantee`} component={AddRelatedCost} />
      <Route component={NotFound} />
    </Switch>
  )
}

export default observer(Calculation)
