import React, { useState, useEffect, useCallback } from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Button from 'components/Button/Button'
import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'

function ButtonWithConfirmation({
  t,
  className,
  icon,
  iconClassName,
  text,
  confirmText,
  withoutButton,
  classNameWrapper,
  onClick,
  disabled,
  centered,
  loading,
  confirmButtonText,
  dismissButtonText,
  switchButtonsOrder,
}) {
  const [showConfirm, setShowConfirm] = useState(false)
  const cb = useCallback(
    confirm => {
      if (confirm) onClick()
      setShowConfirm(!showConfirm)
    },
    [onClick, setShowConfirm, showConfirm],
  )
  const handleKeyUp = useCallback(
    e => {
      if (e.keyCode === 27) cb(false)
    },
    [cb],
  )

  useEffect(() => {
    if (showConfirm) {
      window.addEventListener('keyup', handleKeyUp, false)
    }

    return () => {
      window.removeEventListener('keyup', handleKeyUp, false)
    }
  }, [showConfirm, handleKeyUp])
  if (switchButtonsOrder === true) {
    return (
      <div className={classNameWrapper}>
        {withoutButton ? (
          <i
            className={classNames(iconClassName, { disabled, clickable: !disabled })}
            onClick={() => {
              if (!disabled) setShowConfirm(!showConfirm)
            }}
          />
        ) : (
          <Button
            className={className}
            disabled={disabled}
            loading={loading}
            onClick={() => setShowConfirm(!showConfirm)}
          >
            {icon && <i className={iconClassName} />} {text && <span className="ml-1">{text}</span>}
          </Button>
        )}

        <ModalWithBackdrop show={showConfirm} size="modal-sm" centered={centered}>
          <div className="modal-body text-left">
            <div className="py-1">{confirmText}</div>
          </div>

          <div className="modal-footer pb-2 pt-2">
            <button className="btn btn-secondary" onClick={cb.bind(this, false)}>
              {dismissButtonText ? dismissButtonText : t('common.no')}
            </button>
            <button className="btn btn-primary" onClick={cb.bind(this, true)}>
              {confirmButtonText ? confirmButtonText : t('common.yes')}
            </button>
          </div>
        </ModalWithBackdrop>
      </div>
    )
  } else {
    return (
      <div className={classNameWrapper}>
        {withoutButton ? (
          <i
            className={classNames(iconClassName, { disabled, clickable: !disabled })}
            onClick={() => {
              if (!disabled) setShowConfirm(!showConfirm)
            }}
          />
        ) : (
          <Button
            className={className}
            disabled={disabled}
            loading={loading}
            onClick={() => setShowConfirm(!showConfirm)}
          >
            {icon && <i className={iconClassName} />} {text && <span className="ml-1">{text}</span>}
          </Button>
        )}

        <ModalWithBackdrop show={showConfirm} size="modal-sm" centered={centered}>
          <div className="modal-body text-left">
            <div className="py-1">{confirmText}</div>
          </div>

          <div className="modal-footer pb-2 pt-2">
            <button className="btn btn-primary" onClick={cb.bind(this, true)}>
              {confirmButtonText ? confirmButtonText : t('common.yes')}
            </button>
            <button className="btn btn-secondary" onClick={cb.bind(this, false)}>
              {dismissButtonText ? dismissButtonText : t('common.no')}
            </button>
          </div>
        </ModalWithBackdrop>
      </div>
    )
  }
}

ButtonWithConfirmation.defaultProps = {
  icon: false,
  withoutButton: false,
  classNameWrapper: '',
  disabled: false,
  centered: false,
  loading: false,
  confirmButtonText: '',
  dismissButtonText: '',
}

ButtonWithConfirmation.propTypes = {
  onClick: PropTypes.func.isRequired,
  confirmText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  className: PropTypes.string,
  icon: PropTypes.bool,
  iconClassName: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  withoutButton: PropTypes.bool,
  classNameWrapper: PropTypes.string,
  disabled: PropTypes.bool,
  centered: PropTypes.bool,
  loading: PropTypes.bool,
  confirmButtonText: PropTypes.string,
  dismissButtonText: PropTypes.string,
}

export default withTranslation()(ButtonWithConfirmation)
