import React from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import classNames from 'classnames'

import Toggle from 'components/Toggle/Toggle'
import CollapseList from './CollapseList'
import InputPrice from 'components/InputPrice/InputPrice'
import TooltipPrice from './TooltipPrice'
import QualificationRow from './QualificationRow'

const IconType = ({ type, className }) => {
  switch (type) {
    case 'L':
      return <i className={`fa fa-male ${className}`} />
    case 'M':
      return <i className={`fa fa-cubes ${className}`} />
    case 'T':
      return <i className={`fa fa-wrench ${className}`} />
    default:
      console.warn(`Icon not found: ${type}`)
      return null
  }
}

class Item extends React.Component {
  state = { showDetail: false }
  openDetail = () => this.setState({ showDetail: !this.state.showDetail })
  onChange = e => {
    if (e.target.value === true && e.target.name === 'piActive') {
      this.props.packageData.clearPiGroupPiActivated(this.props.itemData)
    }
    this.props.itemData.setProperty(e.target.name, e.target.value)
  }

  render() {
    const { showDetail } = this.state
    const {
      itemData,
      packageData,
      match: {
        params: { type: urlType },
      },
      MissionStore: { isMissionClosed },
      ManagerClaimStore: { isClaimClosed },
    } = this.props
    const { desc, type, materials, tools, supplies, piActive } = itemData
    const isClosed = urlType === 'mission' ? isMissionClosed : isClaimClosed

    return (
      <div className={classNames('border mb-2', { 'disabled-item': !piActive })}>
        <div
          className={classNames('item-title-section row pb-2 pt-2 no-gutters align-items-center', {
            'border-bottom': showDetail,
          })}
        >
          <div className="col-1 text-center clickable" onClick={this.openDetail}>
            <i
              className={classNames('p-2 mr-1 fa font-weight-bold', {
                'fa-angle-down': !showDetail,
                'fa-angle-up': showDetail,
              })}
            />
            <IconType type={type} className="d-none d-lg-inline d-xl-inline" />
          </div>

          <div className="col-11 col-lg-7 clickable" onClick={this.openDetail}>
            {desc}
          </div>

          <div className="col-9 col-lg-3 pl-3 pl-lg-0 pl-xl-0 text-lg-right">
            <TooltipPrice itemData={itemData} />

            <InputPrice
              name="overrideTotalPrice"
              price={
                itemData.overrideTotalPrice === null
                  ? itemData.selfRepair
                    ? itemData.totalPriceSelfRepair
                    : itemData.totalPriceCatalog
                  : itemData.overrideTotalPrice
              }
              overridable
              overrode={itemData.overrode}
              onChange={this.onChange}
              disabled={!piActive}
              disableShowUnit={false}
              changed={itemData.overrideTotalPrice !== null}
            />
          </div>

          <div className="col-3 col-lg-1 pr-3 pr-lg-0 pr-xl-0 d-flex align-items-center justify-content-lg-center justify-content-end">
            <Toggle
              name="piActive"
              checked={piActive}
              onChange={this.onChange}
              disabled={isClosed}
            />
          </div>
        </div>

        {showDetail ? (
          <div className="pt-2 pb-2">
            <QualificationRow itemData={itemData} packageData={packageData} />
            <CollapseList type="materials" items={materials} itemData={itemData} />
            <CollapseList type="supplies" items={supplies} itemData={itemData} />
            <CollapseList type="tools" items={tools} itemData={itemData} />
          </div>
        ) : null}
      </div>
    )
  }
}

export default compose(
  inject('MissionStore', 'ManagerClaimStore'),
  withRouter,
  withTranslation(),
  observer,
)(Item)
