import React, { Fragment, useState } from 'react'
import { inject, observer } from 'mobx-react'

import RelatedCostShow from 'pages/SelfCare/Estimation/RelatedCostShow'
import RelatedCostAdd from 'pages/SelfCare/Estimation/RelatedCostAdd'
import Button from 'components/Button/Button'

const RelatedCosts = ({
  CartStore: { relatedCostPackages, addRelatedCost, removeRelatedCost, replaceRelatedCost },
  SelCareEstimationCtrl: { loading },
  AddRelatedCostCtrl: { setProperty, relatedCost, setRelatedCostSelfCare, editionId },
}) => {
  const [showAdd, setShowAdd] = useState(false)

  const addAction = () => {
    setRelatedCostSelfCare()
    setShowAdd(true)
  }

  return (
    <div>
      {relatedCostPackages.map(rc => (
        <Fragment key={`related-cost-${rc.id}`}>
          {rc.id === editionId && (
            <RelatedCostAdd
              mode="edit"
              relatedCost={rc}
              onCancel={() => {
                setProperty('editionId', null)
              }}
              onConfirm={() => {
                if (relatedCost.validRelatedCostEE) {
                  replaceRelatedCost(editionId, relatedCost)
                  setProperty('editionId', null)
                }
              }}
            />
          )}
          {rc.id !== editionId && (
            <RelatedCostShow
              relatedCost={rc}
              loading={loading}
              onDelete={() => removeRelatedCost(rc.id)}
              onEdit={() => {
                setProperty('editionId', rc.id)
                setProperty('relatedCost', rc)
              }}
            />
          )}
        </Fragment>
      ))}

      {showAdd && relatedCostPackages.length < 1 && editionId === null && (
        <RelatedCostAdd
          mode="new"
          relatedCost={relatedCost}
          onCancel={() => {
            setProperty('editionId', null)
            setShowAdd(false)
          }}
          onConfirm={() => {
            if (relatedCost.validRelatedCostEE) {
              addRelatedCost(relatedCost)
              setProperty('editionId', null)
              setShowAdd(false)
            }
          }}
        />
      )}

      {!showAdd && relatedCostPackages.length < 1 && editionId === null && (
        <div className="mb-3">
          <Button className="btn btn-primary" onClick={addAction} disabled={loading}>
            Ajouter une détérioration
          </Button>
        </div>
      )}
    </div>
  )
}

export default inject('CartStore', 'AddRelatedCostCtrl', 'SelCareEstimationCtrl')(
  observer(RelatedCosts),
)
