import React from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import { NavLink } from 'react-router-dom'

// import ProtectedLink from 'components/ProtectedLink/ProtectedLink'
import QlickLink from './QlikLink'

const ClaimLinks = ({
  t,
  DashboardCtrl: { newMissions },
  InboxStore: { notifications },
  UserStore: { hasRoleClaimManager },
}) => {
  return (
    <React.Fragment>
      <li className="nav-item">
        <NavLink exact to="/" className="nav-link text-primary" activeClassName="active">
          {t('header.navbar.links.claims')}

          {newMissions > 0 && <span className="badge badge-danger ml-1">{newMissions}</span>}
        </NavLink>
      </li>

      <span className="divider" />

      <li className="nav-item">
        <NavLink exact className="nav-link text-primary" activeClassName="active" to="/inbox">
          {t('header.navbar.links.inbox')}

          {notifications > 0 && <span className="badge badge-danger ml-1">{notifications}</span>}
        </NavLink>
      </li>

      {!hasRoleClaimManager && (
        <React.Fragment>
          <span className="divider" />
          <li className="nav-item">
            <NavLink
              exact
              className="nav-link text-primary"
              activeClassName="active"
              to="/calendar"
            >
              {t('header.navbar.links.calendar')}
            </NavLink>
          </li>
        </React.Fragment>
      )}

      <QlickLink />
    </React.Fragment>
  )
}

export default compose(
  inject('DashboardCtrl', 'InboxStore', 'UserStore'),
  withTranslation(),
  observer,
)(ClaimLinks)
