import React, { useEffect } from 'react'
import { inject } from 'mobx-react'

import { getDataLocal } from 'stores/localStorage'
import ErrorBoundary from 'components/ErrorBoundary'
import MainSelfCare from 'components/Main/MainSelfCare'
import AlertCenter from 'components/AlertCenter/AlertCenter'

const SelfCare = ({ UserStore: { logout, loadUserDataSelfCare }, UiCtrl: { checkApiStatus } }) => {
  useEffect(() => {
    checkApiStatus()
      .then(status => {
        if (status === 200) {
          const userInfo = getDataLocal('userInfo')
          const selfCareWan = getDataLocal('selfCareWan')
          const selfCareUuid = getDataLocal('selfCareUuid')
          if (userInfo && selfCareUuid && selfCareWan) {
            loadUserDataSelfCare({ ...userInfo, selfCareWan, selfCareUuid })
          }
        } else {
          logout(false)
        }
      })
      .catch(err => logout(false))
    // eslint-disable-next-line
  }, [])

  return (
    <div className="App">
      <ErrorBoundary>
        <MainSelfCare />
      </ErrorBoundary>
      <AlertCenter />
    </div>
  )
}

export default inject('UiCtrl', 'UserStore')(SelfCare)
