import { observable, computed, action, decorate, runInAction, reaction } from 'mobx'
import { path, type, isNil } from 'ramda'
import { isAfter } from 'date-fns'
import i18next from 'i18next'

import { fetchMission, closeMission, cancelMission, cfaSendCallback } from 'services/mission'
import { sendConfirmationBySms, fetchMeteo } from 'services/claim'
import PartyInvolvedStore from 'stores/Common/domain/PartyInvolvedStore'
import AlertCtrl from 'stores/Common/view/AlertCtrl'
import SearchPackageCtrl from 'stores/Mission/view/SearchPackageCtrl'
import SupportingDocumentsCtrl from 'stores/Common/view/SupportingDocumentsCtrl'
import CartStore from 'stores/Mission/domain/CartStore'
import CausesAndCircumstancesCtrl from 'stores/Mission/view/CausesAndCircumstancesCtrl'
import DamageDescriptionCtrl from 'stores/Mission/view/DamageDescriptionCtrl'
import RiskConformityCtrl from 'stores/Mission/view/RiskConformityCtrl'
import IrsiCtrl from 'stores/Mission/view/IrsiCtrl'
import PvCtrl from 'stores/Mission/view/PvCtrl'
import DashboardCtrl from 'stores/Common/view/DashboardCtrl'
import CommonStore, { HORS_DARVA_TAGS } from 'stores/Common/domain/CommonStore'
import MessagingStore from 'stores/Messaging/MessagingStore'
import ConclusionCtrl from 'stores/Mission/ConclusionCtrl'
import SearchFurnitureCtrl from 'stores/Mission/view/SearchFurnitureCtrl'
import AddrelatedCostCtrl from 'stores/Mission/view/AddRelatedCostCtrl'
import SearchLeakCtrl from 'stores/Mission/view/SearchLeakCtrl'
import AddFurnitureCtrl from 'stores/Mission/view/AddFurnitureCtrl'
import { isInspectionMission } from 'utils'
import moment from 'moment'
import { noop } from 'utils'
import AgreementProtocolCtrl from '../view/AgreementProtocolCtrl'

class MissionStore {
  cfa = null
  loading = false
  information = null
  claim = null
  policy = null
  report = null
  geometry = null
  claimantInformation = null
  assignerInformation = null
  assignerCustomer = null
  externalId = null
  damageDescription = null

  id = null
  applicableConventionTypeDisposer = noop
  loadIrsiDisposer = noop
  irsiSliceDisposer = noop

  selectedIRSICart = null

  showSmsModal = false
  confirmSmsDate = null
  sendingSms = false
  smsSelectedIP = null
  phoneNumber = ''

  retrieveReportButtonAvailable = false
  cfaCallingCallBack = false

  get isMissionClosed() {
    const status = path(['missionStatusKey'], this.information)
    return status === 'CACC'
  }

  get isWorkCFA() {
    if (this.loading || path(['id'], this.claim)) return false
    return true
  }

  get isReportFiled() {
    return path(['missionStatusKey'], this.information) === 'CARS'
  }

  get updatedAt() {
    return path(['updatedAt'], this.cfa)
  }

  get isCancelButtonAvailable() {
    const sd13Available = path(['sd13Available'], this.cfa)
    const sd70Available = path(['sd70Available'], this.cfa)
    const status = path(['status', 'key'], this.cfa)
    return (sd13Available || sd70Available || this.isHorsDarva) && status !== 'CACC'
  }

  get showDelayButton() {
    return !this.isReportFiled && !this.isMissionClosed
  }

  get tags() {
    return this.cfa.tags || []
  }

  get isWaterDamage() {
    if (this.loading) return false
    return path(['coverage', 'key'], this.claim) === 'WTDG'
  }

  get dateOfLoss() {
    return path(['cfi', 'claimInformation', 'dateOfLoss'], this.cfa)
  }

  get status() {
    return this.cfa && this.cfa.status
  }

  get optionGuarantee() {
    return path(
      ['cfi', 'contract', 'guarantee', 'guaranteeOptional', 'renewMovableEquipment', 'amount'],
      this.cfa,
    )
  }

  get deductibleGuarantee() {
    const absolute = path(
      ['cfi', 'contract', 'guarantee', 'guaranteeDeductible', 'absolute', 'amount'],
      this.cfa,
    )
    const relative = path(
      ['cfi', 'contract', 'guarantee', 'guaranteeDeductible', 'relative', 'amount'],
      this.cfa,
    )

    if (!isNil(absolute) && absolute > 0) {
      return absolute
    } else if (!isNil(relative) && relative > 0 && !this.isIME) {
      return relative
    }
    return 0
  }

  get zipCode() {
    return path(['mission', 'missionInsurerInformation', 'address', 'zipCode'], this.cfa) || null
  }

  get isASP() {
    return path(['aspAvailable'], this.cfa)
  }

  get isIRSI() {
    return path(['applicableConventionType', 'id'], this.damageDescription) === 'IRSI'
  }

  get workRequestRequired() {
    if (this.isMPJ) return false

    return (
      path(['missionStatusKey'], this.information) === 'CARS' &&
      CartStore.workAmount > 0 &&
      path(['noDamageOrNoClaim'], this.damageDescription) === false
    )
  }

  get agreementProtocolRequired() {
    return this.isMPJ
  }

  get pvRequired() {
    if (this.isIRSI) return false

    return true
  }

  get isIRSI1() {
    const convention = path(['applicableConventionType', 'id'], this.damageDescription)
    const slice = path(['irsi', 'slice'], this.cfa)

    return convention === 'IRSI' && slice === 'IRSI1'
  }

  get isIRSI2() {
    const convention = path(['applicableConventionType', 'id'], this.damageDescription)
    const slice = path(['irsi', 'slice'], this.cfa)

    return convention === 'IRSI' && slice === 'IRSI2'
  }

  get isAffectedByOtherThirdParty() {
    return path(['riskConformity', 'affectedByExpertise', 'key'], this.cfa) === 'OTHER_THIRD_PARTY'
  }

  get affectedByExpertise() {
    const affectedBy = path(['riskConformity', 'affectedByExpertise', 'key'], this.cfa)

    if (affectedBy === 'INSURED') {
      return path(['cfi', 'insuredInformation', 'involvedPartyId'], this.cfa)
    } else if (affectedBy === 'OTHER_THIRD_PARTY') {
      return path(['riskConformity', 'otherThirdPartyId'], this.cfa)
    }

    return null
  }

  get isIME() {
    const gta = path(['cfi', 'gta'], this.cfa)
    return gta === '1311' || gta === '418E'
  }

  get suffixVATLabel() {
    return this.isIME ? 'WithoutVAT' : ''
  }

  get isMPJ() {
    const gta = path(['cfi', 'gta'], this.cfa)
    return gta === 'J01' || gta === 'J02'
  }

  get isSocle() {
    const gta = path(['cfi', 'gta'], this.cfa)
    const isSocle = path(['cfi', 'contract', 'isSocle'], this.cfa)
    return isSocle === true && gta === '418'
  }

  get insuredInformationId() {
    return path(['involvedPartyId'], this.insuredInformation)
  }

  get workAmount() {
    const workAmount = path(['estimation', 'calculation', 'conclusion', 'workAmount'], this.cfa)
    return isNil(workAmount) ? null : workAmount
  }

  get remainderForInsured() {
    const remainderForInsured = path(
      ['estimation', 'calculation', 'conclusion', 'remainderForInsured'],
      this.cfa,
    )
    return isNil(remainderForInsured) ? null : remainderForInsured
  }

  get franchiseMustBeDeducted() {
    return path(
      ['estimation', 'calculation', 'conclusion', 'franchiseMustBeDeducted', 'id'],
      this.cfa,
    )
  }

  get taxSystem() {
    return path(['damageDescription', 'taxSystemType', 'id'], this.cfa)
  }

  // FOR IRSI ONLY
  get noStatusInvolvedParties() {
    const ips = path(['irsi', 'irsiInvolvedPartys'], this.cfa)
    if (isNil(ips) || type(ips) !== 'Array') return []

    return ips.filter(ip => path(['status', 'key'], ip) === '01').map(ip => ip.involvedPartyId)
  }

  get IRSIInvolvedParty() {
    const ipsIrsi = path(['irsi', 'irsiInvolvedPartys'], this.cfa)
    if (isNil(ipsIrsi) || type(ipsIrsi) !== 'Array') return []

    return ipsIrsi.map(ip => ip.involvedPartyId)
  }

  get IRSIInvolvedPartyWithName() {
    return this.IRSIInvolvedParty.map(irsiIP => {
      const ip = PartyInvolvedStore.partyInvolved.find(ip => ip.id === irsiIP)
      if (ip) {
        let name = ip.type === 'personal' ? `${ip.lastName} ${ip.firstName}` : ip.companyName
        if (isNil(name) || (type(name) === 'String' && name.length === 0)) name = '*'

        return {
          id: irsiIP,
          name,
        }
      }
      return undefined
    }).filter(ip => ip !== undefined)
  }

  get isHorsDarva() {
    const tags = path(['tags'], this.cfa)

    if (!isNil(tags) && type(tags) === 'Array') {
      return tags.some(tag => HORS_DARVA_TAGS.includes(tag))
    }

    return false
  }

  get selectedIRSICartName() {
    if (this.selectedIRSICart === null) return i18next.t('mission.conclusion.data.notVentilated')

    const ip = this.IRSIInvolvedPartyWithName.find(ip => ip.id === this.selectedIRSICart)

    if (ip) return ip.name
    return '*'
  }

  get gta() {
    return path(['cfi', 'gta'], this.cfa)
  }

  get cfaCallBackUrl() {
    return path(['callBackUrl'], this.cfa)
  }

  get appointmentDateRule() {
    let date = path(['mission', 'appointmentDate'], this.cfa)
    date = isNil(date) ? null : new Date(date)
    if (isNil(date)) return true

    return isAfter(new Date(), date)
  }

  get formulaContractKey() {
    return path(['formulaContract', 'key'], this.policy)
  }

  get isCreatedFromCfm() {
    return path(['cfmWan'], this.cfa) !== null
  }

  loadData = async wan => {
    if (!wan) return
    IrsiCtrl.resetForm()
    SearchFurnitureCtrl.loadFurnitureRooms(wan)
    this.loading = true

    try {
      await SearchPackageCtrl.loadMissionRooms(wan)
      fetchMission(wan)
        .then(
          action(mission => {
            if (
              process.env.REACT_APP_SOLERA_ENV === 'TEST' ||
              process.env.REACT_APP_SOLERA_ENV === 'DEV'
            ) {
              console.log('mission data : ', mission)
            }

            CartStore.fetch(wan, mission)
            this.id = wan
            this.cfa = mission
            this.information = {
              ...mission.mission,
              missionStatus: mission.status.value,
              missionStatusKey: mission.status.key,
              missionId: mission.wan,
            }
            this.claim = { ...mission.cfi.claimInformation, id: mission.cfiWan }
            this.policy = mission.cfi.contract
            this.insuredInformation = mission.cfi.insuredInformation
            this.claimantInformation = mission.cfi.claimantInformation
            this.assignerInformation = mission.assignerUser
            this.assignerCustomer = mission.assignerCustomer || mission.cfi.insurerCustomer
            this.externalId = mission.cfi.externalId
            this.confirmSmsDate = mission.mission.confirmSmsDate
            this.geometry = {
              lat: parseFloat(mission.cfi.claimInformation.addressOfLoss.geometry.lat),
              long: parseFloat(mission.cfi.claimInformation.addressOfLoss.geometry.long),
            }
            this.damageDescription = mission.damageDescription
            this.retrieveReportButtonAvailable = mission.retrieveReportButtonAvailable

            // DEFAULT SELECT FIRST IRSI INVOLVED PARTY
            this.selectedIRSICart =
              this.IRSIInvolvedParty.length > 0 ? this.IRSIInvolvedParty.shift() : null

            let reportSupportingDocuments = []
            if (mission.reports && mission.reports[0] && mission.reports[0].supportingDocuments)
              reportSupportingDocuments = mission.reports[0].supportingDocuments

            SupportingDocumentsCtrl.fetchSupportingDocuments(wan)
            SupportingDocumentsCtrl.ctrl.loadSDReport(reportSupportingDocuments)
            PartyInvolvedStore.loadData(mission.involvedParties)

            // FETCH DYNFORMS
            RiskConformityCtrl.loadData(wan, mission)
            CausesAndCircumstancesCtrl.loadData(wan, mission)
            DamageDescriptionCtrl.loadData(wan, mission)
            if (this.isMPJ) {
              AgreementProtocolCtrl.loadData(wan, mission)
            }
            if (this.isIRSI) {
              IrsiCtrl.loadData(wan, mission)
            }
            ConclusionCtrl.loadData(wan, mission)
            PvCtrl.loadData(wan, mission)

            // Remove messaging for WORK CFA
            //if (mission.tags.indexOf('WORK_CFA') === -1) {
            MessagingStore.loadData(wan, null, false, isInspectionMission(mission))
            //}

            // Load obsolesence rate
            AddFurnitureCtrl.loadObsolescenceRate(
              path(['assigneeCustomer', 'id'], this.cfa),
              path(['cfi', 'gta'], this.cfa),
              path(['formulaContract', 'key'], this.policy),
            )
            // Reload Dashboard too refresh tag !
            DashboardCtrl.fetchMissions()

            // LOAD RC ANNEX + exceedingGuarantedCeiling + noGuaranteedDamage
            AddrelatedCostCtrl.loadRelatedCostType({
              wan,
              coverage: mission.cfi.claimInformation.coverage.key,
            })
            // LOAD RC FEES + RC NONMATERIALS
            if (this.isIRSI) {
              AddrelatedCostCtrl.loadAdditionalRelatedCostType({
                wan,
                coverage: mission.cfi.claimInformation.coverage.key,
              })
            }

            // LOAD LEAK SEARCH
            if (this.isWaterDamage) {
              SearchLeakCtrl.fetchLeakSearchPackage(wan)
            }

            CommonStore.loadSupportingDocumentsTypesData(wan, 'mission')

            this.originalApplicableConventionType = path(
              ['applicableConventionType', 'id'],
              this.damageDescription,
            )

            this.applicableConventionTypeDisposer = reaction(
              () => path(['applicableConventionType', 'id'], this.damageDescription),
              convention => {
                this.updateConclusionForm()
                this.originalApplicableConventionType = convention
              },
            )

            this.loadIrsiDisposer = reaction(
              () => this.isIRSI,
              () => {
                if (this.isIRSI && !IrsiCtrl.loaded) {
                  IrsiCtrl.loadData(wan, this.cfa)
                }
              },
            )

            this.irsiSliceDisposer = reaction(
              () => path(['irsi', 'slice'], this.cfa),
              slice => {
                if (slice === 'IRSI2') {
                  CartStore.changeAllPackageForIRSI2()
                }
              },
            )
          }),
        )
        .catch(
          action(err => {
            console.error(err)
            this.loading = false
          }),
        )
    } catch (err) {
      console.log(err)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  updateStatus = (key, isThisPreviousKey = null) => {
    if (isThisPreviousKey && isThisPreviousKey !== this.status.key) return

    const status = CommonStore.claimCFA.find(status => status.key === key)

    if (status) {
      this.cfa.status = status
      this.information.missionStatusKey = status.key
    } else console.warn(`WARNING status de key: ${key} non trouvée`)
  }

  updateSd13Sd70 = () => {
    try {
      this.cfa.sd13Available = false
      this.cfa.sd70Available = false
    } catch (error) {
      console.warn('Trouble updating mission store : ', error)
    }
  }
  updateWorkAmount(workAmount) {
    try {
      this.cfa.estimation.calculation.conclusion.workAmount = workAmount
    } catch (error) {
      console.warn('Trouble updating mission store : ', error)
    }
  }

  updateRemainderForInsured(remainderForInsured) {
    try {
      this.cfa.estimation.calculation.conclusion.remainderForInsured = remainderForInsured
    } catch (error) {
      console.warn('Trouble updating mission store : ', error)
    }
  }

  updateIrsi(cfa) {
    this.cfa.irsi = cfa.irsi
    this.cfa.expertiseType = cfa.expertiseType

    // DEFAULT SELECT FIRST IRSI INVOLVED PARTY
    this.selectedIRSICart =
      this.IRSIInvolvedParty.length > 0 ? this.IRSIInvolvedParty.shift() : 'notVentilated'
  }

  updateWorkRequest(cfa) {
    this.cfa.workRequest = cfa.workRequest
  }

  reloadAndUpdateWorkRequest = async () => {
    try {
      const res = await fetchMission(this.id)
      runInAction(() => {
        this.cfa.workRequest = res.workRequest
      })
    } catch (err) {
      console.error(err)
    }
  }

  updateRiskConformirty(data) {
    this.cfa.riskConformity = data
  }

  updateDamageDescription(data) {
    this.cfa.damageDescription = data
    this.damageDescription = data
  }

  updateConclusionForm() {
    ConclusionCtrl.loadData(this.id, this.cfa)
  }

  async closeMission(wan) {
    this.loading = true
    try {
      await closeMission(wan)
      await this.loadData(wan)
      runInAction(() => {
        AlertCtrl.alert('success', 'mission.report.closeSuccess')
      })
    } catch (error) {
      if (error.status === 500) AlertCtrl.alert('danger', 'common.error500')
      return Promise.reject(error)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  async cancelMission(wan, { motif, message }) {
    this.loading = true
    try {
      await cancelMission(wan, { cancellationClosure: motif, message })
      await this.loadData(wan)
      runInAction(() => {
        AlertCtrl.alert('success', 'mission.report.cancelSuccess')
      })
    } catch (error) {
      if (error.status === 500) AlertCtrl.alert('danger', 'common.error500')
      return Promise.reject(error)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  resetClaim = () => (this.claim = null)

  setProperty = (key, value) => {
    this[key] = value
  }

  get canSendConfirmationBySms() {
    if (this.isMissionClosed) return false

    const appointmentDate = path(['mission', 'appointmentDate'], this.cfa)

    if (!appointmentDate || moment(appointmentDate).diff(moment(new Date()), 'hours') < 0)
      return false

    return true
  }

  sendConfirmationBySms = async () => {
    this.sendingSms = true
    try {
      const confirmDate = await sendConfirmationBySms(this.id, this.phoneNumber)
      AlertCtrl.alert('success', 'mission.report.confirmSmsSent')
      runInAction(() => {
        this.confirmSmsDate = confirmDate
        this.showSmsModal = false
        this.cfa.mission.smsSent = true
        this.phoneNumber = ''
        this.smsSelectedIP = null
      })
    } catch (error) {
      AlertCtrl.alert('danger', 'mission.report.unableToconfirmSms')
    } finally {
      runInAction(() => {
        this.sendingSms = false
      })
    }
  }

  cfaSendCallback = async () => {
    this.cfaCallingCallBack = true
    await cfaSendCallback(this.id)
    runInAction(() => {
      this.cfaCallingCallBack = false
    })
  }

  disposeReactions = () => {
    this.loadIrsiDisposer()
    this.applicableConventionTypeDisposer()
    this.irsiSliceDisposer()
  }

  async retrieveMeteo(wan, isExpert) {
    try {
      return await fetchMeteo(wan, isExpert)
    } catch (err) {
      AlertCtrl.alert('danger', 'meteoError.errorAPI')
    }
  }
}

const DecoratedMissionStore = decorate(MissionStore, {
  cfa: observable,
  loading: observable,
  information: observable,
  claim: observable,
  policy: observable,
  report: observable,
  geometry: observable,
  claimantInformation: observable,
  assignerInformation: observable,
  assignerCustomer: observable,
  externalId: observable,
  damageDescription: observable,
  originalApplicableConventionType: observable,
  applicableConvention: observable,
  selectedIRSICart: observable,
  insuredInformation: observable,
  confirmSmsDate: observable,
  phoneNumber: observable,
  sendingSms: observable,
  smsSelectedIP: observable,
  showSmsModal: observable,
  retrieveReportButtonAvailable: observable,
  cfaCallingCallBack: observable,

  isCancelButtonAvailable: computed,
  isMissionClosed: computed,
  isWorkCFA: computed,
  isWaterDamage: computed,
  status: computed,
  optionGuarantee: computed,
  deductibleGuarantee: computed,
  isHorsDarva: computed,
  tags: computed,
  isIRSI: computed,
  isReportFiled: computed,
  isSocle: computed,
  showDelayButton: computed,
  insuredInformationId: computed,
  workAmount: computed,
  remainderForInsured: computed,
  noStatusInvolvedParties: computed,
  IRSIInvolvedParty: computed,
  isIRSI1: computed,
  isIRSI2: computed,
  affectedByExpertise: computed,
  isAffectedByOtherThirdParty: computed,
  franchiseMustBeDeducted: computed,
  IRSIInvolvedPartyWithName: computed,
  selectedIRSICartName: computed,
  zipCode: computed,
  updatedAt: computed,
  isIME: computed,
  suffixVATLabel: computed,
  canSendConfirmationBySms: computed,
  appointmentDateRule: computed,
  dateOfLoss: computed,
  formulaContractKey: computed,
  workRequestRequired: computed,
  agreementProtocolRequired: computed,
  cfaCallBackUrl: computed,
  taxSystem: computed,
  isCreatedFromCfm: computed,

  loadData: action,
  closeMission: action.bound,
  cancelMission: action.bound,
  sendConfirmationBySms: action,
  updateStatus: action,
  updateSd13Sd70: action,
  updateWorkAmount: action,
  reloadAndUpdateWorkRequest: action,
  updateRemainderForInsured: action,
  updateIrsi: action,
  updateWorkRequest: action,
  updateRiskConformirty: action,
  updateDamageDescription: action,
  updateConclusionForm: action,
  resetClaim: action,
  setProperty: action,
  cfaSendCallback: action,
})

export default new DecoratedMissionStore()
