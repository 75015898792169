import React from 'react'
import { observer } from 'mobx-react'

import PropertyEmbellishmentCtrl from 'stores/Mission/EasyEstimation/PropertyEmbellishmentCtrl'
import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'

const WallModal = ({ show, setShow }) => {
  const walls = [
    { label: 1, value: 25 },
    { label: 2, value: 50 },
    { label: 3, value: 75 },
    { label: 4, value: 100 },
  ]
  const { setProperty, computeAreaForPackages } = PropertyEmbellishmentCtrl

  return (
    <ModalWithBackdrop show={show} size="modal-md" centered onClose={() => {}}>
      <div className="modal-header d-flex justify-content-between">
        <h5 className="modal-title">Nombre de murs endommagés</h5>
      </div>

      <div className="modal-body container">
        <div className="btn-group d-flex">
          {walls.map(wall => (
            <button
              key={`wall-${wall.label}`}
              className="btn btn-outline-primary"
              onClick={() => {
                setProperty('percentageDammaged', wall.value)
                setProperty('firstTimeWall', false)
                computeAreaForPackages()
                setShow(false)
              }}
            >
              {wall.label}
            </button>
          ))}
        </div>
      </div>
    </ModalWithBackdrop>
  )
}

export default observer(WallModal)
