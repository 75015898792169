import { observable, computed, action, decorate } from 'mobx'

import AbstractField from 'utils/dynform/AbstractField'

class InputString extends AbstractField {
  value = ''
  errors = []
  show = true
  originalValue = null

  type = 'text'
  choices = null
  maxLength = null
  multiple = false
  expanded = false
  hasRandomButton = false

  children = null

  constructor(data) {
    super(data)

    this.type = data.type || 'text'
    this.hasRandomButton = data.hasRandomButton || false
    this.choices = data.choices || null
    this.maxLength = data.maxLength || null
    this.labelUrl = data.labelUrl || null
    this.url = data.url || null

    if (data.value) this.value = data.value
    else if (data.default) this.value = data.default
    else this.value = ''

    this.show = data.show
    this.originalValue = this.value
  }

  get changed() {
    return this.value !== this.originalValue
  }

  setOriginalData() {
    this.value = this.originalValue
  }
}

const DecoratedInputString = decorate(InputString, {
  value: observable,
  errors: observable,
  show: observable,
  originalValue: observable,

  setOriginalData: action,

  changed: computed,
})

export default DecoratedInputString
