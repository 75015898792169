import React from 'react'
import { observer } from 'mobx-react'
import { isNil } from 'ramda'

import PropertyEmbellishmentCtrl from 'stores/Mission/EasyEstimation/PropertyEmbellishmentCtrl'
import InputArea from 'components/InputArea/InputArea'
import InputNumber from 'components/InputNumber/InputNumber'
import Calculator from './Calculator'

const CoveringUnit = () => {
  const {
    showCalculator,
    area,
    quantity,
    currentRoom,
    selectedPart,
    setProperty,
    changePackagesProperty,
    changeArea,
    unitByDefault,
  } = PropertyEmbellishmentCtrl

  if (selectedPart === '') return null
  else if (selectedPart === 'C' || selectedPart === 'W' || selectedPart === 'F') {
    return (
      <div className="covering-unit-area form-group">
        <label className="d-flex align-items-center">
          Surface de la pièce
          {selectedPart !== 'W' && (
            <button
              className="btn ml-1"
              onClick={() => setProperty('showCalculator', !showCalculator)}
            >
              <i className="fa fa-calculator" />
            </button>
          )}
        </label>

        <div className="d-flex ">
          <InputArea
            name="area"
            className="form-control d-inline-block"
            value={isNil(area) ? currentRoom.areaSize : area}
            onChange={e => changeArea(e.target.value)}
          />

          <Calculator />
        </div>
      </div>
    )
  }

  return (
    <div className="form-group text-right">
      <Calculator />
      {unitByDefault === 'M2' && (
        <label className="d-flex align-items-center justify-content-end">
          Surface
          <button
            className="btn ml-1"
            onClick={() => setProperty('showCalculator', !showCalculator)}
          >
            <i className="fa fa-calculator" />
          </button>
        </label>
      )}

      {unitByDefault !== 'M2' && <label>Quantité</label>}

      {unitByDefault.length > 0 ? (
        <div className="input-group">
          <InputNumber
            name="quantity"
            className="form-control text-right"
            value={quantity}
            onChange={e => {
              setProperty(
                e.target.name,
                unitByDefault === 'M2' || unitByDefault === 'ML'
                  ? e.target.value
                  : parseInt(e.target.value),
              )
              changePackagesProperty(
                'quantity',
                unitByDefault === 'M2' || unitByDefault === 'ML'
                  ? e.target.value
                  : parseInt(e.target.value),
              )
            }}
          />
          <div className="input-group-append">
            <span className="input-group-text">{unitByDefault}</span>
          </div>
        </div>
      ) : (
        <InputNumber
          name="quantity"
          className="form-control text-right"
          value={quantity}
          onChange={e => {
            setProperty(e.target.name, parseInt(e.target.value))
            changePackagesProperty('quantity', parseInt(e.target.value))
          }}
        />
      )}
    </div>
  )
}

export default observer(CoveringUnit)
