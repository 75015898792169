import React, { Fragment } from 'react'
import { observer, inject } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { NavLink, useParams } from 'react-router-dom'
import PendingModal from 'pages/ManagerClaim/pages/Pending/PendingModal'

import ButtonWithConfirmation from 'components/ButtonWithConfirmation/ButtonWithConfirmation'
import Tooltip from '../../Tooltip/Tooltip'

const ManagerClaimDesktop = ({
  CartStore: { easyEstimation, blockNavigation },
  ManagerClaimStore: {
    isClaimClosed,
    isPending,
    closeWithNoFurtherAction,
    closeAction,
    isReportLinksAvailable,
    alreadyChosenOrientation,
    hasMissionExpert,
    hasLastBillJob,
  },
  UserStore: { customer },
}) => {
  const { t } = useTranslation()
  const { id } = useParams()
  const hasDeclarationContract = customer.hasContract('DECLA')
  return (
    <Fragment>
      {
        <div className="nav flex-column nav-pills">
          <NavLink className="nav-link" activeClassName="active" to={`/managerClaim/${id}/policy`}>
            {t('mission.sideNav.policy')}
          </NavLink>
        </div>
      }
      {hasDeclarationContract && <h5 className="mt-4 mb-4">{t('mission.sideNav.titleAnnexe')}</h5>}
      {hasDeclarationContract && (
        <div className="nav flex-column nav-pills">
          <NavLink
            className="nav-link"
            activeClassName="active"
            to={`/managerClaim/${id}/partyInvolved`}
          >
            {t('mission.sideNav.partyInvolved')}
          </NavLink>

          <NavLink
            className="nav-link"
            activeClassName="active"
            to={`/managerClaim/${id}/supportingDocuments`}
          >
            {t('mission.sideNav.supportingDocument')}
          </NavLink>
        </div>
      )}

      <h5 className="mt-4 mb-4">{t('mission.sideNav.sinister')}</h5>
      <div className="nav flex-column nav-pills">
        {hasDeclarationContract && (
          <NavLink
            className="nav-link"
            activeClassName="active"
            to={`/managerClaim/${id}/declaration`}
          >
            {t('mission.sideNav.sinisterDeclaration')}
          </NavLink>
        )}
        <NavLink
          className="nav-link"
          activeClassName="active"
          to={`/managerClaim/${id}/calculation`}
        >
          {t('mission.sideNav.calculation')}
        </NavLink>
        {!easyEstimation && (
          <NavLink
            className="nav-link"
            activeClassName="active"
            to={`/managerClaim/${id}/ventilation`}
          >
            {t('mission.sideNav.ventilationREN')}
          </NavLink>
        )}
        {!blockNavigation && (
          <NavLink
            className="nav-link"
            activeClassName="active"
            to={`/managerClaim/${id}/instruction`}
          >
            {t('mission.sideNav.indemnisation')}
          </NavLink>
        )}
        {blockNavigation && (
          <Tooltip text={t('managerClaim.sideNav.estimationTooltip')}>
            <span className="nav-link disabled">{t('mission.sideNav.indemnisation')}</span>
          </Tooltip>
        )}
      </div>

      <h5 className="mt-4 mb-4">{t('managerClaim.sideNav.compensation')}</h5>

      {isReportLinksAvailable && !blockNavigation && (
        <div className="nav flex-column nav-pills">
          <NavLink
            className="nav-link"
            activeClassName="active"
            to={`/managerClaim/${id}/orientation`}
          >
            {t('managerClaim.sideNav.orientation')}
          </NavLink>
          {alreadyChosenOrientation && (
            <NavLink
              className="nav-link"
              activeClassName="active"
              to={`/managerClaim/${id}/report`}
            >
              {t('managerClaim.sideNav.report')}
            </NavLink>
          )}
          {!alreadyChosenOrientation && (
            <Tooltip text={t('managerClaim.sideNav.orientionRequired')}>
              <span className="nav-link disabled">{t('managerClaim.sideNav.report')}</span>
            </Tooltip>
          )}
        </div>
      )}

      {isReportLinksAvailable && blockNavigation && (
        <div className="nav flex-column nav-pills">
          <Tooltip text={t('managerClaim.sideNav.estimationTooltip')}>
            <span className="nav-link disabled">{t('managerClaim.sideNav.orientation')}</span>
          </Tooltip>
          <Tooltip text={t('managerClaim.sideNav.estimationTooltip')}>
            <span className="nav-link disabled">{t('managerClaim.sideNav.report')}</span>
          </Tooltip>
        </div>
      )}

      {!isReportLinksAvailable && (
        <div className="nav flex-column nav-pills">
          <Tooltip text={t('managerClaim.sideNav.orientationUnavailable')}>
            <span className="nav-link disabled">{t('managerClaim.sideNav.orientation')}</span>
          </Tooltip>
          <Tooltip text={t('managerClaim.sideNav.reportUnavailable')}>
            <span className="nav-link disabled">{t('managerClaim.sideNav.report')}</span>
          </Tooltip>
        </div>
      )}


      {((alreadyChosenOrientation && hasLastBillJob) || hasMissionExpert) && (
        <Fragment>
          <h5 className="mt-4 mb-4">{t('mission.sideNav.titleMission')}</h5>
          <div className="nav flex-column nav-pills">
            <div className="container-notif">
              {hasMissionExpert && <NavLink
                className="nav-link"
                activeClassName="active"

                to={`/managerClaim/${id}/events-expert-mission`}
              >
                {t('events.cfm-mission-expert')}
              </NavLink>}

              {hasLastBillJob && <NavLink
                className="nav-link"
                activeClassName="active"
                to={`/managerClaim/${id}/last-bill`}
              >
                {t('lastbill.title')}
              </NavLink>}
            </div>
          </div>
        </Fragment>
      )}

      <h5 className="mt-4 mb-4">{t('mission.sideNav.titleCommunication')}</h5>

      <div className="nav flex-column nav-pills">
        <div className="container-notif">
          <NavLink
            className="nav-link"
            activeClassName="active"
            to={`/managerClaim/${id}/messaging`}
          >
            {t('messaging.title')}
          </NavLink>
          <NavLink className="nav-link" activeClassName="active" to={`/managerClaim/${id}/events`}>
            {t('events.title')}
          </NavLink>
        </div>
      </div>

      {!isClaimClosed && !isPending && <PendingModal />}

      {!isClaimClosed && (
        <div className="mt-4 d-flex justify-content-between">
          <ButtonWithConfirmation
            classNameWrapper="btn-block bg-white"
            className="btn btn-outline-primary btn-block"
            onClick={closeWithNoFurtherAction}
            confirmText={t('mission.report.confirmationCloseMission')}
            text={
              <span>
                {t('mission.closeWithNoFurtherAction')}
                <i className="fa fa-unlock-alt" />
              </span>
            }
          />
        </div>
      )}

      {!isClaimClosed && (
        <div className="mt-4 d-flex justify-content-between">
          <ButtonWithConfirmation
            classNameWrapper="btn-block bg-white"
            className="btn btn-outline-primary btn-block"
            onClick={closeAction}
            confirmText={t('mission.report.confirmationCloseMission')}
            text={
              <span>
                Terminer le dossier <i className="fa fa-unlock-alt" />
              </span>
            }
          />
        </div>
      )}
    </Fragment>
  )
}

export default inject('CartStore', 'ManagerClaimStore', 'UserStore')(observer(ManagerClaimDesktop))
