import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'

import { euro } from 'utils/currency'
const SelfCareFinish = ({
  SelCareFinishCtrl: {
    choice,
    amount,
    formatAppointmentDate,
    appointmentDateHourStart,
    appointmentDateHourEnd,
  },
}) => {
  return (
    <Fragment>
      {!['abandon', 'noDamage'].includes(choice) && (
        <div className="border border-primary box-shadow-sc-hover rounded p-3 mb-3">
          <div className="row no-gutters mb-4">
            <div className="col-12">
              <h4>Nous vous remercions d'avoir réalisé votre déclaration de sinistre en ligne</h4>
            </div>
          </div>

          <div className="row no-gutters">
            <div className="col-12">Vous avez choisi :</div>
          </div>

          <div className="row no-gutters">
            <div className="col-12 font-weight-bold">
              {choice === 'gag' && 'Réparation par vous même'}
              {choice === 'mobilier' && 'Règlements pour les dommages mobiliers'}
              {choice === 'ren' && "L'intervention d'une entreprise agréée"}
              {choice === 'ip' && "L'intervention de mon entreprise"}
              {choice === 'gagMobilier' &&
                'Réparation par vous-même avec règlements pour les dommages mobiliers'}
              {choice === 'renMobilier' &&
                "L'intervention d'une entreprise agréée avec règlements pour les dommages mobiliers"}
              {choice === 'contactInsured' && 'Etre contacté par nos services'}
            </div>
          </div>

          {['gag', 'mobilier', 'gagMobilier'].includes(choice) && (
            <div className="row no-gutters mt-4">
              <div className="col-12">
                Le montant de <span className="font-weight-bold">{euro(amount)}</span> vous sera
                réglé dans les plus brefs délais.
              </div>
            </div>
          )}

          <div className="row no-gutters mt-4">
            <div className="col-12">
              Vous recevrez un récapitulatif de votre déclaration par mail
            </div>
          </div>
        </div>
      )}

      {choice === 'abandon' && (
        <div className="border border-primary box-shadow-sc-hover rounded p-3 mb-3">
          <div className="row no-gutters mb-4">
            <div className="col-12">
              <h4>Vous avez mis fin à votre déclaration de sinistre</h4>
            </div>
          </div>

          <div className="row no-gutters mt-4">
            <div className="col-12">
              Vous serez contacter par nos services pour poursuivre votre déclaration.
            </div>
          </div>
        </div>
      )}

      {choice === 'noDamage' && (
        <div className="border border-primary box-shadow-sc-hover rounded p-3 mb-3">
          <div className="row no-gutters mb-4">
            <div className="col-12">
              <h4>Vous avez choisi : "Aucun dommage subi"</h4>
            </div>
          </div>

          <div className="row no-gutters mt-4">
            <div className="col-12">
              Vous serez contacter par nos services pour poursuivre votre déclaration.
            </div>
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default inject('SelCareFinishCtrl')(observer(SelfCareFinish))
